import React, { useState, useEffect, useRef } from "react";
import { Button, Table, Card, Dropdown, Pagination } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { MdOutlineSensors } from "react-icons/md";
import { FaEraser } from "react-icons/fa6";
import { TfiPencil } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import { FaPlus } from "react-icons/fa";
import { FcRefresh } from "react-icons/fc";
import { TbSettingsCog } from "react-icons/tb";
import EditDeviceModal from './EditSensorModal';
import DeviceSensorAdd from './DeviceSensorAdd';

import NewLoader from "../speedo-asset/Loader";
import DeleteSensorModal from "./DeleteSensorModal";





function DeviceSensorMain(props) {

    const [showEdit, setShowEdit] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [toggleSensorChange, setToggleSensorChange] = useState(false)
    const [showDeleteCheck, setShowDeleteCheck] = useState(false)

    //states for sensor
    const [selectedSensors, setSelectedSensors] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [sensorList, setSensorList] = useState([])
    const [totalSensor, setTotalSensor] = useState('0')
    const [sensorIdForDelete, setSensorIdForDelete] = useState('')
    const [sensorIdForEdit, setSensorIdForEdit] = useState('')
    //pagination
    const [pageNumber, setPageNumber] = useState('1');
    const [totalPages, setTotalPages] = useState('');
    //import
    const [fileForImport, setFileForImport] = useState()


    const fetchSensors = async () => {
        try {
            setIsLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("Authorization", localStorage.getItem('token'));

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const response = await fetch(`https://gps.speedotrack.com/api/device/${props.imeiForSensor}/sensor?rows=5&page=${pageNumber}&field=name`, requestOptions)
            const result = await response.json();
            setSensorList(result.results);
            setTotalPages(result.pages);
            setTotalSensor(`(${result.total})`)
            console.log(result)
            setIsLoading(false)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchSensors()
    }, [toggleSensorChange, pageNumber])


    //importing the sensors.
    const fileInputRef = useRef(null);

    const handleFileUpload = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log(file)
        setFileForImport(file)
        funcImportSensor(file)
    }


    //api call for import file
    const funcImportSensor = async (file) => {
        try {
            var formdata = new FormData();
            formdata.append("cmd", "import-sen");
            formdata.append("sensors-file", file, "[PROXY]");

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            const response = await fetch(`https://gps.speedotrack.com/api/device/${props.imeiForSensor}/sensor/bulk`, requestOptions)
            const result = await response.json()
            console.log(result)
        } catch (error) {
            console.log(error)
        }
    }



    return (
        <div>
            <Modal

                {...props}
                size="lg"
                aria-labelledby="contained-modal"
                centered
                style={{ marginTop: '-5%', marginLeft: '80px' }}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal" style={{ fontSize: '15px' }}>
                        <MdOutlineSensors />{" "}Sensor {totalSensor}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ? (
                        <NewLoader />
                    ) : (
                        <div className="table-responsive" style={{ marginTop: '10px' }}>
                            <Table striped hover size="sm">

                                <thead>
                                    <tr>
                                        <th style={{ paddingRight: '0px', }} ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></th>
                                        <th style={{ fontSize: '15px' }}>Name</th>
                                        <th style={{ fontSize: '15px' }}>Type</th>
                                        <th style={{ fontSize: '15px' }}>Parameter</th>
                                        <th style={{ fontSize: '15px' }}>Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {sensorList?.map((sensor, index) => (
                                        <tr key={sensor._id}>
                                            <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                                            <td>{sensor.name}</td>
                                            <td>{sensor.type}</td>
                                            <td>{sensor.param}</td>
                                            <td>
                                                <a class="delete" data-toggle="modal" title="Edit" onClick={() => { setSensorIdForEdit(sensor._id); setShowEdit(true) }} ><TfiPencil style={{ width: '23px' }} /></a>
                                                <a class="delete" data-toggle="modal" title="Delete" onClick={() => { setSensorIdForDelete(sensor._id); setShowDeleteCheck(true) }}><TfiTrash style={{ width: '23px', cursor: 'pointer' }} /></a>
                                            </td>
                                        </tr>

                                    ))}
                                    {/* <tr>
                                    <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                                    <td>SpeedoTrack</td>
                                    <td>Battery</td>
                                    <td>io66</td>
                                    <td>
                                        <a class="delete" data-toggle="modal" title="Edit" onClick={() => setShowEdit(true)} ><TfiPencil style={{ width: '23px' }} /></a>
                                        <a class="delete" data-toggle="modal" title="Delete" ><TfiTrash style={{ width: '23px' }} /></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                                    <td>SpeedoTrack</td>
                                    <td>Battery</td>
                                    <td>io66</td>
                                    <td>
                                        <a class="delete" data-toggle="modal" title="Edit"  ><TfiPencil style={{ width: '23px' }} /></a>
                                        <a class="delete" data-toggle="modal" title="Delete" ><TfiTrash style={{ width: '23px' }} /></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                                    <td>SpeedoTrack</td>
                                    <td>Battery</td>
                                    <td>io66</td>
                                    <td>
                                        <a class="delete" data-toggle="modal" title="Edit" onClick={() => setShowEdit(true)} ><TfiPencil style={{ width: '23px' }} /></a>
                                        <a class="delete" data-toggle="modal" title="Delete" ><TfiTrash style={{ width: '23px' }} /></a>
                                    </td>
                                </tr> */}

                                </tbody>
                            </Table>
                            <Pagination size="sm">
                                <Pagination.Prev
                                    disabled={pageNumber === '1' ? true : false}
                                    onClick={() => setPageNumber((parseInt(pageNumber) - 1).toString())}
                                />
                                {(<>
                                    {Array.from({ length: totalPages }).map((_, i) => (
                                        <Pagination.Item
                                            key={i}
                                            onClick={() => setPageNumber((i + 1).toString())}
                                            active={pageNumber === (i + 1).toString() ? true : false}
                                        >{i + 1}
                                        </Pagination.Item>
                                    ))}
                                </>)}
                                <Pagination.Next
                                    disabled={pageNumber === totalPages.toString() ? true : false}
                                    onClick={() => setPageNumber((parseInt(pageNumber) + 1).toString())}
                                />
                            </Pagination>

                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </div>
                    )}
                </Modal.Body>
                <div>

                </div>

                {showDeleteCheck ? (
                    <DeleteSensorModal
                        show={showDeleteCheck}
                        onHide={() => setShowDeleteCheck(false)}
                        sensorIdForDelete={sensorIdForDelete}
                        toggleSensorChange={toggleSensorChange}
                        setToggleSensorChange={setToggleSensorChange}
                        imeiForSensor={props.imeiForSensor}
                    />
                ) : null}


                {showEdit ? (
                    <EditDeviceModal
                        show={showEdit}
                        onHide={() => setShowEdit(false)}
                        toggleSensorChange={toggleSensorChange}
                        setToggleSensorChange={setToggleSensorChange}
                        imeiForSensor={props.imeiForSensor}
                        sensorIdForEdit={sensorIdForEdit}
                    />
                ) : null}


                {showAdd ? (
                    <DeviceSensorAdd
                        show={showAdd}
                        onHide={() => setShowAdd(false)}
                        imeiForSensor={props.imeiForSensor}
                        toggleSensorChange={toggleSensorChange}
                        setToggleSensorChange={setToggleSensorChange}
                    />
                ) : null}

                <Card style={{ padding: '7px', borderRadius: '0px' }}>
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <a href="#" onClick={() => setShowAdd(true)}>
                            <FaPlus title="Add" style={{ width: '35px' }} />
                        </a>
                        <a href="#"><FcRefresh title="Refresh" style={{ marginLeft: '-5px', width: '35px' }}
                            onClick={fetchSensors}
                        /></a>


                        <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic" style={{ marginLeft: '-15px', marginTop: '-3px' }}>
                                <TbSettingsCog />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={handleFileUpload}>Import</Dropdown.Item>
                                <Dropdown.Item href="">Export</Dropdown.Item>
                                <Dropdown.Item href="">Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Card>
            </Modal>





        </div>
    );
}
export default DeviceSensorMain;



