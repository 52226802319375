import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { MdOutlineSensors } from "react-icons/md";
import CallibrationDeviceModal from './CallibrationDeviceModal';
import NewLoader from '../speedo-asset/Loader';




function EditDeviceModal(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [callibrationModal, setCallibrationModal] = useState(false);
  const [patchData, setPatchData] = useState({})
  const [sensorData, setSensorData] = useState({});

  //state for fetching the templates data
  const [sensorTemplate, setSensorTemplate] = useState([])
  const [sensorParamList, setSensorParamList] = useState('')


  //states for form inputs for edit field
  const [editSensorName, setEditSensorName] = useState('')
  const [editSensorType, setEditSensorType] = useState('')
  const [editSensorParam, setEditSensorParam] = useState('')
  const [editSensorResultType, setEditSensorResultType] = useState('')
  const [editSensorUnit, setEditSensorUnit] = useState('')
  const [editSensorTextOne, setEditSensorTextOne] = useState('')
  const [editSensorTextZero, setEditSensorTextZero] = useState('')
  const [editSensorFormula, setEditSensorFormula] = useState('')
  const [editSensorLowestValue, setEditSensorLowestValue] = useState('')
  const [editSensorHighestValue, setEditSensorHighestValue] = useState('')
  const [editSensorDataList, setEditSensorDataList] = useState(false)
  const [editSensorPopup, setEditSensorPopup] = useState(false)
  const [editSensorAccIgnore, setEditSensorAccIgnore] = useState(false)

  useEffect(() => {
    const fetchSensorTemplate = async () => {
      try {
        setIsLoading(false)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", localStorage.getItem('token'));

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        const response = await fetch("https://gps.speedotrack.com/api/template/sensor", requestOptions)
        const result = await response.json()
        const param = result?.templates.map((sensor) => sensor.param)
        setSensorTemplate(result.templates)
        setSensorParamList(param)
        setIsLoading(false)
      } catch (error) {
        console.log(error)
      }
    }

    fetchSensorTemplate();
  }, [])




  const fetchSensorData = async () => {
    try {
      setIsLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem('token'));

      var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
      };

      const response = await fetch(`https://gps.speedotrack.com/api/device/${props.imeiForSensor}/sensor/${props.sensorIdForEdit}`, requestOptions)
      const result = await response.json()
      setSensorData(result)
      console.log(result)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      alert(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchSensorData()
  }, [])

  useEffect(() => {
    setEditSensorName(sensorData.name)
    setEditSensorType(sensorData.type)
    setEditSensorParam(sensorData.param)
    setEditSensorResultType(sensorData.resultType)
    setEditSensorUnit(sensorData.units)
    setEditSensorTextOne(sensorData.text1)
    setEditSensorTextZero(sensorData.text0)
    setEditSensorFormula(sensorData.formula)
    setEditSensorLowestValue(sensorData.lv)
    setEditSensorHighestValue(sensorData.hv)
    setEditSensorDataList(sensorData.dataList)
    setEditSensorPopup(sensorData.popup)
    setEditSensorAccIgnore(sensorData.accIgnore)

  }, [sensorData])


  //function for editing the sensor
  const funcEditSensor = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", localStorage.getItem('token'));

      var raw = JSON.stringify(patchData);

      var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/cpanel/device/${props.imeiForSensor}/sensor/${props.sensorIdForEdit}`, requestOptions)
      const result = await response.json()
      console.log(result)
      alert(result.message)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      top
      style={{ marginLeft: '-7.5%', paddingBottom: '20px' }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '14px' }}>
          <MdOutlineSensors /> {" "}Edit Sensor
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <NewLoader />
        ) : (
          <form lg="12">
            <div className="form-row d-flex">
              <div className="form-group col-md-6">
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Sensor name</label>
                <input type="email" className="form-control" id="inputEmail4" placeholder="sensor name"
                  style={{ borderRadius: '5px' }}
                  value={editSensorName}
                  onChange={(e) => {
                    setEditSensorName(e.target.value);
                    setPatchData({
                      ...patchData,
                      name: e.target.value
                    })
                  }}
                />
              </div>
              <div className="form-group col-md-6" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px' }}> Sensor Type</label>
                <select className="form-select" aria-label="Default select example"
                  value={editSensorType}
                  style={{ width: '98%' }}
                  onChange={(e) => {
                    if (e.target.value === 'calibration') {
                      setCallibrationModal(true)
                    } else {
                      setEditSensorType(e.target.value);
                      setPatchData({
                        ...patchData,
                        type: e.target.value
                      })
                    }
                  }}
                >
                  {sensorTemplate?.map((type, index) => (
                    <option key={index} value={type.type}>{type.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-row d-flex" >
              <div className="form-group col-md-6" >
                <label for="inputEmail5" style={{ fontSize: '13px' }}>Parameter Type</label>
                <select className="form-select" aria-label="Default select example" style={{ width: '98%' }}
                  value={editSensorParam}
                  onChange={(e) => {
                    setEditSensorParam(e.target.value);
                    setPatchData({
                      ...patchData,
                      param: e.target.value
                    })
                  }}
                >
                  {sensorTemplate?.map((type, index) => (
                    <option key={index} value={type.param}>{type.param}</option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-6" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px' }}>Result Type</label>
                <select className="form-select" aria-label="Default select example" style={{ width: '98%' }}
                  value={editSensorResultType}
                  onChange={(e) => {
                    setEditSensorResultType(e.target.value);
                    setPatchData({
                      ...patchData,
                      resultType: e.target.value
                    })
                  }}
                >
                  {sensorTemplate?.map((type, index) => (
                    <option key={index} value={type.resultType}>{type.resultType}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-row d-flex" >
              <div className="form-group col-md-6" >
                <label for="inputEmail6" style={{ fontSize: '13px' }}>Units of measurement</label>
                <select className="form-select" aria-label="Default select example" style={{ width: '98%' }}
                  value={editSensorUnit}
                  onChange={(e) => {
                    setEditSensorUnit(e.target.value);
                    setPatchData({
                      ...patchData,
                      units: e.target.value
                    })
                  }}
                >
                  {sensorTemplate?.map((type, index) => (
                    <option key={index} value={type.units}>{type.units}</option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-6" style={{ marginLeft: '8px' }}>
                <label for="inputPassword42" style={{ fontSize: '13px' }}>If sensor "1" (text)</label>
                <input type="text" className="form-control" id="inputPassword42" placeholder="text 1"
                  style={{ borderRadius: '5px' }}
                  value={editSensorTextOne}
                  onChange={(e) => {
                    setEditSensorTextOne(e.target.value);
                    setPatchData({
                      ...patchData,
                      text1: e.target.value
                    })
                  }}
                />
              </div>
            </div>

            <div className="form-row d-flex" >
              <div className="form-group col-md-6" >
                <label for="inputEmail7" style={{ fontSize: '13px' }}>If sensor "0" (text)</label>
                <input type="email" className="form-control" id="inputEmail7" placeholder="text 0"
                  style={{ borderRadius: '5px' }}
                  value={editSensorTextZero}
                  onChange={(e) => {
                    setEditSensorTextZero(e.target.value);
                    setPatchData({
                      ...patchData,
                      text0: e.target.value
                    })
                  }}
                />
              </div>
              <div className="form-group col-md-6" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px' }}>Formula</label>
                <input type="password" className="form-control" id="inputPassword4" placeholder="formula"
                  style={{ borderRadius: '5px' }}
                  value={editSensorFormula}
                  onChange={(e) => {
                    setEditSensorFormula(e.target.value);
                    setPatchData({
                      ...patchData,
                      formula: e.target.value
                    })
                  }}
                />
              </div>
            </div>
            <div className="form-row d-flex" >
              <div className="form-group col-md-6" >
                <label for="inputEmail8" style={{ fontSize: '13px' }}>Lowest Value</label>
                <input type="email" className="form-control" id="inputEmail8" placeholder="sensor lowest value"
                  style={{ borderRadius: '5px' }}
                  value={editSensorLowestValue}
                  onChange={(e) => {
                    setEditSensorLowestValue(e.target.value)
                    setPatchData({
                      ...patchData,
                      lv: e.target.value
                    })
                  }}
                />
              </div>
              <div className="form-group col-md-6" style={{ marginLeft: '8px', }}>
                <label for="inputPassword9" style={{ fontSize: '13px' }}>Highest Value</label>
                <input type="text" className="form-control" id="inputPassword9" placeholder="sensor highest value"
                  style={{ borderRadius: '5px' }}
                  value={editSensorHighestValue}
                  onChange={(e) => {
                    setEditSensorHighestValue(e.target.value)
                    setPatchData({
                      ...patchData,
                      hv: e.target.value
                    })
                  }}
                />
              </div>
            </div>
            <div class="form-row d-flex" >
              <div class="form-group col-md-4" >
                <Form.Check
                  type="checkbox"
                  id={`default-checkbox1`}
                  label="Data List"
                  checked={editSensorDataList}
                  onChange={(e) => {
                    setEditSensorDataList(!editSensorDataList);
                    setPatchData({
                      ...patchData,
                      dataList: e.target.value
                    })
                  }}
                />
              </div>
              <div class="form-group col-md-4" >
                <Form.Check
                  type="checkbox"
                  id={`default-checkbox2`}
                  label="Pop up"
                  checked={editSensorPopup}
                  onChange={(e) => {
                    setEditSensorPopup(!editSensorPopup)
                    setPatchData({
                      ...patchData,
                      popup: e.target.value
                    })
                  }}
                />
              </div>

              <div class="form-group col-md-4" >
                <Form.Check
                  type="checkbox"
                  id={`default-checkbox3`}
                  label="Acc Ignore"
                  checked={editSensorAccIgnore}
                  onChange={(e) => {
                    setEditSensorAccIgnore(!editSensorAccIgnore)
                    setPatchData({
                      ...patchData,
                      accIgnore: e.target.value
                    })
                  }}
                />
              </div>
            </div>
            <div className="btn-group" style={{ float: 'right', marginTop: '20px' }}>
              <button type="button" className="btn btn-primary" onClick={funcEditSensor}>Save</button>
              <button type="button" className="btn btn-secondary" style={{ marginLeft: '5px' }} onClick={props.onHide}>Cancel</button>

            </div>


          </form>
        )}
      </Modal.Body>

      <CallibrationDeviceModal
        show={callibrationModal}
        onHide={() => setCallibrationModal(false)}
      />

    </Modal>
  );
}
export default EditDeviceModal;

