import React, { memo, Fragment, useState, useEffect, useRef } from 'react'
import "./Style.css"
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

//react-bootstrap
import { Row, Table, Dropdown, Button, Modal, Pagination, Alert } from 'react-bootstrap';

//components
import Card from '../../../components/bootstrap/card';


//importing of the icons from react icons
import { IoMdAdd } from "react-icons/io";
import { TbFilter } from "react-icons/tb";
import { TbSettingsCog } from "react-icons/tb";
import { BiSolidCarGarage } from "react-icons/bi";
import { TfiPencil } from "react-icons/tfi";
import { TfiEye } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import { TfiBookmarkAlt } from "react-icons/tfi";
import { FcCheckmark } from "react-icons/fc";
import { FcCancel } from "react-icons/fc";
import { IoMdRefresh } from "react-icons/io";
import { FaSortAmountDown } from "react-icons/fa";
import { FaSortAmountUp } from "react-icons/fa";

//importing of the modals..
import NewLoader from './speedo-asset/Loader';
import EditDeviceModal from './ModalComponents/EditDeviceModal';
import DeviceDelete from './DeviceModal/DeleteDeviceModal';
import ActivateDevice from './DeviceModal/ActivateModal';
import DeactivateDevice from './DeviceModal/DeactivateModal';
import ExpModal from './ModalComponents/ExpModal';
import ClearHistory from './DeviceModal/ClearDeviceModal';
import EditDeviceTable from './DeviceModal/EditDeviceTable';
import DeviceViewModals from './DeviceViewModal/DeviceViewModals';
import DeviceSensorMain from './DeviceSensor/DeviceSensor';
import AddDeviceModal from './DeviceModal/AddDeviceModal';
import DeviceRenew from './DeviceModal/DeviceRenew';




const Device = memo(() => {

  const devicePrevilages = JSON.parse(localStorage.getItem('loginInfo'))?.privileges?.cpanel?.device;
  const userTypePrivileges = JSON.parse(localStorage.getItem("loginInfo"))?.privileges?.user_type;



  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [tableStatus, setTableStatus] = useState('')
  const [deleteDevice, setDeleteDevice] = useState(false);
  const [activateDevice, setActivateDevice] = useState(false);
  const [deactivateDevice, setDeactivateDevice] = useState(false);
  const [expDevice, setExpDevice] = useState(false);
  const [clearHistory, setClearHistory] = useState(false);
  const [sensorDevice, setSensorDevice] = useState(false);
  const [editDevice, setEditDevice] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedDeviceImei, setSelectedDeviceImei] = useState('')
  const [toggleChange, setToggleChange] = useState(false)
  const [errorFetchingTable, setErrorFetchingTable] = useState(false)


  //declaration of state variables for API data
  const [isLoading, setIsLoading] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [totalPages, setTotalPages] = useState('');

  //other parameters for API call/filtered call
  const [selectedDevices, setSelectedDevices] = useState([])
  const [allDevicesSelected, setAllDevicesSelected] = useState(false)
  const [pageNumber, setPageNumber] = useState('1');
  const [rows, setRows] = useState('20');
  const [field, setField] = useState('imei');
  const [filter, setFilter] = useState('');
  //search
  const [searchField, setSearchField] = useState('all');

  //delete
  const [imeiForDelete, setImeiForDelete] = useState('');
  const [imeiForSensor, setImeiForSensor] = useState('');
  const [modalRenew, setModalRenew] = useState(false);
  //bulk action
  const [bulkActionType, setBulkActionType] = useState('');
  //renew
  const [renewDeviceList, setRenewDeviceList] = useState([]);
  const [translationList, setTranslationList] = useState([]);


  useEffect(() => {
    console.log('bulk action is ', bulkActionType)
  }, [bulkActionType])



  //fetching the devices from Cpanel API
  const cpanelFetchDevices = async () => {
    try {
      setIsLoading(true);

      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem('token'));


      var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders,
      };

      const response = await fetch(`https://gps.speedotrack.com/api/cpanel/device?page=${pageNumber}&rows=${rows}&field=${field}&filter=${filter}`, requestOptions)
      const result = await response.json();
      console.log(result);
      setDeviceList(result.results);
      setTableStatus(`(${result.total} / ${result.results.length} / ${result.online})`)
      setPageNumber(result.page.toString());
      setTotalPages(result.pages);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setErrorFetchingTable(true)
    }
  }


  //fetching the devices from users fetch device API
  const usersFetchDevices = async () => {
    alert('this is fetch user api')
  }


  useEffect(() => {
    if (userTypePrivileges === 'user') {
      usersFetchDevices()
    } else {
      cpanelFetchDevices()
    }
  }, [rows, pageNumber, toggleChange, field, filter])



  const deviceSelectToggle = (imei) => {
    if (selectedDevices.includes(imei)) {
      const removedArray = selectedDevices.filter((item) => item !== imei);
      setSelectedDevices(removedArray);
    } else {
      setSelectedDevices([...selectedDevices, imei]);
    }
  };

  const selectAllDevices = () => {
    if (allDevicesSelected) {
      setSelectedDevices([]);
      setAllDevicesSelected(!allDevicesSelected)
    } else {
      setAllDevicesSelected(!allDevicesSelected)
      const selectedList = deviceList.map(device => device.imei)
      setSelectedDevices(selectedList)
    }
  }

  const bulkActionPerform = async (action) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", localStorage.getItem('token'));

      var raw = JSON.stringify({
        "cmd": action,
        "devices": selectedDevices
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch("https://gps.speedotrack.com/api/cpanel/device/bulk", requestOptions)
      const result = await response.json()
      console.log(result)
      setToggleChange(!toggleChange)
    } catch (error) {
      console.log(error)
    }
  }

  const searchDevice = async (searchValue) => {
    try {
      setIsLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem('token'));

      var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
      };

      const response = await fetch(`https://gps.speedotrack.com/api/cpanel/device?search-field=${searchField}&search-value=${searchValue}`, requestOptions);
      const result = await response.json();
      setDeviceList(result.results);
      console.log("called API", result)
      setTableStatus(`(${result.total} / ${result.results.length} / ${result.online})`)
      setPageNumber(result.page.toString());
      setTotalPages(result.pages);
      setIsLoading(false);

    } catch (error) {
      console.log(error)
      setIsLoading(false)
      setErrorFetchingTable(true)
    }
  }



  //function before opening the renew device option
  const funcRenewDevice = () => {
    setRenewDeviceList([])
    let objectList = []
    selectedDevices?.forEach((device) => {
      const object = deviceList?.find(item => item.imei === device)
      objectList.push(object)
    })
    setRenewDeviceList(objectList)
    setModalRenew(true)
  }



  return (
    <Fragment>
      <Alert variant="danger" show={errorFetchingTable} onClose={() => setErrorFetchingTable(false)} dismissible className="my-2">
        <p>Failed to fetch device list. Please try again later.</p>
      </Alert>
      <Row >
        <Card>

          {/* {isLoading ? (
                <Loader />
              ) : null} */}

          <div className="col-12">
            <Card.Header className="d-flex justify-content-between col-12">
              <div className="header-title">
                <div className="btn-group flex-wrap" role="group" aria-label="Basic example" style={{ marginBottom: '20px' }}><h5 style={{ color: '', marginLeft: '10px', marginRight: '10px', marginTop: '8px', marginBottom: 'px' }}><img src={require('./images/device.png')} style={{ width: '30px' }} /> <b>All</b> <b>Device</b>
                </h5>
                  <span>Show <select type="number"
                    style={{ width: '30%', marginTop: '8px', marginLeft: '' }}
                    onChange={(e) => { setRows(e.target.value) }}
                    value={rows}
                  >
                    <option value='2'>02</option>
                    <option value='5'>05</option>
                    <option value='10'>10</option>
                    <option value='20'>20</option>
                    <option value='30'>30</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </select><span style={{}}> enteries</span></span>

                  <button type="button" className="btn btn-outline-primary" style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', borderRadius: '5px', height: '38px', fontSize: '14px', marginLeft: '140px' }}>{" "}
                    <i className="fa fa-map-marker" style={{ marginRight: '3px' }}></i>

                    <span style={{ fontSize: '12px' }}>{tableStatus}</span>
                  </button>



                  <Dropdown>
                    <Dropdown.Toggle as={Button} variant="outline-primary" type="button" id="dropdownMenuButton " style={{ marginLeft: '5px', border: '1px solid', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', fontSize: '14px' }} disabled={selectedDevices.length === 0 ? true : false}>
                      Action
                    </Dropdown.Toggle>
                    <Dropdown.Menu aria-labelledby="dropdownMenuButton">
                      <Dropdown.Item onClick={() => bulkActionPerform("activate")}>Activate</Dropdown.Item>
                      <Dropdown.Item onClick={() => bulkActionPerform("deactivate")}>Deactivate</Dropdown.Item>
                      <Dropdown.Item onClick={() => bulkActionPerform("delete")}>Delete</Dropdown.Item>
                      <Dropdown.Item onClick={funcRenewDevice}>Renew</Dropdown.Item>
                      <Dropdown.Item onClick={() => bulkActionPerform("send-command")}>Send Command</Dropdown.Item>
                      <Dropdown.Item onClick={() => bulkActionPerform("clear-history")}>Clear History</Dropdown.Item>
                      <Dropdown.Item onClick={() => bulkActionPerform("export")}>Export</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown>
                    <Dropdown.Toggle as={Button} variant="outline-primary" type="button" id="dropdownMenuButton " style={{ marginLeft: '5px', border: '1px solid ', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', height: '37px' }}>
                      <BiSolidCarGarage />

                    </Dropdown.Toggle>
                    <Dropdown.Menu aria-labelledby="dropdownMenuButton">
                      <Dropdown.Item onClick={() => setFilter('all')}>All</Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilter('active')}>Active</Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilter('inactive')}>Inactive</Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilter('expired')}>Expired</Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilter('expiring')}>Expiring</Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilter('stopped')}>Stopped</Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilter('moving')}>Moving</Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilter('idle')}>Idle </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown>
                    <Dropdown.Toggle as={Button} variant="outline-primary" type="button" id="dropdownMenuButton " style={{ marginLeft: '5px', border: '1px solid ', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', height: '37px' }}>
                      <TbSettingsCog />
                    </Dropdown.Toggle>
                    <Dropdown.Menu aria-labelledby="dropdownMenuButton">
                      <Dropdown.Item onClick={() => setActivateDevice(true)} active >Activate</Dropdown.Item>
                      <Dropdown.Item onClick={() => { setBulkActionType('deactivate'); }}>Deactivate</Dropdown.Item>
                      <Dropdown.Item onClick={() => setExpDevice(true)}>Set Expiration</Dropdown.Item>
                      <Dropdown.Item onClick={() => setClearHistory(true)}>Clear History</Dropdown.Item>
                      <Dropdown.Item onClick={() => setDeleteDevice(true)}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown>
                    <Dropdown.Toggle as={Button} variant="outline-primary" type="button" id="dropdownMenuButton " style={{ marginLeft: '5px', border: '1px solid ', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', height: '37px' }}>
                      <TbFilter />
                    </Dropdown.Toggle>
                    <Dropdown.Menu aria-labelledby="dropdownMenuButton">
                      <Dropdown.Item onClick={() => setSearchField('name')}>Name</Dropdown.Item>
                      <Dropdown.Item onClick={() => setSearchField('imei')}>IMEI</Dropdown.Item>
                      <Dropdown.Item onClick={() => setSearchField('plate_number')}>Plate Number</Dropdown.Item>
                      <Dropdown.Item onClick={() => setSearchField('sim_number')}>Sim Number</Dropdown.Item>
                      <Dropdown.Item onClick={() => setSearchField('protocol')}>Protocol</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className=" " style={{ marginTop: '', marginLeft: '5px', marginRight: '' }}>
                    <input type="text" id="inputField" className="form-control" placeholder={searchField !== '' ? `${searchField}...` : 'Search value ...'}
                      style={{ minWidth: '200px', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }}
                      onChange={(e) => searchDevice(e.target.value)}
                    />
                  </div>

                  {devicePrevilages?.create ? (
                    <button type="button" className="btn btn-primary" onClick={() => { setShow(true) }} style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', borderRadius: '5px', marginLeft: '5px', fontSize: '12px' }}>
                      <IoMdAdd /> Add Device
                    </button>
                  ) : null}

                </div>
              </div>
            </Card.Header>
          </div>
        </Card>

        <Card style={{ marginTop: '-18px' }}>
          <Card.Body >
            <div className="" style={{ marginTop: '45px' }}>
              {isLoading ? (
                <NewLoader />
              ) : (
                <Table responsive striped hover size="sm" style={{ marginTop: '20px' }}>
                  <thead>
                    <tr>
                      <th style={{ paddingRight: '0px', paddingLeft: '10px' }}>Sl.No</th>
                      <th style={{ paddingRight: '0px', }} >
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={selectAllDevices} checked={deviceList.length === selectedDevices.length} />
                      </th>

                      <th style={{ paddingLeft: '0px', cursor: 'pointer' }}
                        onClick={() => setField("active")} onDoubleClick={() => setField("-active")}
                      >Active{" "}<span className='text-primary'>{field === 'active' ? (<FaSortAmountDown />) : field === '-active' ? (<FaSortAmountUp />) : null}</span></th>

                      <th style={{ paddingLeft: '0px', cursor: 'pointer' }}
                        onClick={() => setField("expire_dt")} onDoubleClick={() => setField("-expire_dt")}
                      >Expiry{" "}<span className='text-primary'>{field === 'expire_dt' ? (<FaSortAmountDown />) : field === '-expire_dt' ? (<FaSortAmountUp />) : null}</span></th>
                      <th style={{ paddingLeft: '0px', cursor: 'pointer' }}
                        onClick={() => setField("name")} onDoubleClick={() => setField("-name")}
                      >Device Name{" "}<span className='text-primary'>{field === 'name' ? (<FaSortAmountDown />) : field === '-name' ? (<FaSortAmountUp />) : null}</span></th>

                      <th style={{ paddingLeft: '0px', cursor: 'pointer' }}
                        onClick={() => setField("imei")} onDoubleClick={() => setField("-imei")}
                      >Device IMEI{" "}<span className='text-primary'>{field === 'imei' ? (<FaSortAmountDown />) : field === '-imei' ? (<FaSortAmountUp />) : null}</span></th>

                      <th style={{ paddingLeft: '0px', cursor: 'pointer' }}
                        onClick={() => setField("sim_number")} onDoubleClick={() => setField("-sim_number")}
                      >Sim Number{" "}<span className='text-primary'>{field === 'sim_number' ? (<FaSortAmountDown />) : field === '-sim_number' ? (<FaSortAmountUp />) : null}</span></th>

                      <th style={{ paddingLeft: '0px', cursor: 'pointer' }}
                        onClick={() => setField("dt_tracker")} onDoubleClick={() => setField("-dt_tracker")}
                      >Last Connection{" "}<span className='text-primary'>{field === 'dt_tracker' ? (<FaSortAmountDown />) : field === '-dt_tracker' ? (<FaSortAmountUp />) : null}</span></th>

                      <th style={{ paddingLeft: '0px', cursor: 'pointer' }}
                        onClick={() => setField("protocol")} onDoubleClick={() => setField("-protocol")}
                      >Protocol{" "}<span className='text-primary'>{field === 'protocol' ? (<FaSortAmountDown />) : field === '-protocol' ? (<FaSortAmountUp />) : null}</span></th>

                      <th style={{ paddingLeft: '0px', cursor: 'pointer' }}
                        onClick={() => setField("createdAt")} onDoubleClick={() => setField("-createdAt")}
                      >Creation Date{" "}<span className='text-primary'>{field === 'createdAt' ? (<FaSortAmountDown />) : field === '-createdAt' ? (<FaSortAmountUp />) : null}</span></th>

                      <th  >Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deviceList?.map((device, index) => (
                      <tr key={index}>
                        <td style={{ marginLeft: '50px' }}>{index + 1}</td>
                        <td ><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={() => deviceSelectToggle(device.imei)} checked={selectedDevices.includes(device.imei)} /></td>
                        <td>{device.active ? <FcCheckmark style={{ marginLeft: '-10px' }} /> : <FcCancel style={{ marginLeft: '-10px' }} />}</td>
                        <td style={{ paddingLeft: '0px', }}>{device.expire_dt.slice(0, 11)}</td>
                        <td style={{ paddingLeft: '0px', }}>{device.name}</td>
                        <td style={{ paddingLeft: '0px', }}>{device.imei}</td>
                        <td style={{ paddingLeft: '0px', }}>{device.sim_number}</td>
                        <td style={{ paddingLeft: '0px', }}>{device.dt_tracker}	</td>
                        <td style={{ paddingLeft: '0px', }}>{device.protocol}</td>
                        <td style={{ paddingLeft: '0px', }}>{device.createdAt.slice(0, 11)}</td>
                        <td style={{ paddingLeft: '0px', }}>

                          {devicePrevilages?.read || devicePrevilages?.update ? (
                            <a href="#" className="edit" data-toggle="modal" title="View" onClick={() => { setSelectedDeviceImei(device.imei); setViewModal(true) }} >
                              <TfiEye style={{ width: '23px' }} /></a>
                          ) : null}

                          {/* <a href="#deleteEmployeeModal" className="delete" data-toggle="modal" title="Edit" onClick={() => setEditDevice(true)}><TfiPencil style={{ width: '23px' }} /></a> */}
                          {devicePrevilages?.update ? (
                            <a href="#deleteEmployeeModal" className="delete" data-toggle="modal" title="Delete" onClick={() => { setImeiForDelete(device.imei); setDeleteDevice(true) }}><TfiTrash style={{ width: '23px' }} /></a>
                          ) : null}

                          <a href="#deleteEmployeeModal" className="delete" data-toggle="modal" title="Sensor" onClick={() => { setImeiForSensor(device.imei); setModalShow(true) }}> <TfiBookmarkAlt style={{ width: '23px' }} /></a>

                        </td>
                      </tr>
                    ))}

                  </tbody>
                </Table>
              )}
              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="link" onClick={cpanelFetchDevices}><IoMdRefresh /></Button>

                <Pagination size="sm">
                  <Pagination.Prev
                    disabled={pageNumber === '1' ? true : false}
                    onClick={() => setPageNumber((parseInt(pageNumber) - 1).toString())}
                  />
                  {
                    (
                      <>
                        {Array.from({ length: totalPages }).map((_, i) => (
                          <Pagination.Item
                            key={i}
                            onClick={() => setPageNumber((i + 1).toString())}
                            active={pageNumber === (i + 1).toString() ? true : false}
                          >{i + 1}
                          </Pagination.Item>
                        ))}
                      </>
                    )
                  }
                  <Pagination.Next
                    disabled={pageNumber === totalPages.toString() ? true : false}
                    onClick={() => setPageNumber((parseInt(pageNumber) + 1).toString())}
                  />

                  {/* <Pagination.First />
                      <Pagination.Item>{1}</Pagination.Item>
                      <Pagination.Ellipsis />

                      <Pagination.Item>{10}</Pagination.Item>
                      <Pagination.Item>{11}</Pagination.Item>
                      <Pagination.Item active>{12}</Pagination.Item>
                      <Pagination.Item>{13}</Pagination.Item>
                      <Pagination.Item disabled>{14}</Pagination.Item>

                      <Pagination.Ellipsis />
                      <Pagination.Item>{20}</Pagination.Item>
                      <Pagination.Last /> */}
                </Pagination>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Row>

      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content" style={{ minWidth: '600px', borderRadius: '5px', paddingBottom: '20px' }}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Edit Device </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">

              <form lg="12">
                <div className="form-row d-flex">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Name</label>
                    <input type="email" className="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="inputPassword4">Imei</label>
                    <input type="password" className="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                </div>
                <div className="form-row d-flex">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Device Model</label>
                    <input type="email" className="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="inputPassword4">Sim No.</label>
                    <input type="password" className="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                </div>
                <div className="form-row d-flex">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Plate No.</label>
                    <input type="email" className="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="inputPassword4">Device Expiry</label>
                    <input type="password" className="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                </div>
                <div className="form-row d-flex">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Coins</label>
                    <input type="email" className="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="inputPassword4">Vehicle Types</label>
                    <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                      <div className="btn-group mr-2" role="group" aria-label="First group" style={{ marginTop: '5px' }}>
                        <span type="" className="ml-2" style={{ marginLeft: '3px', fontSize: '25px' }}><i className='fas fa-bus' ></i>
                        </span>
                        <span type="" className="ml-2" style={{ marginLeft: '3px', fontSize: '25px' }}><i className='fas fa-car'></i>

                        </span>
                        <span type="" className="ml-2" style={{ marginLeft: '3px', fontSize: '25px' }}><i className='fas fa-bus' ></i>
                        </span>
                        <span type="" className="ml-2" style={{ marginLeft: '3px', fontSize: '25px' }}><i className='fas fa-car-side'></i>

                        </span>
                        <span type="" className="ml-2" style={{ marginLeft: '3px', fontSize: '25px' }}><i className='fas fa-truck'></i>

                        </span>
                        <span type="" className="ml-2" style={{ marginLeft: '3px', fontSize: '25px' }}><i className='fas fa-truck-pickup'></i>

                        </span>

                      </div>
                    </div>
                  </div>
                </div>
                <Select style={{ width: '90%' }}

                  isMulti
                  name="colors"
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
                <button type="submit" className="btn btn-primary" style={{ width: '98%', borderRadius: '5px', marginTop: '20px' }}>Edit Device »</button>
              </form>
            </div>


          </div>
        </div>
      </div>



      {/* {
        showCreate ? (
          <DeviceCreateModal
            show={showCreate}
            onHide={() => setShowCreate(false)}
            setShowAlert={setShowAlert}
          />
        ) : null
      } 
      {
        showDetail ? (
          <DeviceDetailModal
            show={showDetail}
            onHide={() => setShowDetail(false)}
            details={details}
          />
        ) : null
      } 

      {
        showEditModal ? (
          <DeviceEditModal
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            details={editDetails}
          />
        ) : null
      } 


      {
        showDeviceSensorModal ? (
          <DeviceSensorModal
            show={showDeviceSensorModal}
            onHide={() => setShowDeviceSensorModal(false)}
            details={details}
          />
        ) : null
      } 

      {showCreateSensor ? (
        <CreateSensorModal
          show={showCreateSensor}
          onHide={() => setShowCreateSensor(false)}
        details={props.details}
        setSensorCreated={setSensorCreated}
        sensorCreated={sensorCreated}
        />
      ) : null}  */}


      {show ? (
        <AddDeviceModal
          show={show}
          onHide={() => setShow(false)}
          toggleChange={toggleChange}
          setToggleChange={setToggleChange}
        />
      ) : null}

      <EditDeviceTable
        show={editDevice}
        onHide={() => setEditDevice(false)}
      />

      <DeviceDelete
        show={deleteDevice}
        onHide={() => setDeleteDevice(false)}
        imeiForDelete={imeiForDelete}
        toggleChange={toggleChange}
        setToggleChange={setToggleChange}
      />

      <ActivateDevice
        show={activateDevice}
        onHide={() => setActivateDevice(false)}

      />

      <DeactivateDevice
        show={deactivateDevice}
        onHide={() => setDeactivateDevice(false)}
      />

      <ExpModal
        show={expDevice}
        onHide={() => setExpDevice(false)}
      />

      <ClearHistory
        show={clearHistory}
        onHide={() => setClearHistory(false)}
      />

      {viewModal ? (
        <DeviceViewModals
          show={viewModal}
          onHide={() => setViewModal(false)}
          selectedDeviceImei={selectedDeviceImei}
          toggleChange={toggleChange}
          setToggleChange={setToggleChange}
        />
      ) : null}

      {modalShow ? (
        <DeviceSensorMain
          show={modalShow}
          onHide={() => setModalShow(false)}
          imeiForSensor={imeiForSensor}
        />
      ) : null}



      {modalRenew ? (
        <DeviceRenew
          show={modalRenew}
          onHide={() => {setModalRenew(false); setSelectedDevices([])}}
          renewDeviceList={renewDeviceList}
          setRenewDeviceList={setRenewDeviceList}
          translationList={translationList}
          setTranslationList={setTranslationList}
        />
      ) : null}

    </Fragment >
  )
}
)

Device.displayName = "Device"
export default Device;
