import React from 'react';
import { Button, ButtonGroup, Card} from 'react-bootstrap';
import "./Style.css";

import { Routes, Route, Link, NavLink } from 'react-router-dom';
// import Card from '../../../components/bootstrap/card';



//importing of the componenets
import ObjectSetting from './ObjectSetting';
import EventSetting from './EventSetting';
import TemplateSetting from './TemplateSetting';
import SmsSetting from './SmsSetting';
import UserInterfaceSetting from './UserInterfaceSetting';
import MyAccountSetting from './MyAccountSetting';
import SubAccountSetting from './SubAccountSetting';


const Setting = () => {
  return (
    <div>
      <Card style={{ borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }}>

        <h6 className="card-title" >
          <img src={require('./images/coin.png')} style={{ width: '3%', marginLeft: '10px', marginTop: '10px' }} />{" "}
          Mi Coins Balance : 69</h6>
        <div className=" topnav mx-auto w-100 z-index-5 d-flex align-items-center justify-content-center">

          <ButtonGroup className="btn-group w-100" >
            <NavLink to="/dashboard/setting">
              <Button variant="link" className="btn btn-link btn-lg active" data-bs-toggle="tooltip" title="Objects setting." style={{ fontSize: '14px' }}>Import & Renew</Button>

            </NavLink>
            <Link to='/dashboard/setting/event-setting'>
              <Button variant="link" className="btn btn-link btn-lg" data-bs-toggle="tooltip" title="Events setting." style={{ fontSize: '14px' }} >Transfer Mi Coins</Button>
            </Link>
            <Link to='/dashboard/setting/template-setting'>
              <Button variant="link" className="btn btn-link btn-lg" data-bs-toggle="tooltip" title="Templates setting" style={{ fontSize: '14px' }} >Insufficient balance reminder</Button>
            </Link>


          </ButtonGroup>
        </div>

        <Routes>
          <Route path='/' element={<ObjectSetting />} />
          <Route path='/event-setting' element={<EventSetting />} />
          <Route path='/template-setting' element={<TemplateSetting />} />
          <Route path='/sms-setting' element={<SmsSetting />} />
          <Route path='/user-interface-setting' element={<UserInterfaceSetting />} />
          <Route path='/my-account-setting' element={<MyAccountSetting />} />
          <Route path='/sub-account-setting' element={<SubAccountSetting />} />
        </Routes>
      </Card>
    </div>
  )
}

export default Setting;
