import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import "./Sing.css";
import { Link } from "react-router-dom";


import { Button } from 'react-bootstrap';


// Import selectors & action from setting store
import * as SettingSelector from '../../../store/setting/selectors';
import { setLoginSuccess } from '../../../store/login/loginSlice';


function SignIn() {
   // Declaration of the state variables..... 

   //form inputs
   const [formUsername, setFormUsername] = useState('');
   const [formPassword, setFormPassword] = useState('');
   //checks
   const [missingUsername, setMissingUsername] = useState(false);
   const [missingPassword, setMissingPassword] = useState(false);
   const [isFetching, setIsFetching] = useState(false);
   const [loginSuccess, setLoginSuccess] = useState(false)
   //API responses
   const [loginError, setLoginError] = useState(false);

   const dispatch = useDispatch();
   const appName = useSelector(SettingSelector.app_name);
   const navigate = useNavigate();



   //funciton for logout
   useEffect(() => {
      const funcLogout = async () => {
         if (localStorage.setItem('loginInfo')) {
            var urlencoded = new URLSearchParams();

            var requestOptions = {
               method: 'POST',
               body: urlencoded,
               redirect: 'follow'
            };

            const response = await fetch("https://gps.speedotrack.com/api/logout", requestOptions)
            const result = await response.json()
            console.log(result)
         }
      }

      funcLogout()
   })


   //function for login
   const fetchLogin = async () => {
      try {
         setIsFetching(true)
         const myHeaders = new Headers();
         myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

         const urlencoded = new URLSearchParams();
         urlencoded.append("uname", formUsername);
         urlencoded.append("pwd", formPassword);

         const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
         };

         const response = await fetch("https://gps.speedotrack.com/api/login", requestOptions);
         const result = await response.json();

         if (response.ok) {
            setIsFetching(false)
            console.log(result);
            localStorage.setItem('loginInfo', JSON.stringify(result));
            localStorage.setItem('token', result?.token?.token);
            navigate('/speedotrack/user-dashboard', { replace: true })
         }
      } catch (error) {
         setIsFetching(false)
         setLoginError(true)
         console.log(error);
      }
   }




   //Method, when submitting the form
   const handleSubmit = async () => {

      setLoginError(false);
      setMissingUsername(!formUsername || formUsername === '');
      setMissingPassword(!formPassword || formPassword === '');

      if (!missingUsername && !missingPassword) {
         fetchLogin()

      }
   };






   return (
      <>
         <div className="login-container" style={{ marginTop: '7%' }}>
            <div className="login-container" style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '15px', padding: '20px' }}>
               <div className="onboarding">
                  <div className="swiper-container">
                     <div className="swiper-wrapper">
                        <div className="swiper-slide color-1">
                           <div className="slide-image">
                              <img src={require("./images/gif.gif")} />
                           </div>
                           <div className="slide-content" style={{ marginTop: '-60px' }}>
                              <h5>SpeedoTrack GPS Tracking</h5>
                              <p style={{ fontSize: '14px' }}>Consistent quality and eperience across all platform and devices</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="login-form" >
                  <div className="login-form-inner float-right">
                     <div className="logo">
                        <img src={require("./images/logos.png")} style={{ width: '50%', marginBottom: '50px', marginTop: '18px', marginLeft: '25%' }} />
                     </div>

                     <div className="sign-in-seperator" style={{ marginTop: '-30px' }}>
                        <span>Login to your account</span>
                     </div>
                     <div className="login-form-group">
                        <label htmlFor="email">Username <span className="required-star">*</span></label>
                        <input type="text" placeholder="Enter username" id="email"
                           value={formUsername}
                           onChange={(e) => { setFormUsername(e.target.value) }}
                        />
                        {missingUsername ? (
                           <p className='text-danger'> Please enter username</p>
                        ) : null}
                     </div>
                     <div className="login-form-group">
                        <label htmlFor="pwd">Password <span className="required-star">*</span></label>
                        <input autoComplete="off" type="password" placeholder="Enter password" id="pwd"
                           value={formPassword}
                           onChange={(e) => {
                              setFormPassword(e.target.value)
                           }}
                        />
                        {missingPassword ? (
                           <p className='text-danger'> Please enter password</p>
                        ) : null}
                     </div>

                     <div className="login-form-group single-row">
                        <div className="custom-check">

                        </div>

                        <Link to="/forgot" className="link forgot-link" style={{ marginTop: '-10px' }}>Forgot Password ?</Link>
                        <br />
                     </div>

                     <p className={'text-danger text-center ' + (loginError ? 'd-block' : 'd-none')}><b>Invalid username or password.</b></p>
                     <p className={'text-primary text-center ' + (isFetching ? 'd-block' : 'd-none')}><b>Please wait !</b></p>
                     <p className={'text-success text-center ' + (loginSuccess ? 'd-block' : 'd-none')}><b>Logged in sucessfully !!</b></p>

                     <Button
                        className={`rounded-button login-cta ${isFetching ? 'disabled' : ''}`}
                        onClick={handleSubmit}
                        style={{ marginTop: '-10px' }}
                     >
                        Sign In
                     </Button>



                     <div className="register-div" style={{ marginLeft: '12%', marginTop: '-15px' }}>Not registered yet? <Link to="/register" className="link create-account">Create an account ?</Link></div>
                  </div>

               </div>

            </div>

         </div>


      </>
   )
};


export default SignIn;


