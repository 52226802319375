import React, { useState, useEffect } from 'react'
import { Table, Button, Pagination, Form, Modal } from "react-bootstrap";
import { TfiPencil } from "react-icons/tfi";
import { TfiEye } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import { TfiBookmarkAlt } from "react-icons/tfi";
import { FcCheckmark } from "react-icons/fc";
import { TbSettingsCog } from "react-icons/tb";
import { HiOutlineRefresh } from "react-icons/hi";
import { IoIosSend } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import Multiselect from 'multiselect-react-dropdown';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import NewLoader from '../speedo-asset/Loader';


const Gprs = () => {
  const [deviceOption, setDeviceOptions] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [selectedObject, setSelectedObject] = useState([]);

  //confirm modal
  const [showConfirmModal, setShowConfirmModal] = useState(false)


  const toggleRow = (itemId) => {
    setExpandedRow(itemId === expandedRow ? null : itemId);
  };

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [commandType, setCommandType] = useState('getver');

  const deviceListData = JSON.parse(localStorage.getItem('loginInfo'));

  //pagination
  const [isLoading, setIsLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState('1')
  const [totalPages, setTotalPages] = useState('');
  const [rows, setRows] = useState('50');
  const [sortValue, setSortValue] = useState('createdAt')


  useEffect(() => {
    const fetching = async () => {
      try {
        setIsLoading(true)
        var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };

        const response = await fetch(`https://gps.speedotrack.com/api/device-commands?page=${pageNumber}&rows=${rows}&sort=${sortValue}`, requestOptions)
        const result = await response.json()
        setTableData(result?.results?.commands)
        setTotalPages(result?.results?.pages)
        setIsLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    fetching()
  }, [pageNumber, rows, sortValue])



  const funcFetchCommands = async () => {
    try {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/device-commands?page=${pageNumber}&rows=${rows}&sort=${sortValue}`, requestOptions)
      const result = await response.json()
      setTableData(result?.results?.commands)
      setTotalPages(result?.results?.pages)
    } catch (error) {
      console.log(error)
    }
  }

  // useEffect(() => {
  //   funcFetchCommands()
  // }, [pageNumber, rows, sortValue])

  useEffect(() => {
    const intervalId = setInterval(funcFetchCommands, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [pageNumber, rows, sortValue]);



  const addCommand = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "imeis": selectedValue,
        "cmd": commandType,
        "name": "test"
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch("https://gps.speedotrack.com/api/device-commands", requestOptions);
      const result = await response.json();
      console.log('API Result:', result);
      if (result && result.data) {
        setData(result.data);
        funcFetchCommands()
      }

    } catch (error) {
      console.log('API Error:', error);
    }
  };




  useEffect(() => {
    console.log('table data is ', tableData)
  }, [tableData])


  useEffect(() => {
    const listDevices = deviceListData?.devices?.map((device, index) => (
      {
        name: device.name,
        imei: device.imei
      }
    ))
    setDeviceOptions(listDevices)
  }, [])

  return (
    <>
      <div>
        <div class="card" style={{ marginTop: '-20px' }}>
          <div class="card-body" style={{ minHeight: '25px', marginTop: '-15px' }}>
            <div class="row" style={{ marginTop: '10px' }}>
              <div class="col-2">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Filter</label>
                    <select class="form-select" aria-label="Default select example" style={{ minWidth: "200px", marginTop: "10px" }}>
                      <option selected>Open this select menu</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>                </div>
                </form>
              </div>
              <div className="col-2">
                <form>
                  <div className="form-group">
                    {/* <label htmlFor="exampleInputEmail1" style={{ marginLeft: '10px' }}>Object</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    style={{ minWidth: "200px", marginTop: "10px", marginLeft: '3px' }}
                    value={selectedValue}
                    onChange={(e) => setSelectedValue(e.target.value)}
                  >
                    {
                      devicesList?.map(item => (
                        <option key={item._id} value={item.imei}>
                          {item.name}
                        </option>
                      ))
                    } </select> */}
                    <label htmlFor="exampleInputEmail1" style={{ marginLeft: '10px' }}>Object</label>

                    <Multiselect
                      showCheckbox={true}
                      displayValue="name"
                      isObject={true}
                      onKeyPressFn={function noRefCheck() { }}
                      onRemove={(e) => { setSelectedValue(e.map(value => value.imei)) }}
                      onSearch={function noRefCheck() { }}
                      onSelect={(e) => { setSelectedValue(e.map(value => value.imei)); console.log(e) }}
                      options={deviceOption}
                      hideSelectedList
                      placeholder="Select Data "
                      style={{
                        multiselectContainer: {
                          width: '200px'
                        },
                      }}
                    />
                  </div>
                </form>
              </div>
              <div class="col-2">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1" style={{ marginLeft: '10px' }}>Templates</label>
                    <select class="form-select" aria-label="Default select example" style={{ minWidth: "200px", marginTop: "10px", marginLeft: '4px' }}>
                      <option selected>Open this select menu</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </form>
              </div>
              <div class="col-2">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1" style={{ marginLeft: '10px' }}>Commands</label>
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=""
                      style={{ minWidth: "200px", marginTop: "10px", marginLeft: '4px' }}
                      value={commandType}
                      onChange={(e) => { setCommandType(e.target.value) }}
                    />
                  </div>
                </form>
              </div>
              <div class="col-2">
                <form>
                  <div className="input-group rounded" >
                    <div class="form-group has-search" style={{ minWidth: "200px", marginTop: "46px", marginLeft: '4px' }}>
                      <span class="fa fa-search form-control-feedback"></span>
                      <input type="text" class="form-control" placeholder="Search..." style={{ marginTop: '-15px' }} />
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-2">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1"></label>

                    <button type="button" class="btn btn-primary"
                      style={{ marginTop: '30px', marginLeft: '10px' }}
                      onClick={() => { setShowConfirmModal(true) }}
                    ><IoIosSend /> {" "}Send Command
                    </button>

                  </div>
                </form>
              </div>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Form.Select
                  aria-label="Default select example"
                  style={{ width: '70px' }}
                  size="sm"
                  value={rows}
                  onChange={(e) => { setRows(e.target.value) }}
                >
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="250">250</option>
                  <option value="500">500</option>
                </Form.Select>
              </div>
            </div>
            <div className="table-responsive" style={{ marginTop: '10px' }}>


              {isLoading ? (
                <div className="w-100" style={{ height: '300px', width: '500px' }}>
                  <NewLoader />
                </div>
              ) : (
                <Table striped hover size="sm">
                  <thead style={{ paddingBottom: '20px' }}>
                    <tr>
                      <th style={{ paddingRight: '0px' }}><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></th>
                      <th style={{ paddingLeft: '0px', marginLeft: '0px' }}>Sl. no</th>
                      <th style={{ paddingLeft: '0px', marginLeft: '0px', cursor: 'pointer' }}
                        onClick={() => { setSortValue('createdAt') }}
                        onDoubleClick={() => { setSortValue('-createdAt') }}
                      >Created Date</th>
                      {/* <th style={{paddingLeft:'0px', marginLeft:'0px'}}>Updated Date</th> */}

                      <th style={{ paddingLeft: '0px', marginLeft: '0px', cursor: 'pointer' }}
                        onClick={() => { setSortValue('name') }}
                        onDoubleClick={() => { setSortValue('-name') }}
                      >Object</th>

                      <th style={{ paddingLeft: '0px', marginLeft: '0px', cursor: 'pointer' }}
                        onClick={() => setSortValue('imei')}
                        onDoubleClick={() => { setSortValue('-imei') }}
                      >IMEI</th>
                      <th style={{ paddingLeft: '0px', marginLeft: '0px', cursor: 'pointer' }}
                        onClick={() => { setSortValue('cmd') }}
                        onDoubleClick={() => { setSortValue('-cmd') }}
                      >Command</th>
                      <th style={{ paddingLeft: '0px', cursor: 'pointer' }}
                        onClick={() => { setSortValue('status') }}
                        onDoubleClick={() => { setSortValue('-status') }}
                      >Status</th>
                      <th style={{ paddingLeft: '0px', marginLeft: '0px' }}>Reply</th>

                    </tr>
                  </thead>

                  {isLoading ? (
                    <NewLoader />
                  ) : (
                    <tbody>
                      {tableData?.map((item, index) => (
                        <React.Fragment key={item._id}>
                          <tr>
                            <td ><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                            <td>{index + 1}</td>
                            <td style={{ paddingLeft: '0px', marginLeft: '0px' }}>{item?.createdAt}</td>
                            {/* <td style={{paddingLeft:'0px', marginLeft:'0px'}}>{item.updatedAt}</td> */}
                            <td style={{ paddingLeft: '0px', marginLeft: '0px' }}>{item?.name}</td>
                            <td style={{ paddingLeft: '0px', marginLeft: '0px' }}>{item?.imei}</td>
                            <td style={{ paddingLeft: '0px', marginLeft: '0px' }}>{item?.cmd}</td>
                            <td style={{ paddingLeft: '0px', marginLeft: '0px' }}>


                              {item?.status ? (
                                // {item?.status}
                                <FcCheckmark style={{ marginLeft: '', width: '15px' }} />
                              ) : (
                                <img src={require('./images/gifs.gif')} style={{ marginLeft: '', width: '15px' }} />
                              )}


                            </td>

                            <td style={{ paddingLeft: '0px', marginLeft: '0px' }}>
                              <Button
                                variant="link"
                                onClick={() => toggleRow(item._id)}
                                aria-controls={`replyContent_${item._id}`}
                                aria-expanded={expandedRow === item._id}
                                style={{ marginLeft: '-15px' }}
                              >
                                {expandedRow === item._id ? 'Collapse' : (item?.reply?.slice(0, 70) + '...')}
                              </Button>
                            </td>
                          </tr>
                          {expandedRow === item._id && (
                            <tr>
                              <td colSpan="12">
                                <div id={`replyContent_${item._id}`} className="collapse show">
                                  {/* Your expanded content here */}
                                  <span style={{ float: 'right' }}>{item?.reply}</span>
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  )}

                </Table>
              )}

              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                <Pagination size="sm">
                  <Pagination.Prev
                    disabled={pageNumber === '1' ? true : false}
                    onClick={() => setPageNumber((parseInt(pageNumber) - 1).toString())}
                  />
                  {
                    (
                      <>
                        {Array.from({ length: totalPages }).map((_, i) => (
                          <Pagination.Item
                            key={i}
                            onClick={() => setPageNumber((i + 1).toString())}
                            active={pageNumber === (i + 1).toString() ? true : false}
                          >{i + 1}
                          </Pagination.Item>
                        ))}
                      </>
                    )
                  }
                  <Pagination.Next
                    disabled={pageNumber === totalPages.toString() ? true : false}
                    onClick={() => setPageNumber((parseInt(pageNumber) + 1).toString())}
                  />

                </Pagination>
              </div>

            </div>

          </div>
          <div class="card-footer d-flex bg-light" style={{ padding: "2px" }}>
            <button type="button" class="btn btn-primary btn-sm btn-block" title="Refresh" onClick={funcFetchCommands}><HiOutlineRefresh style={{ fontSize: '15px' }} /> </button>
            <button type="button" class="btn btn- btn-sm btn-block" title="Action" onClick={() => setShowConfirmModal(true)}><MdDeleteOutline style={{ fontSize: '15px' }} /> </button>

          </div>
        </div>







      </div>

      <div style={{ width: '100vw' }}>
        <Modal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {selectedValue.length > 0 ? (
              'Are you sure you want to send command ?'
            ) : (
              `No device is selected.`
            )
            }

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => { setShowConfirmModal(false); setSelectedValue([]) }}>
              Cancel
            </Button>
            <Button variant="primary"
              onClick={addCommand}
              disabled={selectedValue.length > 0 ? false : true}
            >Confirm</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )


}

export default Gprs;
