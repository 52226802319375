import React, { useState, useEffect } from 'react';


import { MapContainer, TileLayer, Marker, Popup, LayersControl, Polyline, Polygon, Tooltip, FeatureGroup, useMapEvents, ZoomControl } from 'react-leaflet';
import { Icon } from "leaflet";
import MarkerClusterGroup from 'react-leaflet-cluster'
import 'leaflet-rotatedmarker';
import { LeafletTrackingMarker } from "react-leaflet-tracking-marker";
import FullscreenControl from 'react-leaflet-fullscreen';
import "react-leaflet-fullscreen/styles.css";
import 'leaflet/dist/leaflet.css';
import GoogleLayer, { GOOGLE_LAYER_TYPES } from "react-leaflet-google-layer";
import "leaflet-draw/dist/leaflet.draw.css";
import { EditControl } from 'react-leaflet-draw';
import { useSelector } from 'react-redux';

const { BaseLayer, Overlay } = LayersControl;

const customIcon = new Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/128/3253/3253113.png", //"https://cdn-icons-png.flaticon.com/128/1047/1047003.png", //"https://cdn-icons-png.flaticon.com/128/1505/1505502.png", //"https://cdn-icons-png.flaticon.com/128/3253/3253113.png", 
  iconSize: [30, 30],
  iconAnchor: [15, 15],
})
const historyIcon = new Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/128/684/684908.png", //"https://cdn-icons-png.flaticon.com/128/1047/1047003.png", //"https://cdn-icons-png.flaticon.com/128/1505/1505502.png", //"https://cdn-icons-png.flaticon.com/128/3253/3253113.png", 
  iconSize: [20, 20],
  iconAnchor: [10, 10],
})







const MonitorMap = (props) => {

  const trackingData = useSelector(state => state.tracking.monitorData);
  const historyRouteData = useSelector(state => state.history.route);

  const [showToolTip, setShowToolTip] = useState(false);
  // const [position, setPosition] = useState(null);




  useEffect(() => {
    setShowToolTip(props.showToolTip);
  }, [props.showToolTip]);



  //react-leaflet-draw
  const zoneCreated = (e) => {
    console.log(e)
    const latlngs = e.layer._latlngs
    if (e.layerType === 'polyline') {
      console.log('route latlngs are', latlngs)
      const filteredLatlngs = latlngs.map((latLng) => {
        return {
          lat: latLng.lat,
          lng: latLng.lng,
        };
      });
      console.log("the route is ", filteredLatlngs)
      props.setRoutePoints(filteredLatlngs)

    } else {
      console.log('latlngs are', latlngs)
      const filteredLatlngs = latlngs[0].map((latLng) => {
        return {
          lat: latLng.lat,
          lng: latLng.lng,
        };
      });
      console.log("the zone is ", filteredLatlngs)
      props.setZoneVertices(filteredLatlngs)
    }
  }
  const zoneDeleted = (e) => {
    props.setZoneVertices([])
  }

  const handleClick = (e) => {
    console.log('You clicked the map at:', e.latlng);
    props.setMarkerLat(e.latlng.lat);
    props.setMarkerLng(e.latlng.lng);
  };




  return (
    <div>
      <MapContainer
        style={{ width: '80vw', height: '99.5vh', zIndex: 6, position: 'absolute', top: 0, left: '370px' }} //marginLeft: '350px'
        // center={[0, 0]}
        center={[22.22089, 84.752747]}
        minZoom={3}
        zoom={14}
      >

        {props.addZoneCheck || props.addRouteCheck ? (
          <FeatureGroup>
            <EditControl
              position="topright"
              onCreated={zoneCreated}
              onDeleted={zoneDeleted}
              draw={{
                polygon: props.addZoneCheck,
                polyline: props.addRouteCheck,
                rectangle: props.addZoneCheck,
                circle: false,
                circlemarker: false,
                marker: false
              }}
            />
          </FeatureGroup>
        ) : null}

        <LayersControl
          position="topleft"
          style={{ backgroundColor: 'red' }}
        >
          <BaseLayer name='Google Maps Roadmap' checked>
            <GoogleLayer
              googleKey={'AIzaSyDGg4aC4fmWg6htlOPn4qEg_PO8xl1W6Lo'}
              maxZoom={21}
              type={'roadmap'}
            />
            {/* <ZoomControl position="topleft" /> */}
          </BaseLayer>
          <BaseLayer name='Google Maps Satellite'>
            <GoogleLayer
              googleKey={'AIzaSyDGg4aC4fmWg6htlOPn4qEg_PO8xl1W6Lo'}
              maxZoom={21}
              type={'satellite'}
            />
          </BaseLayer>
          <BaseLayer name='Google Maps Terrain'>
            <GoogleLayer
              googleKey={'AIzaSyDGg4aC4fmWg6htlOPn4qEg_PO8xl1W6Lo'}
              maxZoom={21}
              type={'terrain'}
            />
          </BaseLayer>
          <BaseLayer name='Google Maps Hybrid'>
            <GoogleLayer
              googleKey={'AIzaSyDGg4aC4fmWg6htlOPn4qEg_PO8xl1W6Lo'}
              maxZoom={21}
              type={'hybrid'}
            />
          </BaseLayer>
          <BaseLayer name='Google Maps Traffic'>
            <GoogleLayer
              googleKey={'AIzaSyDGg4aC4fmWg6htlOPn4qEg_PO8xl1W6Lo'}
              type="traffic"
            />
          </BaseLayer>
          <BaseLayer name="Open Street Map">
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </BaseLayer>
          <BaseLayer name="Open Street Map France">
            <TileLayer
              attribution='&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url='https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png'
            />
          </BaseLayer>
          <BaseLayer name="Base Map Carto ">
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
              url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png'
            />
          </BaseLayer>
          <BaseLayer name="Open Street Hot Map">
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
              url='https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'
            />
          </BaseLayer>
          <BaseLayer name="Open Top Map">
            <TileLayer
              attribution='Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
              url='https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png'
            />
          </BaseLayer>
          <BaseLayer name="Cycl OSM">
            <TileLayer
              attribution='<a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a> | Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url='https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png'
            />
          </BaseLayer>
          <BaseLayer name="Ersi World Imagery">
            <TileLayer
              attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
              url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
            />
          </BaseLayer>
          <BaseLayer name="Stadia Dark Map">
            <TileLayer
              attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url='https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.{ext}'
              ext='png'
            />
          </BaseLayer>
          <BaseLayer name="Stadia Terrain">
            <TileLayer
              attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url='https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}{r}.{ext}'
              ext='png'
            />
          </BaseLayer>
          <BaseLayer name="Ersi Digita Globe">
            <TileLayer
              attribution="Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community"
              className="basemap"
              maxNativeZoom={40}
              maxZoom={40}
              subdomains={["clarity"]}
              url="https://{s}.maptiles.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            />
          </BaseLayer>

        </LayersControl>


        {/* <MarkerClusterGroup chunkedLoading > */}
        {props.showMarker && props.showToolTip && !props.showCluster ? (
          <>
            {trackingData.map((value, index) => (
              <>
                <LeafletTrackingMarker
                  key={index}
                  // keepAtCenter={true}
                  rotationOrigin="center"
                  position={[value?.location_data?.lat, value?.location_data?.lng]}
                  previousPosition={
                    [
                      value?.location_data?.latB,
                      value?.location_data?.lngB
                    ]
                  }
                  icon={customIcon}
                // duration={10000}
                >

                  <Tooltip permanent>
                    {value.name}
                  </Tooltip>
                  <Popup>
                    <div>
                      <p>Name : {value?.name}</p>
                      <p>Date : {value.location_data?.dt_tracker?.slice(0, 10)}</p>
                      <p>Time : {value?.location_data?.dt_tracker?.slice(12, 19)}</p>
                      <p>Altitude : {value?.location_data?.altitude}</p>
                      <p>Speed : {value?.location_data?.speed}</p>
                      <p>Angle : {value?.location_data?.angle}</p>
                      <p>time : {value?.location_data?.dt_tracker}</p>
                      <p>Position : <a href={`https://www.google.com/maps?q=${value?.location_data?.lat},${value?.location_data?.lng}`} target='blank'>
                        {`${value?.location_data?.lat}, ${value?.location_data?.lng}`}
                      </a>
                      </p>
                    </div>
                  </Popup>
                </LeafletTrackingMarker>

                {props.showTail ? (
                  <Polyline
                    positions={value?.tail?.points?.map((point) => [point?.lat, point?.lng])}
                    color={value?.tail?.color}
                  />
                ) : null}
              </>
            ))}


          </>
        ) : null}

        {/* </MarkerClusterGroup> */}




        {/* for showing markers without tooltip. */}
        {props.showMarker && !props.showToolTip && !props.showCluster ? (
          <>
            {trackingData.map((value, index) => (
              <>
                <LeafletTrackingMarker
                  key={index}
                  // keepAtCenter={true}
                  rotationOrigin="center"
                  position={[value?.location_data?.lat, value?.location_data?.lng]}
                  previousPosition={
                    [
                      value?.location_data?.latB,
                      value?.location_data?.lngB
                    ]
                  }
                  icon={customIcon}
                // duration={10000}
                >

                  <Tooltip>
                    {value.name}
                  </Tooltip>
                  <Popup>
                    <div>
                      <p>Name : {value?.name}</p>
                      <p>Date : {value.location_data?.dt_tracker?.slice(0, 10)}</p>
                      <p>Time : {value?.location_data?.dt_tracker?.slice(12, 19)}</p>
                      <p>Altitude : {value?.location_data?.altitude}</p>
                      <p>Speed : {value?.location_data?.speed}</p>
                      <p>Angle : {value?.location_data?.angle}</p>
                      <p>time : {value?.location_data?.dt_tracker}</p>
                      <p>Position : <a href={`https://www.google.com/maps?q=${value?.location_data?.lat},${value?.location_data?.lng}`} target='blank'>
                        {`${value?.location_data?.lat}, ${value?.location_data?.lng}`}
                      </a>
                      </p>
                    </div>
                  </Popup>
                </LeafletTrackingMarker>

                {props.showTail ? (
                  <Polyline
                    positions={value?.tail?.points?.map((point) => [point?.lat, point?.lng])}
                    color={value?.tail?.color}
                  />
                ) : null}
              </>
            ))}


          </>
        ) : null}




        {props.showCluster ? (
          <MarkerClusterGroup chunkedLoading >
            {/* {props.showMarker && props.showToolTip ? ( */}
              <>
                {trackingData.map((value, index) => (
                  <>
                    <LeafletTrackingMarker
                      key={index}
                      // keepAtCenter={true}
                      rotationOrigin="center"
                      position={[value?.location_data?.lat, value?.location_data?.lng]}
                      previousPosition={
                        [
                          value?.location_data?.latB,
                          value?.location_data?.lngB
                        ]
                      }
                      icon={customIcon}
                    // duration={10000}
                    >

                      {/* <Tooltip permanent>
                        {value.name}
                      </Tooltip> */}
                      <Popup>
                        <div>
                          <p>Name : {value?.name}</p>
                          <p>Date : {value.location_data?.dt_tracker?.slice(0, 10)}</p>
                          <p>Time : {value?.location_data?.dt_tracker?.slice(12, 19)}</p>
                          <p>Altitude : {value?.location_data?.altitude}</p>
                          <p>Speed : {value?.location_data?.speed}</p>
                          <p>Angle : {value?.location_data?.angle}</p>
                          <p>time : {value?.location_data?.dt_tracker}</p>
                          <p>Position : <a href={`https://www.google.com/maps?q=${value?.location_data?.lat},${value?.location_data?.lng}`} target='blank'>
                            {`${value?.location_data?.lat}, ${value?.location_data?.lng}`}
                          </a>
                          </p>
                        </div>
                      </Popup>
                    </LeafletTrackingMarker>

                    {/* {props.showTail ? (
                      <Polyline
                        positions={value?.tail?.points?.map((point) => [point?.lat, point?.lng])}
                        color={value?.tail?.color}
                      />
                    ) : null} */}
                  </>
                ))}


              </>
            {/* ) : null} */}

          </MarkerClusterGroup>

        ) : null}


        {/* for showing single zones on map */}
        {props?.zoneMap?.vertices?.length > 0 ? (
          <Polygon pathOptions={{ color: props?.zoneMap?.color }} positions={props?.zoneMap?.vertices?.map(({ lat, lng }) => [lat, lng])}>
            <Tooltip direction="bottom" offset={[0, 20]} opacity={1} permanent>
              {props?.zoneMap?.name}
            </Tooltip>
          </Polygon>
        ) : null}


        {/* for handling the click events, for the add marker API */}
        <ClickHandler onClick={handleClick} />



        {/* for showing zones in the map */}
        {props?.showZone && props?.zoneList?.length > 0 ? (
          <>
            {props.zoneList.map((zone, index) => (
              zone.vertices.length > 0 ? (
                <Polygon key={index} pathOptions={{ color: zone.color }} positions={zone.vertices.map(({ lat, lng }) => [lat, lng])}>
                  <Tooltip direction="bottom" offset={[0, 20]} opacity={1} permanent>
                    {zone.name}
                  </Tooltip>
                </Polygon>
              ) : null
            ))}
          </>
        ) : null}

        {/* for showing routes in the map */}
        {props?.showRoute && props.routeList.length > 0 ? (
          <>
            {props.routeList.map((route, index) => (
              route?.points.length > 0 ? (
                <Polyline key={index} positions={route?.points} color={route.color} />
              ) : null
            ))}
          </>
        ) : null}


        {/* for showing history route on map */}
        {historyRouteData.length > 0 ? (
          <>
            <Polyline
              positions={historyRouteData?.map((point) => [point[1], point[2]])}
              color='blue'
            />
          </>
        ) : null}


        {/* for showing history markers on map  */}
        {/* {historyRouteData.length > 0 ? (
          <>
            {
              historyRouteData?.map((historyIcon, index) => (
                <Marker position={[historyIcon.lat, historyIcon.lng]}
                  icon={historyIcon}
                >
                  <Popup>
                    A pretty CSS3 popup. <br /> Easily customizable.
                  </Popup>
                </Marker>
              ))
            }
          </>
        ) : null} */}



      </MapContainer>
    </div>
  )
}

export default MonitorMap



function ClickHandler({ onClick }) {
  const map = useMapEvents({
    click: (e) => {
      onClick(e);
      console.log(e)
    },
  });

  return null;


}