import React, { useEffect, useState } from 'react';
import { Card, Container, Dropdown, Table } from 'react-bootstrap';
import { IoEllipsisVertical } from "react-icons/io5";
import { PieChart } from 'react-minimal-pie-chart';
import Chart from "react-apexcharts";
import './Style.css';
import CustomToggle from '../../components/dropdowns'
import { MdAccessTime } from "react-icons/md";
import { GrVmMaintenance } from "react-icons/gr";

import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { CiLocationArrow1 } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import { IoCloudOfflineOutline } from "react-icons/io5";
import { CiBellOff } from "react-icons/ci";


import { MapContainer, TileLayer, Marker, Popup, LayersControl, Polyline, Polygon, Tooltip, FeatureGroup, useMapEvents, ZoomControl } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster'
import GoogleLayer, { GOOGLE_LAYER_TYPES } from "react-leaflet-google-layer";
import { Icon } from "leaflet";



const { BaseLayer, Overlay } = LayersControl;


const getVariableColor = () => {
    let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
    if (prefix) {
        prefix = prefix.trim()
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
    const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
    const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
    const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
    return {
        primary: color1.trim(),
        info: color2.trim(),
        warning: color4.trim(),
        primary_light: color3.trim(),
    };
}
const variableColors = getVariableColor();
// const [checked, setChecked] = useState(true);
const colors = [variableColors.primary, variableColors.info];
// useEffect(() => {
//     return () => colors
// })

const chart1 = {
    options: {
        chart: {
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        colors: colors,
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "28%",
                endingShape: "rounded",
                borderRadius: 3,
            },
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 3,
            colors: ["transparent"],
        },
        grid: {
            show: true,
            strokeDashArray: 7,
        },
        xaxis: {
            categories: ["S", "M", "T", "W", "T", "F", "S"],
            labels: {
                minHeight: 20,
                maxHeight: 20,
                style: {
                    colors: "#8A92A6",
                },
            },
        },
        yaxis: {
            title: {
                text: "",
            },
            labels: {
                minWidth: 20,
                maxWidth: 20,
                style: {
                    colors: "#8A92A6",
                },
            },
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return "$ " + val + " thousands";
                },
            },
        },
        responsive: [
            {
                breakpoint: 1025,
                options: {
                    chart: {
                        height: 130,
                    },
                },
            },
        ],
    },
    series: [
        {
            name: "Successful deals",
            data: [30, 50, 35, 60, 40, 60, 60],
        },
        {
            name: "Failed deals",
            data: [40, 50, 55, 50, 30, 80, 30],
        }
    ],
}

const chart2 = {
    options: {

        colors: colors,
        chart: {

            toolbar: {
                show: false,
            },
        },
        forecastDataPoints: {
            count: 3,
        },
        stroke: {
            width: 3,
        },
        grid: {
            show: true,
            strokeDashArray: 7,
        },
        markers: {
            size: 6,
            colors: "#FFFFFF",
            strokeColors: colors,
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 0,
            shape: "circle",
            radius: 2,
            offsetX: 0,
            offsetY: 0,
        },
        xaxis: {
            categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
            ],
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
        },
    },
    series: [
        {
            name: "Sales",
            data: [10, 82, 75, 68, 47, 60, 49, 91, 108],
        },
    ]
}



const UserDashboard = () => {
    const [dashboardData, setDashboardData] = useState({});


    const funcFetchUserDashboardData = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", localStorage.getItem('token'));

            var requestOptions = {
                method: 'GET',
                redirect: 'follow',
                headers: myHeaders
            };

            const response = await fetch("https://gps.speedotrack.com/api/dashboard/user", requestOptions)
            const result = await response.json()
            setDashboardData(result)
            console.log(result)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        funcFetchUserDashboardData()
    }, [])

    const customIcon = new Icon({
        iconUrl: "https://cdn-icons-png.flaticon.com/128/3253/3253113.png", //"https://cdn-icons-png.flaticon.com/128/1505/1505502.png", //, 
        iconSize: [25, 25],
        iconAnchor: [15, 15],
    })

    return (
        <div>
            <div class="row">
                <div class="col-8">
                    <h5>Dashboard</h5>
                    <p style={{ fontSize: '13px', marginTop: "-5px" }}>User</p>

                    <div class="d-flex flex-row mb-3">
                        <div class="p-2"><Card style={{ border: 'none', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', padding: '7px', minWidth: '170px' }}>
                            <div className="" style={{
                                display: 'flex', alignItems: 'center',
                                height: '50px',
                                flexShrink: '0', borderRadius: '7px',
                                background: '', marginTop: '-5px', marginLeft: '',
                            }}>
                                <CiLocationArrow1 style={{ fontSize: '30px' }} />{" "}
                                <span style={{
                                    color: '',

                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                    opacity: '0.8',
                                    marginLeft: '8px'
                                }}>Total
                                    <br></br>
                                    <span style={{ marginTop: '8px', fontSize: '13px' }}>{" "}{dashboardData?.devices_info?.total}</span></span>
                            </div>
                        </Card></div>

                        <div class="p-2">
                            <Card style={{ border: 'none', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', padding: '7px', minWidth: '170px' }}>
                                <div className="" style={{
                                    display: 'flex', alignItems: 'center', width: '100px',
                                    height: '50px',
                                    flexShrink: '0', borderRadius: '7px',
                                    background: '', marginTop: '-5px', marginLeft: '',
                                }}>
                                    <CiLocationOn style={{ fontSize: '30px' }} />{" "}
                                    <span style={{
                                        color: '',

                                        fontSize: '13px',
                                        fontStyle: 'normal',
                                        lineHeight: 'normal',
                                        opacity: '0.8',
                                        marginLeft: '8px'
                                    }}>Active
                                        <br></br>
                                        <span style={{ marginTop: '8px', fontSize: '13px' }}>{" "}{dashboardData?.devices_info?.active}</span></span>
                                </div>
                            </Card></div>
                        <div class="p-2">
                            <Card style={{ border: 'none', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', padding: '7px', minWidth: '170px' }}>
                                <div className="" style={{
                                    display: 'flex', alignItems: 'center', width: '100px',
                                    height: '50px',
                                    flexShrink: '0', borderRadius: '7px',
                                    background: '', marginTop: '-5px', marginLeft: '',
                                }}>
                                    <IoCloudOfflineOutline style={{ fontSize: '30px' }} />{" "}
                                    <span style={{
                                        color: '',

                                        fontSize: '13px',
                                        fontStyle: 'normal',
                                        lineHeight: 'normal',
                                        opacity: '0.8',
                                        marginLeft: '8px'
                                    }}>Deactive
                                        <br></br>
                                        <span style={{ marginTop: '8px', fontSize: '13px' }}>{" "}{dashboardData?.devices_info?.deactive}</span></span>
                                </div>
                            </Card></div>
                        <div class="p-2"><Card style={{ border: 'none', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', padding: '7px', minWidth: '170px', borderLeftColor: 'red', }}>
                            <div className="" style={{
                                display: 'flex', alignItems: 'center', width: '100px',
                                height: '50px',
                                flexShrink: '0', borderRadius: '7px',
                                background: '', marginTop: '-5px', marginLeft: '',
                            }}>
                                <CiBellOff style={{ fontSize: '30px' }} />{" "}
                                <span style={{
                                    color: '',

                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                    opacity: '0.8',
                                    marginLeft: '8px',

                                }}>Expired
                                    <br></br>
                                    <span style={{ marginTop: '8px', fontSize: '13px' }}>{" "}{dashboardData?.devices_info?.expired}</span></span>
                            </div>
                        </Card></div>
                    </div>
                    <div class="container" style={{ marginTop: '-25px' }}>
                        <div class="row">
                            <div class="col-sm">

                                <b>Events Todays</b>
                            </div>
                            <div class="col-sm">
                                <b>More {" "}<IoEllipsisVertical /></b>
                            </div>
                            <div class="col-sm">
                                <b>Task Today</b>
                            </div>
                            <div class="col-sm">
                                <b>More</b> {" "}<IoEllipsisVertical />
                            </div>
                            <div class="col-sm">
                                <b>Maintainence</b>
                            </div>
                            <div class="col-sm">
                                <b>More</b> {" "} <IoEllipsisVertical />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm" style={{ fontSize: '13px' }}>
                                some text here
                            </div>

                            <div class="col-sm" style={{ fontSize: '13px' }}>
                                some text here
                            </div>

                            <div class="col-sm" style={{ fontSize: '13px' }}>
                                some text here
                            </div>

                        </div>
                    </div>
                    <div class="container" style={{ marginTop: '-80px' }}>
                        <div class="row">
                            <div class="col-sm" >
                                <PieChart
                                    style={{ width: '80%' }}
                                    data={[
                                        { title: `Total ${dashboardData?.devices_info?.total}`, value: dashboardData?.devices_info?.total, color: '#E38627' },
                                        { title: `Active ${dashboardData?.devices_info?.active}`, value: dashboardData?.devices_info?.active, color: '#C13C37' },
                                        { title: `Deactive ${dashboardData?.devices_info?.deactive}`, value: dashboardData?.devices_info?.deactive, color: '#6A2135' },
                                    ]}
                                />
                            </div>
                            <div class="col-sm" style={{ marginTop: '20px' }}>
                                <div class="d-flex flex-column" style={{ marginLeft: '-30px', marginTop: '20px' }}>
                                    <div class="p-2"><div class="col-sm">
                                        <div class="p-2"><Card class="border border-primary" style={{ border: 'none', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', padding: '7px', minWidth: '170px' }}>
                                            <div className="" style={{
                                                display: 'flex', alignItems: 'center', width: '100px',
                                                height: '50px',
                                                flexShrink: '0', borderRadius: '7px',
                                                background: '', marginTop: '-5px', marginLeft: '',
                                            }}>
                                                <img src={require('../images/wifi.png')} style={{
                                                    width: '30px',
                                                    marginTop: '',
                                                    marginLeft: '5px',
                                                    height: '30px',
                                                    flexShrink: '0'
                                                }} />{" "}
                                                <span style={{
                                                    color: '',

                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    opacity: '0.8',
                                                    marginLeft: '8px'
                                                }}>Total
                                                    <br></br>
                                                    <span style={{ marginTop: '8px', fontSize: '13px' }}>{" "}{dashboardData?.devices_info?.total}</span></span>
                                            </div>
                                        </Card>
                                        </div>
                                    </div></div>
                                    <div class="p-2" style={{ marginTop: '-30px' }}><div class="col-sm">
                                        <div class="p-2"><Card class="border-left" style={{ border: 'none', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', padding: '7px', minWidth: '170px', marginTop: '-20px' }}>
                                            <div className="" style={{
                                                display: 'flex', alignItems: 'center', width: '100px',
                                                height: '50px',
                                                flexShrink: '0', borderRadius: '7px',
                                                background: '', marginTop: '-5px', marginLeft: '',
                                            }}>
                                                <img src={require('../images/wifi.png')} style={{
                                                    width: '30px',
                                                    marginTop: '',
                                                    marginLeft: '5px',
                                                    height: '30px',
                                                    flexShrink: '0'
                                                }} />{" "}
                                                <span style={{
                                                    color: '',

                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    opacity: '0.8',
                                                    marginLeft: '8px'
                                                }}>Active
                                                    <br></br>
                                                    <span style={{ marginTop: '8px', fontSize: '13px' }}>{" "}{dashboardData?.devices_info?.active}</span></span>
                                            </div>
                                        </Card>
                                        </div>
                                    </div></div>
                                    <div class="p-2"><div class="col-sm">
                                        <div class="p-2"><Card class="border-left" style={{ border: 'none', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', padding: '7px', minWidth: '170px', marginTop: '-50px' }}>
                                            <div className="" style={{
                                                display: 'flex', alignItems: 'center', width: '100px',
                                                height: '50px',
                                                flexShrink: '0', borderRadius: '7px',
                                                background: '', marginTop: '-5px', marginLeft: '',
                                            }}>
                                                <img src={require('../images/wifi.png')} style={{
                                                    width: '30px',
                                                    marginTop: '',
                                                    marginLeft: '5px',
                                                    height: '30px',
                                                    flexShrink: '0'
                                                }} />{" "}
                                                <span style={{
                                                    color: '',

                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    opacity: '0.8',
                                                    marginLeft: '8px'
                                                }}>Inactive
                                                    <br></br>
                                                    <span style={{ marginTop: '8px', fontSize: '13px' }}>{" "}{dashboardData?.devices_info?.deactive}</span></span>
                                            </div>
                                        </Card>
                                        </div>
                                    </div></div>
                                    <div class="p-2"><div class="col-sm">
                                        <div class="p-2"><Card class="border-left" style={{ border: 'none', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', padding: '7px', minWidth: '170px', marginTop: '-50px' }}>
                                            <div className="" style={{
                                                display: 'flex', alignItems: 'center', width: '100px',
                                                height: '50px',
                                                flexShrink: '0', borderRadius: '7px',
                                                background: '', marginTop: '-5px', marginLeft: '',
                                            }}>
                                                <img src={require('../images/wifi.png')} style={{
                                                    width: '30px',
                                                    marginTop: '',
                                                    marginLeft: '5px',
                                                    height: '30px',
                                                    flexShrink: '0'
                                                }} />{" "}
                                                <span style={{
                                                    color: '',

                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    opacity: '0.8',
                                                    marginLeft: '8px'
                                                }}>Expired
                                                    <br></br>
                                                    <span style={{ marginTop: '8px', fontSize: '13px' }}>{" "}{dashboardData?.devices_info?.expired}</span></span>
                                            </div>
                                        </Card>
                                        </div>
                                    </div></div>
                                </div>
                            </div>

                            <div class="col-sm" style={{ marginTop: '20px', marginLeft: '15px' }}>
                                <Container className="py-5">
                                    <ul className="timeline">
                                        <li className="timeline-item mb-5">
                                            <h6 className="">Moving<br></br>
                                                <span style={{ fontSize: '11px' }}>{dashboardData?.devices_info?.moving}</span></h6>

                                        </li>
                                        <li className="timeline-item mb-5">
                                            <h6 className="">Stopped<br></br>
                                                <span style={{ fontSize: '11px' }}>{dashboardData?.devices_info?.stopped}</span></h6>

                                        </li>
                                        <li className="timeline-item mb-5">
                                            <h6 className="">Idle<br></br>
                                                <span style={{ fontSize: '11px' }}>{dashboardData?.devices_info?.idle}</span></h6>

                                        </li>
                                        {/* <li className="timeline-item mb-5">
                                            <h6 className="">Left the Geo fence <br></br>
                                                <span style={{ fontSize: '11px' }}>12.30 AM Morning</span></h6>

                                        </li> */}
                                    </ul>
                                </Container>

                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-4">
                    <h6>Live tracking</h6>
                    {/* <!--Google map--> */}
                    <div id="map-container-google-1" class="z-depth-1-half map-container" style={{ height: '400px', marginTop: '45px' }}>
                        {/* <iframe src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0"
                            style={{ border: '0' }} allowfullscreen></iframe> */}


                        <MapContainer
                            style={{ height: '400px' }} //marginLeft: '350px'
                            // center={[0, 0]}
                            center={[22.22089, 84.752747]}
                            minZoom={3}
                            zoom={5}
                        >

                            <LayersControl
                                position="topright"
                                style={{ backgroundColor: 'red' }}
                            >
                                <BaseLayer name='Google Maps Roadmap' checked>
                                    <GoogleLayer
                                        googleKey={'AIzaSyDGg4aC4fmWg6htlOPn4qEg_PO8xl1W6Lo'}
                                        maxZoom={21}
                                        type={'roadmap'}
                                    />

                                </BaseLayer>
                                <BaseLayer name='Google Maps Satellite'>
                                    <GoogleLayer
                                        googleKey={'AIzaSyDGg4aC4fmWg6htlOPn4qEg_PO8xl1W6Lo'}
                                        maxZoom={21}
                                        type={'satellite'}
                                    />
                                </BaseLayer>
                                <BaseLayer name='Google Maps Terrain'>
                                    <GoogleLayer
                                        googleKey={'AIzaSyDGg4aC4fmWg6htlOPn4qEg_PO8xl1W6Lo'}
                                        maxZoom={21}
                                        type={'terrain'}
                                    />
                                </BaseLayer>
                                <BaseLayer name='Google Maps Hybrid'>
                                    <GoogleLayer
                                        googleKey={'AIzaSyDGg4aC4fmWg6htlOPn4qEg_PO8xl1W6Lo'}
                                        maxZoom={21}
                                        type={'hybrid'}
                                    />
                                </BaseLayer>
                                <BaseLayer name='Google Maps Traffic'>
                                    <GoogleLayer
                                        googleKey={'AIzaSyDGg4aC4fmWg6htlOPn4qEg_PO8xl1W6Lo'}
                                        type="traffic"
                                    />
                                </BaseLayer>
                                <BaseLayer name="Open Street Map">
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                </BaseLayer>
                                <BaseLayer name="Open Street Map France">
                                    <TileLayer
                                        attribution='&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url='https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png'
                                    />
                                </BaseLayer>
                                <BaseLayer name="Base Map Carto ">
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                                        url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png'
                                    />
                                </BaseLayer>
                                <BaseLayer name="Open Street Hot Map">
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
                                        url='https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'
                                    />
                                </BaseLayer>
                                <BaseLayer name="Open Top Map">
                                    <TileLayer
                                        attribution='Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
                                        url='https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png'
                                    />
                                </BaseLayer>
                                <BaseLayer name="Cycl OSM">
                                    <TileLayer
                                        attribution='<a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a> | Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url='https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png'
                                    />
                                </BaseLayer>
                                <BaseLayer name="Ersi World Imagery">
                                    <TileLayer
                                        attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                                        url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                                    />
                                </BaseLayer>
                                <BaseLayer name="Stadia Dark Map">
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url='https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.{ext}'
                                        ext='png'
                                    />
                                </BaseLayer>
                                <BaseLayer name="Stadia Terrain">
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url='https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}{r}.{ext}'
                                        ext='png'
                                    />
                                </BaseLayer>
                                <BaseLayer name="Ersi Digita Globe">
                                    <TileLayer
                                        attribution="Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community"
                                        className="basemap"
                                        maxNativeZoom={40}
                                        maxZoom={40}
                                        subdomains={["clarity"]}
                                        url="https://{s}.maptiles.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                    />
                                </BaseLayer>

                            </LayersControl>


                            <MarkerClusterGroup
                                chunkedLoading
                            >

                                {
                                    dashboardData?.devices?.map((device, index) => (
                                        <Marker
                                            key={index}
                                            position={[device?.lat, device?.lng]}
                                            icon={customIcon}
                                        >
                                            <Popup>
                                                <p>Name : {device?.name}</p>
                                                <p>Imei : {device?.imei}</p>
                                                <p>Status : {device?.status}</p>
                                            </Popup>
                                        </Marker>

                                    ))
                                }


                            </MarkerClusterGroup>


                        </MapContainer>









                    </div>
                </div>
                <div class="row" style={{ marginTop: '-15px' }}>
                    <div class="col-4"> <Card className="card-block card-stretch card-height">
                        <Card.Header>
                            <div className=" d-flex justify-content-between  flex-wrap">
                                <h6 className="card-title">Odometer Top 10 (km)<br></br>
                                    <p style={{ fontSize: '12px' }}>some title here</p>
                                </h6>
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} href="#" variant="text-secondary dropdown-toggle" size="sm" id="dropdownMenuButton1">
                                        This year
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#">Year</Dropdown.Item>
                                        <Dropdown.Item href="#">Month</Dropdown.Item>
                                        <Dropdown.Item href="#">Week</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Chart options={chart2.options} series={chart2.series} type="line" height="100%" className="dashboard-line-chart"></Chart>
                        </Card.Body>
                    </Card></div>
                    <div class="col-4"> <Card className="card-block card-stretch card-height">
                        <Card.Header>
                            <div className=" d-flex justify-content-between  flex-wrap">
                                <h6 className="card-title">Mileage (km)<br></br>
                                    <p style={{ fontSize: '12px' }}>some title here</p></h6>
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} href="#" variant="text-secondary dropdown-toggle" size="sm" id="dropdownMenuButton1">
                                        This year
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#">Year</Dropdown.Item>
                                        <Dropdown.Item href="#">Month</Dropdown.Item>
                                        <Dropdown.Item href="#">Week</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Chart options={chart2.options} series={chart2.series} type="line" height="100%" className="dashboard-line-chart"></Chart>
                        </Card.Body>
                    </Card></div>
                    <div class="col-4">
                        <h6>Expiring Devices</h6>
                        {/* <h6 style={{ float: 'right', marginTop: '-22px' }}><a href="#">See All</a></h6> */}

                        <Card style={{ border: 'none', marginTop: '15px', }}>
                            <div className="" style={{
                                display: 'flex', alignItems: 'center', width: '450px',
                                height: '60px',
                                flexShrink: '0', borderRadius: '7px',
                                background: '', marginTop: '-5px', marginLeft: '', marginRight: '25px',
                            }}>
                                <img src={require('../images/delivery-truck.png')} style={{
                                    width: '30px',
                                    marginTop: '',
                                    marginLeft: '18px',
                                    height: '30px',
                                    flexShrink: '0'
                                }} />{" "}
                                <span style={{
                                    color: '',

                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                    opacity: '0.8',
                                    marginLeft: '15px'
                                }}>Demo Vehicle 1
                                    <br></br>
                                    <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}<img src={require('../images/clock.png')} style={{ width: '10px' }} />{" "} Moving 28.34 km</span></span>
                                <span style={{
                                    display: 'inlineFlex',
                                    padding: '4px 4px',
                                    alignItems: 'flex-start',
                                    gap: '10px',
                                    borderRadius: '14px',
                                    border: '1px solid #F7941E',
                                    color: '#F7941E',

                                    fontSize: "10px",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    marginLeft: '30px'
                                }}>12 Kph</span>
                                {/* <span style={{
                                    width: "10px",
                                    height: "12px",
                                    marginTop: '-15px',
                                    marginLeft: '10px',
                                    flexShrink: '0'
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 20 16" fill="none">
                                        <g opacity="0.6">
                                            <path d="M14.2534 9.71712C13.0841 8.67766 11.5739 8.10349 10.0094 8.10349C8.44479 8.10349 6.93463 8.67766 5.76527 9.71712C5.66646 9.80262 5.58562 9.9069 5.52745 10.0239C5.46928 10.1409 5.43494 10.2683 5.42642 10.3987C5.41789 10.5291 5.43536 10.6599 5.47781 10.7834C5.52025 10.907 5.58683 11.0209 5.67367 11.1186C5.7605 11.2162 5.86588 11.2956 5.98366 11.3522C6.10145 11.4088 6.2293 11.4414 6.35979 11.4481C6.49028 11.4549 6.62082 11.4356 6.74381 11.3915C6.8668 11.3474 6.9798 11.2792 7.07624 11.1911C7.88443 10.4728 8.9281 10.076 10.0094 10.076C11.0906 10.076 12.1343 10.4728 12.9425 11.1911C13.1381 11.3649 13.3947 11.454 13.6559 11.4386C13.9171 11.4232 14.1616 11.3047 14.3`35`4 11.1091C14.5093 10.9135 14.5983 10.6569 14.5829 10.3957C14.5675 10.1344 14.449 9.89003 14.2534 9.71618V9.71712Z" fill="#00B33D" />
                                            <path d="M10.0087 4.05238C7.3603 4.04873 4.81026 5.05531 2.87843 6.86689C2.78062 6.95451 2.70128 7.06077 2.64508 7.17945C2.58888 7.29813 2.55694 7.42683 2.55114 7.55802C2.54533 7.6892 2.56578 7.82022 2.61127 7.9434C2.65677 8.06658 2.72641 8.17944 2.8161 8.27535C2.90579 8.37126 3.01372 8.4483 3.13358 8.50194C3.25343 8.55559 3.38279 8.58477 3.51407 8.58776C3.64535 8.59076 3.7759 8.56751 3.89808 8.51939C4.02025 8.47126 4.13159 8.39923 4.22556 8.3075C5.79415 6.841 7.86131 6.02519 10.0087 6.02519C12.156 6.02519 14.2232 6.841 15.7918 8.3075C15.8857 8.39923 15.9971 8.47126 16.1192 8.51939C16.2414 8.56751 16.372 8.59076 16.5033 8.58776C16.6345 8.58477 16.7639 8.55559 16.8837 8.50194C17.0036 8.4483 17.1115 8.37126 17.2012 8.27535C17.2909 8.17944 17.3605 8.06658 17.406 7.9434C17.4515 7.82022 17.472 7.6892 17.4662 7.55802C17.4604 7.42683 17.4284 7.29813 17.3722 7.17945C17.316 7.06077 17.2367 6.95451 17.1389 6.86689C15.2071 5.05531 12.657 4.04873 10.0087 4.05238Z" fill="#00B33D" />
                                            <path d="M19.6716 3.68418C17.0122 1.31137 13.5727 0 10.0087 0C6.44459 0 3.00511 1.31137 0.34573 3.68418C0.245783 3.76956 0.163907 3.87405 0.104915 3.99152C0.0459228 4.10898 0.0110039 4.23706 0.00220997 4.36821C-0.006584 4.49936 0.0109244 4.63095 0.0537064 4.75524C0.0964885 4.87953 0.163681 4.99402 0.251336 5.09197C0.338991 5.18993 0.44534 5.26937 0.564134 5.32564C0.682929 5.38191 0.811771 5.41387 0.943092 5.41964C1.07441 5.42542 1.20556 5.40488 1.32884 5.35925C1.45211 5.31361 1.56502 5.2438 1.66092 5.15391C3.95858 3.10474 6.92972 1.9723 10.0084 1.9723C13.0871 1.9723 16.0583 3.10474 18.3559 5.15391C18.5509 5.32832 18.8073 5.41811 19.0685 5.40353C19.3297 5.38895 19.5744 5.2712 19.7488 5.07618C19.9233 4.88115 20.013 4.62484 19.9985 4.36361C19.9839 4.10238 19.8661 3.85765 19.6711 3.68324L19.6716 3.68418Z" fill="#E1DCD2" />
                                            <path d="M10.0087 15.4058C10.8388 15.4058 11.5117 14.7328 11.5117 13.9027C11.5117 13.0726 10.8388 12.3996 10.0087 12.3996C9.17854 12.3996 8.50558 13.0726 8.50558 13.9027C8.50558 14.7328 9.17854 15.4058 10.0087 15.4058Z" fill="#00B33D" />
                                        </g>
                                    </svg></span> */}
                                {/* <i className="fa fa-ellipsis-v" aria-hidden="true" style={{ marginLeft: '15px' }}></i> */}
                                <span style={{ fontSize: '12px', marginLeft: '10px' }}>(12-12-2023)</span>
                            </div>
                        </Card>
                        {/* <div class="card" style={{ width: '27.5rem', marginTop: '-20px' }}>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item"><MdAccessTime />{" "}Sensor Stalled (12/APR/2020)</li>
                                <li class="list-group-item" ><span style={{ fontSize: '12px', color: '	#A8A8A8' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.  </span></li>
                                <button type="button" class="btn btn-primary btn-sm" style={{ marginLeft: '' }}><GrVmMaintenance /> {" "}Assign for maintainence</button>


                            </ul>
                        </div> */}

                        {/* <Card style={{ border: 'none', marginTop: '15px', }}>
                            <div className="" style={{
                                display: 'flex', alignItems: 'center', width: '272px',
                                height: '60px',
                                flexShrink: '0', borderRadius: '7px',
                                background: '', marginTop: '-5px', marginLeft: '', marginRight: '25px',
                            }}>
                                <img src={require('../images/delivery-truck.png')} style={{
                                    width: '30px',
                                    marginTop: '',
                                    marginLeft: '18px',
                                    height: '30px',
                                    flexShrink: '0'
                                }} />{" "}
                                <span style={{
                                    color: '',

                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                    opacity: '0.8',
                                    marginLeft: '10px'
                                }}>Demo Vehicle 1
                                    <br></br>
                                    <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}<img src={require('../images/clock.png')} style={{ width: '10px' }} />{" "} Moving 28.34 km</span></span>
                                <span style={{
                                    display: 'inlineFlex',
                                    padding: '4px 4px',
                                    alignItems: 'flex-start',
                                    gap: '10px',
                                    borderRadius: '14px',
                                    border: '1px solid #F7941E',
                                    color: '#F7941E',

                                    fontSize: "10px",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    marginLeft: '30px'
                                }}>12 Kph</span>
                                <span style={{
                                    width: "10px",
                                    height: "12px",
                                    marginTop: '-15px',
                                    marginLeft: '10px',
                                    flexShrink: '0'
                                }}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 20 16" fill="none">
                                        <g opacity="0.6">
                                            <path d="M14.2534 9.71712C13.0841 8.67766 11.5739 8.10349 10.0094 8.10349C8.44479 8.10349 6.93463 8.67766 5.76527 9.71712C5.66646 9.80262 5.58562 9.9069 5.52745 10.0239C5.46928 10.1409 5.43494 10.2683 5.42642 10.3987C5.41789 10.5291 5.43536 10.6599 5.47781 10.7834C5.52025 10.907 5.58683 11.0209 5.67367 11.1186C5.7605 11.2162 5.86588 11.2956 5.98366 11.3522C6.10145 11.4088 6.2293 11.4414 6.35979 11.4481C6.49028 11.4549 6.62082 11.4356 6.74381 11.3915C6.8668 11.3474 6.9798 11.2792 7.07624 11.1911C7.88443 10.4728 8.9281 10.076 10.0094 10.076C11.0906 10.076 12.1343 10.4728 12.9425 11.1911C13.1381 11.3649 13.3947 11.454 13.6559 11.4386C13.9171 11.4232 14.1616 11.3047 14.3`35`4 11.1091C14.5093 10.9135 14.5983 10.6569 14.5829 10.3957C14.5675 10.1344 14.449 9.89003 14.2534 9.71618V9.71712Z" fill="#00B33D" />
                                            <path d="M10.0087 4.05238C7.3603 4.04873 4.81026 5.05531 2.87843 6.86689C2.78062 6.95451 2.70128 7.06077 2.64508 7.17945C2.58888 7.29813 2.55694 7.42683 2.55114 7.55802C2.54533 7.6892 2.56578 7.82022 2.61127 7.9434C2.65677 8.06658 2.72641 8.17944 2.8161 8.27535C2.90579 8.37126 3.01372 8.4483 3.13358 8.50194C3.25343 8.55559 3.38279 8.58477 3.51407 8.58776C3.64535 8.59076 3.7759 8.56751 3.89808 8.51939C4.02025 8.47126 4.13159 8.39923 4.22556 8.3075C5.79415 6.841 7.86131 6.02519 10.0087 6.02519C12.156 6.02519 14.2232 6.841 15.7918 8.3075C15.8857 8.39923 15.9971 8.47126 16.1192 8.51939C16.2414 8.56751 16.372 8.59076 16.5033 8.58776C16.6345 8.58477 16.7639 8.55559 16.8837 8.50194C17.0036 8.4483 17.1115 8.37126 17.2012 8.27535C17.2909 8.17944 17.3605 8.06658 17.406 7.9434C17.4515 7.82022 17.472 7.6892 17.4662 7.55802C17.4604 7.42683 17.4284 7.29813 17.3722 7.17945C17.316 7.06077 17.2367 6.95451 17.1389 6.86689C15.2071 5.05531 12.657 4.04873 10.0087 4.05238Z" fill="#00B33D" />
                                            <path d="M19.6716 3.68418C17.0122 1.31137 13.5727 0 10.0087 0C6.44459 0 3.00511 1.31137 0.34573 3.68418C0.245783 3.76956 0.163907 3.87405 0.104915 3.99152C0.0459228 4.10898 0.0110039 4.23706 0.00220997 4.36821C-0.006584 4.49936 0.0109244 4.63095 0.0537064 4.75524C0.0964885 4.87953 0.163681 4.99402 0.251336 5.09197C0.338991 5.18993 0.44534 5.26937 0.564134 5.32564C0.682929 5.38191 0.811771 5.41387 0.943092 5.41964C1.07441 5.42542 1.20556 5.40488 1.32884 5.35925C1.45211 5.31361 1.56502 5.2438 1.66092 5.15391C3.95858 3.10474 6.92972 1.9723 10.0084 1.9723C13.0871 1.9723 16.0583 3.10474 18.3559 5.15391C18.5509 5.32832 18.8073 5.41811 19.0685 5.40353C19.3297 5.38895 19.5744 5.2712 19.7488 5.07618C19.9233 4.88115 20.013 4.62484 19.9985 4.36361C19.9839 4.10238 19.8661 3.85765 19.6711 3.68324L19.6716 3.68418Z" fill="#E1DCD2" />
                                            <path d="M10.0087 15.4058C10.8388 15.4058 11.5117 14.7328 11.5117 13.9027C11.5117 13.0726 10.8388 12.3996 10.0087 12.3996C9.17854 12.3996 8.50558 13.0726 8.50558 13.9027C8.50558 14.7328 9.17854 15.4058 10.0087 15.4058Z" fill="#00B33D" />
                                        </g>
                                    </svg></span>
                                <i className="fa fa-ellipsis-v" aria-hidden="true" style={{ marginLeft: '15px' }}></i>
                            </div>
                        </Card> */}
                        {/* <div class="card" style={{ width: '27.5rem', marginTop: '-20px' }}>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item"><MdAccessTime />{" "}Sensor Stalled (12/APR/2020)</li>
                                <li class="list-group-item" ><span style={{ fontSize: '12px', color: '#A8A8A8' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span></li>
                                <button type="button" class="btn btn-primary btn-sm" style={{ marginLeft: '' }}><GrVmMaintenance /> {" "}Assign for maintainence</button>


                            </ul>
                        </div> */}

                    </div>

                </div>



                <Card className='p-5'>
                    <h5>Devices</h5>
                    <Table striped hover size="sm">
                        <thead>
                            <tr>
                                <th>Sl no.</th>
                                <th>Name</th>
                                <th>Imei</th>
                                <th>Expiry Date</th>
                                <th>Status</th>
                                <th>Sensors</th>
                                <th>Street View</th>
                                <th>Maps</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dashboardData?.devices?.slice(0, 10).map((device, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{device.name}</td>
                                    <td>{device.imei}</td>
                                    <td>{device.expire_dt}</td>
                                    <td>{device.status}</td>
                                    <td>{device.tSensors}</td>
                                    <td>
                                        <a href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${device.lat},${device.lng}`} target="_blank">Street View</a>
                                    </td>
                                    <td>
                                        <a href={`https://www.google.com/maps?q=${device.lat},${device.lng}`} target="_blank">Click here...</a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Link to="/speedotrack/device">More devices...</Link>
                </Card>



            </div>


        </div>
    )
}

export default UserDashboard;
