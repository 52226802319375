import React, { useState, useEffect } from 'react';
import { Modal, Form, Spinner } from 'react-bootstrap';
import Multiselect from 'multiselect-react-dropdown';

//importing of the icons from react icons
import { FaCarAlt } from "react-icons/fa";
import { FaPerson } from "react-icons/fa6";
import { RiMotorbikeFill } from "react-icons/ri";
import { FaTruck } from "react-icons/fa";
import { FaBus } from "react-icons/fa";
import { GiPowerGenerator } from "react-icons/gi";
import { MdElectricRickshaw } from "react-icons/md";
import { FaShip } from "react-icons/fa";
import { MdOutlineElectricScooter } from "react-icons/md";
import { GiDeliveryDrone } from "react-icons/gi";
import { FaTruckPickup } from "react-icons/fa6";
import { GiBulldozer } from "react-icons/gi";
import { templates } from 'choices.js';



const AddDeviceModal = (props) => {
    const [coinsList, setCoinsList] = useState([]);
    const [deviceModelList, setDeviceModelList] = useState([]);
    const [userList, setUserList] = useState([])

    //variable for holdinng input value for Add Device
    const [addDeviceMissingItem, setAddDeviceMissingItem] = useState([])
    const [addDeviceLoading, setAddDeviceLoading] = useState(false)
    const [addDeviceName, setAddDeviceName] = useState('');
    const [addDeviceImei, setAddDeviceImei] = useState('');
    const [addDeviceSimNumber, setAddDeviceSimNumber] = useState('');
    const [addDevicePlateNumber, setAddDevicePlateNumber] = useState('');
    const [addDeviceModel, setAddDeviceModel] = useState([]);
    const [addDeviceVin, setAddDeviceVin] = useState('');
    const [addDeviceVehicleType, setAddDeviceVehicleType] = useState('car');
    const [addDeviceActive, setAddDeviceActive] = useState(true);
    const [addDeviceCoins, setAddDeviceCoins] = useState('1');
    const [addDeviceUserList, setAddDeviceUserList] = useState([]);
    const [userAvailableCoins, setUserAvailableCoins] = useState('');


    // const createDevice = async () => {
    //     setAddDeviceMissingItem([])
    //     if (addDeviceName === null || addDeviceName === '') {
    //         setAddDeviceMissingItem(prevState => [...prevState, 'name']);
    //     }
    //     if (addDeviceImei === null || addDeviceImei === '') {
    //         setAddDeviceMissingItem(prevState => [...prevState, 'imei']);
    //     }
    //     if (addDeviceSimNumber === null || addDeviceSimNumber === '') {
    //         setAddDeviceMissingItem(prevState => [...prevState, 'sim_number'])
    //     }
    //     if (addDeviceModel === null || addDeviceModel === '' || addDeviceModel.length === 0) {
    //         setAddDeviceMissingItem(prevState => [...prevState, 'device_model'])
    //     }
    //     if (addDeviceUserList === null || addDeviceUserList === '' || addDeviceUserList.length === 0) {
    //         setAddDeviceMissingItem(prevState => [...prevState, 'user'])
    //     }
    //     if(addDeviceMissingItem.length === 0){
    //         try {
    //             setAddDeviceLoading(true)
    //             var myHeaders = new Headers();
    //             myHeaders.append("Content-Type", "application/json");

    //             var raw = JSON.stringify({
    //                 "name": addDeviceName,
    //                 "imei": addDeviceImei,
    //                 "sim_number": addDeviceSimNumber,
    //                 "plate_number": addDevicePlateNumber,
    //                 "active": true,
    //                 "coins": addDeviceCoins,
    //                 "vin": addDeviceVin,
    //                 "model": addDeviceModel[0],
    //                 "users": addDeviceUserList,
    //                 "vehicle_type": addDeviceVehicleType
    //             });

    //             var requestOptions = {
    //                 method: 'POST',
    //                 headers: myHeaders,
    //                 body: raw,
    //                 redirect: 'follow'
    //             };
    //             const response = await fetch("https://gps.speedotrack.com/api/cpanel/device", requestOptions)
    //             const result = await response.json()
    //             alert(result)
    //             alert('vehicle created sucessfully')
    //             props.setToggleChange(!props.toggleChange)
    //             setAddDeviceLoading(false)
    //         } catch (error) {
    //             setAddDeviceLoading(false)
    //             console.log(error);
    //             alert(error.message)
    //         }
    //     }
    // }


    const createDevice = async () => {
        const missingItems = [];

        const validateField = (field, name) => {
            if (field === null || field === '' || (Array.isArray(field) && field.length === 0)) {
                missingItems.push(name);
            }
        };

        validateField(addDeviceName, 'name');
        validateField(addDeviceImei, 'imei');
        validateField(addDeviceSimNumber, 'sim_number');
        validateField(addDeviceModel, 'device_model');
        validateField(addDeviceUserList, 'user');

        if (missingItems.length === 0) {
            try {
                var myHeaders = new Headers();
                myHeaders.append("Authorization", localStorage.getItem('token'));
                myHeaders.append('Content-Type', 'application/json')

                setAddDeviceLoading(true);
                const requestBody = {
                    name: addDeviceName,
                    imei: addDeviceImei,
                    sim_number: addDeviceSimNumber,
                    plate_number: addDevicePlateNumber,
                    active: true,
                    coins: addDeviceCoins,
                    vin: addDeviceVin,
                    model: addDeviceModel[0],
                    users: addDeviceUserList,
                    vehicle_type: addDeviceVehicleType
                };

                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(requestBody),
                    redirect: 'follow'
                };

                const response = await fetch("https://gps.speedotrack.com/api/cpanel/device", requestOptions);
                const result = await response.json();
                console.log(result);
                props.setToggleChange(!props.toggleChange);
                props.onHide();
            } catch (error) {
                console.log(error);
                alert(error.message);
            } finally {
                setAddDeviceLoading(false);
            }
        } else {
            setAddDeviceMissingItem(missingItems);
        }
    };


    const funcFetchRequiredData = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", localStorage.getItem('token'));

            var requestOptions = {
                method: 'GET',
                redirect: 'follow',
                headers: myHeaders
            };

            const response = await fetch("https://gps.speedotrack.com/api/cpanel/device/required-data", requestOptions)
            const result = await response.json()
            setCoinsList(result.coins);
            setDeviceModelList(result.models)
            setUserList(result.users)
            setUserAvailableCoins(result.availCoins)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        funcFetchRequiredData();
    }, [])

    return (
        <Modal
            {...props}
            animation={false}
            backdrop="static"
            style={{ marginLeft: '-5%' }}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '14px' }}>Add Device</Modal.Title>
            </Modal.Header>
            <p style={{ fontSize: '14px', marginLeft: '2%' }}><img src={require('../images/coin.png')} style={{ width: '7%' }} />Coins Balance: {userAvailableCoins}</p>

            <Modal.Body style={{ marginTop: '-14px', paddingBottom: '40px' }}>
                <form lg="12">
                    <div className="form-row d-flex">
                        <div className="form-group col-md-6">
                            <label htmlFor="inputEmail4" style={{ fontSize: '13px' }}>Name</label>
                            <input type="email" className="form-control" id="inputEmail4"
                                maxLength={20}
                                placeholder="enter device name" style={{ borderRadius: '5px' }}
                                value={addDeviceName}
                                onChange={(e) => { setAddDeviceName(e.target.value) }} />

                            {addDeviceMissingItem?.includes('name') ? (
                                <p className='text-danger mx-2'>name is required</p>
                            ) : <p className='text-danger mx-2'>*</p>}

                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="inputPassword4" style={{ fontSize: '13px', marginLeft: '10px' }}>Imei</label>
                            <input type="number" className="form-control" id="inputPassword4"
                                placeholder="enter device imei here" style={{ borderRadius: '5px', marginLeft: '10px', marginRight: '5px' }}
                                maxLength={15}
                                value={addDeviceImei}
                                onChange={(e) => { setAddDeviceImei(e.target.value) }}
                            />
                            {addDeviceMissingItem?.includes('imei') ? (
                                <p className='text-danger mx-2'>imei is required</p>
                            ) : <p className='text-danger mx-2'>*</p>}
                        </div>
                    </div>
                    <div className="form-row d-flex">
                        <div className="form-group col-md-6">
                            <label htmlFor="inputEmail4" style={{ fontSize: '13px' }}> Sim Number</label>
                            <input type="number" className="form-control" id="inputEmail4" placeholder="enter device sim number"
                                style={{ borderRadius: '5px' }}
                                maxLength={15}
                                value={addDeviceSimNumber}
                                onChange={(e) => { setAddDeviceSimNumber(e.target.value) }}
                            />
                            {addDeviceMissingItem?.includes('sim_number') ? (
                                <p className='text-danger mx-2'>sim number is required</p>
                            ) : <p className='text-danger mx-2'>*</p>}
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="inputPassword4" style={{ fontSize: '13px', marginLeft: '10px' }}> Plate Number</label>
                            <input type="text" className="form-control" id="inputPassword4"
                                placeholder="enter device plate number" style={{ borderRadius: '5px', marginLeft: '10px' }}
                                maxLength={15}
                                value={addDevicePlateNumber}
                                onChange={(e) => setAddDevicePlateNumber(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-row d-flex">
                        <div className="form-group col-md-6">
                            <label htmlFor="inputPassword4" style={{ fontSize: '13px', marginLeft: '10px' }}>Expiry Period</label>
                            <Form.Select aria-label="Default select example" onChange={(e) => setAddDeviceCoins((e.target.value).toString())} checked={coinsList[2]}>
                                {coinsList?.map((coin, index) => (
                                    <option value={coin?.coins} key={index} >{coin.time} / {coin.coins} {coin.coins === 1 ? 'coin' : 'coins'}</option>
                                ))}
                            </Form.Select>
                        </div>
                        <div className="form-group col-md-6 mx-2">
                            <label htmlFor="inputPassword4" style={{ fontSize: '13px', marginLeft: '10px' }}>Device Model</label>
                            <Multiselect
                                displayValue="key"
                                selectionLimit={1}
                                isObject={false}
                                onKeyPressFn={function noRefCheck() { }}
                                onRemove={(e) => { setAddDeviceModel(e) }}
                                onSearch={function noRefCheck() { }}
                                onSelect={(e) => { setAddDeviceModel(e) }}
                                options={[...deviceModelList]}
                                placeholder='select a device model'
                            />
                            {addDeviceMissingItem?.includes('device_model') ? (
                                <p className='text-danger mx-2'>device model required</p>
                            ) : <p className='text-danger mx-2'>*</p>}
                        </div>
                    </div>
                    <div className="form-row d-flex">
                        <div className="form-group col-md-6">
                            <label htmlFor="inputEmail4" style={{ fontSize: '13px' }}>Vin</label>
                            <input type="email" className="form-control" id="inputEmail4"
                                placeholder="enter device identification number" style={{ borderRadius: '5px' }}
                                maxLength={15}
                                value={addDeviceVin}
                                onChange={(e) => setAddDeviceVin(e.target.value)}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="inputPassword4" style={{ fontSize: '13px', marginLeft: '10px' }}>Vehicle Types</label>
                            <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ marginLeft: '10px' }} >
                                <div className="btn-group mr-2" role="group" aria-label="First group" style={{ marginTop: '5px' }}>
                                    <span type="" className="ml-2"
                                        style={{ marginLeft: '3px', fontSize: '25px', cursor: 'pointer', color: addDeviceVehicleType === 'car' ? 'green' : '' }}
                                        onClick={() => setAddDeviceVehicleType('car')}
                                    ><FaCarAlt />
                                    </span>
                                    <span type="" className="ml-2"
                                        style={{ marginLeft: '3px', fontSize: '25px', cursor: 'pointer', color: addDeviceVehicleType === 'person' ? 'green' : '' }}
                                        onClick={() => setAddDeviceVehicleType('person')}
                                    ><FaPerson />

                                    </span>
                                    <span type="" className="ml-2"
                                        style={{ marginLeft: '6px', fontSize: '25px', cursor: 'pointer', color: addDeviceVehicleType === 'bike' ? 'green' : '' }}
                                        onClick={() => setAddDeviceVehicleType('bike')}
                                    ><RiMotorbikeFill />
                                    </span>
                                    <span type="" className="ml-2"
                                        style={{ marginLeft: '6px', fontSize: '25px', cursor: 'pointer', color: addDeviceVehicleType === 'truck' ? 'green' : '' }}
                                        onClick={() => setAddDeviceVehicleType('truck')}
                                    ><FaTruck />

                                    </span>
                                    <span type="" className="ml-2"
                                        style={{ marginLeft: '6px', fontSize: '25px', cursor: 'pointer', color: addDeviceVehicleType === 'bus' ? 'green' : '' }}
                                        onClick={() => setAddDeviceVehicleType('bus')}
                                    ><FaBus />
                                    </span>
                                    <span type="" className="ml-2"
                                        style={{ marginLeft: '6px', fontSize: '25px', cursor: 'pointer', color: addDeviceVehicleType === 'auto' ? 'green' : '' }}
                                        onClick={() => setAddDeviceVehicleType('auto')}
                                    ><MdElectricRickshaw />
                                    </span>
                                    <span type="" className="ml-2"
                                        style={{ marginLeft: '6px', fontSize: '25px', cursor: 'pointer', color: addDeviceVehicleType === 'ship' ? 'green' : '' }}
                                        onClick={() => setAddDeviceVehicleType('ship')}
                                    ><FaShip />
                                    </span>
                                    <span type="" className="ml-2"
                                        style={{ marginLeft: '6px', fontSize: '25px', cursor: 'pointer', color: addDeviceVehicleType === 'drone' ? 'green' : '' }}
                                        onClick={() => setAddDeviceVehicleType('drone')}
                                    ><GiDeliveryDrone />
                                    </span>
                                    <span type="" className="ml-2"
                                        style={{ marginLeft: '6px', fontSize: '25px', cursor: 'pointer', color: addDeviceVehicleType === 'jcb' ? 'green' : '' }}
                                        onClick={() => setAddDeviceVehicleType('jcb')}
                                    ><GiBulldozer />
                                    </span>
                                    <span type="" className="ml-2"
                                        style={{ marginLeft: '6px', fontSize: '25px', cursor: 'pointer', color: addDeviceVehicleType === 'scooter' ? 'green' : '' }}
                                        onClick={() => setAddDeviceVehicleType('scooter')}
                                    ><MdOutlineElectricScooter />
                                    </span>
                                    <span type="" className="ml-2"
                                        style={{ marginLeft: '6px', fontSize: '25px', cursor: 'pointer', color: addDeviceVehicleType === 'pickup' ? 'green' : '' }}
                                        onClick={() => setAddDeviceVehicleType('pickup')}
                                    ><FaTruckPickup />
                                    </span>
                                    <span type="" className="ml-2"
                                        style={{ marginLeft: '6px', fontSize: '25px', cursor: 'pointer', color: addDeviceVehicleType === 'generator' ? 'green' : '' }}
                                        onClick={() => setAddDeviceVehicleType('generator')}
                                    ><GiPowerGenerator />
                                    </span>
                                    <span type="" className="ml-2" style={{ marginLeft: '6px', fontSize: '25px', cursor: 'pointer' }}><i className='fas fa-truck-pickup'></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="form-row d-flex">
              <div className="form-group col-md-6">
                <label htmlFor="inputPassword4" style={{ fontSize: '13px', marginLeft: '10px' }}>Coins</label>
                <Form.Select aria-label="Default select example" onChange={(e) => setAddDeviceCoins((e.target.value).toString())}>
                  { coinsList?.map((coin, index) => (
                      <option value={coin?.coins} key={index} >{coin.time}</option>
                    ))}
                </Form.Select>
              </div>
            </div> */}
                    <Multiselect
                        isObject={false}
                        onKeyPressFn={function noRefCheck() { }}
                        closeOnSelect={true}
                        onRemove={(e) => { setAddDeviceUserList(e) }}
                        onSearch={function noRefCheck() { }}
                        onSelect={(e) => { setAddDeviceUserList(e) }}
                        onChange={(e) => console.log(e.target.value)}
                        options={userList}
                        placeholder='select user'
                    />
                    {addDeviceMissingItem?.includes('user') ? (
                        <p className='text-danger mx-2'>select atleast one user</p>
                    ) : <p className='text-danger mx-2'>*</p>}
                </form>

                <button className="btn btn-primary"
                    style={{ width: '100%', borderRadius: '5px', marginTop: '20px', fontSize: '13px' }}
                    onClick={createDevice}
                >
                    {
                        addDeviceLoading ? (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        ) : null
                    }
                    Add new Device »</button>
            </Modal.Body>

        </Modal>
    )
}

export default AddDeviceModal
