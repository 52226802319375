import React, { useEffect, useState, useRef, useMemo } from "react";
import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { BsSendFill } from "react-icons/bs";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import { BsGeoAltFill } from "react-icons/bs";
import { BsFillPinMapFill } from "react-icons/bs";
import { GiBrokenHeartZone } from "react-icons/gi";
import { PiPathBold } from "react-icons/pi";
import { AiOutlineCluster } from "react-icons/ai";
import { FaTrafficLight } from "react-icons/fa";
import { IoExpandOutline } from "react-icons/io5";
import { FaEye } from "react-icons/fa6";
import { GrHide } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { Card, Form } from 'react-bootstrap';
import MonitorMap from '../Monitor/MonitorMap';
import ReactDOM from 'react-dom/client';
import DemoCard from "./DemoCard";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Nav } from 'react-bootstrap';
import { SlRefresh } from "react-icons/sl";
import { FaRegEye } from "react-icons/fa";
import { BsPlus } from "react-icons/bs";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { GiPositionMarker } from "react-icons/gi";
import { LiaPencilAltSolid } from "react-icons/lia";
import { GoPlus } from "react-icons/go";
import { IoIosClose } from "react-icons/io";
import { IoStopSharp } from "react-icons/io5";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { SlUserFollow } from "react-icons/sl";
import { IoIosSend } from "react-icons/io";
import { BiStreetView } from "react-icons/bi";
import { BiSolidEdit } from "react-icons/bi";
import MonitorGraph from "./MonitorGraph";
import MonitorMassage from "./MonitorMassage";
import TestCard from './TestCard';
import { FcClock } from "react-icons/fc";
import { PiWifiHighThin } from "react-icons/pi";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { PiEngine } from "react-icons/pi";
//history redux
import { setHistoryRoute } from "../../../../store/history/historySlice";
import { PiEngineFill } from "react-icons/pi";

import { CiLocationArrow1 } from "react-icons/ci";
import { CiDeliveryTruck } from "react-icons/ci";
import { CiWifiOff } from "react-icons/ci";
import { CiBellOff } from "react-icons/ci";
import { CiAlarmOn } from "react-icons/ci";
import { CiNoWaitingSign } from "react-icons/ci";
import { BiDotsVertical } from "react-icons/bi";

//importing of the Actions
import { setTrackingData } from "../../../../store/monitor/trackingSlice";
import { setGeneralData } from "../../../../store/monitor/trackingGeneralSlice";
import { RxCountdownTimer } from "react-icons/rx";



//importing of the components
import MonitorBottom from "./MonitorBottom";
import MonitorDataBar from "./MonitorDataBar";
import { FcSearch } from "react-icons/fc";
import { LuPlus } from "react-icons/lu";
import Multiselect from "multiselect-react-dropdown";



//By Default imports of the Theme
import "./style.css";
import { Row, Col, Dropdown, Table, Button, Modal, ButtonGroup } from 'react-bootstrap';
import Loader from "../../../../components/Loader";
import FullScreenLoader from "../speedo-asset/FullScreenLoader";



//Starting of the Functional Component from here.
export default function Dashboard() {


  //declaration of the dispatch method.
  const dispatch = useDispatch();
  const vehicleData = useSelector(state => state.tracking.monitorData)
  // console.log(vehicleData)

  const [loginData, setLoginData] = useState(JSON.parse(sessionStorage.getItem('login-data')))
  const mapAccess = loginData?.privileges?.map;
  // console.log('map data', mapAccess)

  const mapRef = useRef(null);
  //Declaration of the State Variables
  const [isOpen, setIsOpen] = useState(false);
  const [markers, setMarkers] = useState();
  const [vehicles, setVehicles] = useState([]);
  const [tableData, setTableData] = useState();
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [hideVehicles, setHideVehicles] = useState(false);
  const [displayNone, setDisplayNone] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState([])
  const [visibleMarkers, setVisibleMarkers] = useState([]);
  const [displayImeiArray, setDisplayImeiArray] = useState([]);
  const [vehiclePrevious, setVehiclePrevious] = useState([])
  const [devices, setDevices] = useState([]);
  const [infoWindowData, setInfoWindowData] = useState();
  const [showAllVehicle, setShowAllVehicle] = useState(false); // state for make vehicles visible on map.
  const [selectAllVehicle, setSelectAllVehicle] = useState(false); // state for selecting all the vehicles
  const [showHistoryBar, setShowHistoryBar] = useState(false); // state for making history modal, visible and invisible
  const [showEditModal, setShowEditModal] = useState(false); // state for making the edit modal, visible and invisible
  const [showFollowModal, setShowFollowModal] = useState(false); //state for making the follow modal, visible and invisible.
  const [vehicleForHistory, setVehicleForHistory] = useState({}); // state for setting the imei prop to sent to history modal.
  const [imeiForFollow, setImeiForFollow] = useState(null); //state holding the imei value of the vehicle to show in the follow bar.
  const [vehicleMarkerClick, setVehicleMarkerClick] = useState(false)
  const [showTail, setShowTail] = useState(true)
  const [showZone, setShowZone] = useState(false)
  const [showRoute, setShowRoute] = useState(false)
  //state for toolBar
  const [showToolTip, setShowToolTip] = useState(true)
  const [showMarker, setShowMarker] = useState(true)
  const [showCluster, setShowCluster] = useState(false)
  const [showStreetView, setShowStreetView] = useState(false)
  const [showDraw, setShowDraw] = useState(false)
  const [selectedVehicle, setSelectedVehicle] = useState(''); // Initialize with an empty string or any default value
  const [isActive, setIsActive] = useState(false);
  const [showMonitorDataBar, setShowMonitorDataBar] = useState(false)
  const [imeiGeneralInfo, setImeiGeneralInfo] = useState('')

  const [addZoneCheck, setAddZoneCheck] = useState(false)
  const [addRouteCheck, setAddRouteCheck] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState('MARKER')

  //full screen
  const [isCardVisible, setIsCardVisible] = useState(true); // Initialize the card as visible
  const [showBottomCard, setShowBottomCard] = useState(true);
  //load device parameters
  //search
  const [loadDeviceSearch, setLoadDeviceSearch] = useState('')
  //filter
  const [statusFilter, setStatusFilter] = useState('')
  const [connFilter, setConnFilter] = useState('online')
  //sort
  const [sortParam, setSortParam] = useState('imei')



  //places
  const [placesRoute, setPlacesRoute] = useState('')
  //events
  const [eventList, setEventList] = useState([])
  const [noOfEvents, setNoOfEvents] = useState('')
  //select
  const [selectedVehicleImei, setSelectedVehicleImei] = useState('')
  //detailed info
  const [showDetailed, setShowDetailed] = useState(false)
  //zones
  //fetch all zones
  const [zoneList, setZoneList] = useState([])
  // fetch a particular zone
  const [zoneMap, setZoneMap] = useState({})
  //add zone
  const [showAddZone, setShowAddZone] = useState(false)
  const [zoneName, setZoneName] = useState('')
  const [zoneColor, setZoneColor] = useState('#FF0000')
  const [zoneVisible, setZoneVisible] = useState(true)
  const [zoneNameVisible, setZoneNameVisible] = useState(true)
  const [zoneArea, setZoneArea] = useState(45.5)
  const [zoneVertices, setZoneVertices] = useState([])
  //add zone checks
  const [zoneNameMissing, setZoneNameMissing] = useState(false)
  const [zoneCoordsMissing, setZoneCoordsMissing] = useState(false)
  //markers
  //add marker
  const [markerGroup, setMarkerGroup] = useState('6550dac0b2429c7556361e0a')
  const [markerName, setMarkerName] = useState('')
  const [markerDesc, setMarkerDesc] = useState('')
  const [markerIcon, setMarkerIcon] = useState('')
  const [markerVisible, setMarkerVisible] = useState(true)
  const [markerRadius, setMarkerRadius] = useState('')
  const [markerLat, setMarkerLat] = useState('')
  const [markerLng, setMarkerLng] = useState('')
  //routes
  //fetch all routes
  const [routeList, setRouteList] = useState([])
  //add route
  const [routeName, setRouteName] = useState('')
  const [routeColor, setRouteColor] = useState('')
  const [routeVisible, setRouteVisible] = useState('')
  const [routeNameVisible, setRouteNameVisible] = useState('')
  const [routeDeviation, setRouteDeviation] = useState('')
  const [routePoints, setRoutePoints] = useState([])
  const [showMarkers, setShowMarkers] = useState(false);
  const [showRoutes, setShowRoutes] = useState(false);
  const [showZones, setShowZones] = useState(false);

  //counts
  const [totalDevices, setTotalDevices] = useState('')
  const [movingDevices, setMovingDevices] = useState('')
  const [stoppedDevices, setStoppedDevices] = useState('')
  const [idleDevices, setIdleDevices] = useState('')
  const [offlineDevices, setOfflineDevices] = useState('')
  const [expiredDevices, setExpiredDevices] = useState('')
  const [inactiveDevices, setInactiveDevices] = useState('')


  //history
  const [historyDeviceList, setHistoryDeviceList] = useState([])
  const [isLoadingHistory, setIsLoadingHistory] = useState(false)
  const [route, setRoute] = useState([])
  const [isHistory, setIsHistory] = useState(false)


  useEffect(() => {
    const deviceList = JSON.parse(localStorage.getItem('loginInfo')).devices
    setHistoryDeviceList(deviceList)
  }, [])






  useEffect(() => {
    console.log(zoneVertices)
  }, [zoneVertices])

  const handleButtonClick = () => {
    setIsActive(!isActive);
  };

  const [center, setCenter] = useState({
    lat: 23.3266350,
    lng: 85.3122783,
  })

  // making the API call for the first time.
  useEffect(() => {
    fetchData();
  }, [loadDeviceSearch, selectedVehicleImei, statusFilter]);

  //calling setInterval method, to make API call in every 10 seconds.
  useEffect(() => {
    const intervalId = setInterval(fetchData, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, [loadDeviceSearch, selectedVehicleImei, statusFilter, connFilter, sortParam]);




  const fetchData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", localStorage.getItem('token'));

      var raw = JSON.stringify({
        "filter": {
          "conn": connFilter,
          "status": statusFilter,
          // "blocked": false
        },
        imei: selectedVehicleImei
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/tracking/load-device-data?page=1&rows=30&sort=${sortParam}&search-field=all&search-value=${loadDeviceSearch}`, requestOptions)
      const result = await response.json()
      setTotalDevices(result?.total)
      setMovingDevices(result?.moving)
      setStoppedDevices(result?.stopped)
      setIdleDevices(result?.idle)
      setOfflineDevices(result?.offline)
      setExpiredDevices(result?.expired)
      setInactiveDevices(result?.inactive)


      dispatch(setTrackingData(result.result))
      console.log(result)
      // setShowMonitorDataBar(true)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchVehicleInfo = async (imei) => {



    setImeiGeneralInfo(imei)



    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      myHeaders.append("Authorization", localStorage.getItem('token'));

      var urlencoded = new URLSearchParams();
      urlencoded.append("devices_imei", imei);
      urlencoded.append("data_items", "route_start,route_end,route_length,move_duration,stop_duration,stop_count,top_speed,avg_speed,overspeed_count,fuel_consumption,avg_fuel_consumption,fuel_cost,engine_work,engine_idle,odometer,engine_hours,driver,trailer");
      urlencoded.append("date_time_from", "2023-06-07 00:00:00");
      urlencoded.append("date_time_to", "2023-12-24 00:00:00");
      urlencoded.append("speed_limit", "30");
      urlencoded.append("stop_duration", "1");

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      const response = await fetch("https://gps.speedotrack.com/api/reports/general-info", requestOptions)
      const result = await response.json();
      dispatch(setGeneralData(result[0]));
      // console.log('general info data is ', result)
      setShowMonitorDataBar(true)

    } catch (error) {
      console.log(error)
    }
  }

  useMemo(() => {
    if (!showMonitorDataBar) {
      setImeiGeneralInfo('')
    }
  }, [showMonitorDataBar])

  //handle select all function, to select and unselect vehicles - for now not that functional


  const setFullScreen = () => { setIsCardVisible(!isCardVisible); setShowDetailed(false); setShowBottomCard(!showBottomCard) }

  const funcFetchEvents = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem('token'));

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      const response = await fetch("https://gps.speedotrack.com/api/user/event", requestOptions)
      const result = await response.json()
      setEventList(result.events)
      setNoOfEvents(result.total)

    } catch (error) {
      console.log(error)
    }
  }

  //zones
  //add zone
  const funcAddZone = async () => {

    if (!zoneName || zoneName === '') {
      setZoneNameMissing(true)
    } else {
      setZoneNameMissing(false)
    }

    if (zoneVertices.length === 0) {
      setZoneCoordsMissing(true)
    } else {
      setZoneCoordsMissing(false)
    }

    if (!zoneNameMissing && !zoneCoordsMissing) {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", localStorage.getItem('token'));


        var raw = JSON.stringify({
          "name": zoneName,
          "color": zoneColor,
          "visible": zoneVisible,
          "nameVisible": zoneNameVisible,
          "area": zoneArea,
          "vertices": zoneVertices
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        const response = await fetch("https://gps.speedotrack.com/api/user/zone", requestOptions)
        const result = await response.json()
        console.log(result)
        if (response.ok) {
          funcFetchUserZones()
          setPlacesRoute('');
          setAddZoneCheck(false);
          setZoneName('')
          setZoneVertices([])
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
  //fetch all zones of a user
  const funcFetchUserZones = async () => {

    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem('token'));

      var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
      };

      const response = await fetch("https://gps.speedotrack.com/api/user/zone", requestOptions)
      const result = await response.json();
      setZoneList(result.result)
    } catch (error) {
      console.log(error)
    }
  }
  //fetch a particular selected zone
  const funcFetchZone = async (zoneId) => {

    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem('token'));


      var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
      };

      const response = await fetch(`https://gps.speedotrack.com/api/user/zone/${zoneId}`, requestOptions)
      const result = await response.json();
      setZoneMap(result)
      console.log(result)
    } catch (error) {
      console.log(error)
    }
  }
  //delete zone
  const funcDeleteZone = async (zoneId) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem('token'));

      var requestOptions = {
        method: 'DELETE',
        redirect: 'follow',
        headers: myHeaders
      };

      const response = await fetch(`https://gps.speedotrack.com/api/user/zone/${zoneId}`, requestOptions)
      const result = await response.json()
      console.log(result)
      alert('zone deleted successfully !')
      funcFetchUserZones()
    } catch (error) {
      console.log(error)
      alert('unable to delete zone.')
    }
  }
  //markers
  //add marker
  const funcAddMarker = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", localStorage.getItem('token'));

      var raw = JSON.stringify({
        "group": markerGroup,
        "name": markerName,
        "desc": markerDesc,
        "icon": markerIcon,
        "visible": markerVisible,
        "lat": markerLat,
        "lng": markerLng,
        "radius": markerRadius
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch("https://gps.speedotrack.com/api/user/marker", requestOptions)
      const result = await response.json()
      console.log(result)

    } catch (error) {
      console.log(error)
    }
  }
  //routes
  //add route
  const funcAddRoute = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", localStorage.getItem('token'));

      var raw = JSON.stringify({
        "name": routeName,
        "color": routeColor,
        "visible": routeVisible,
        "nameVisible": routeNameVisible,
        "deviation": routeDeviation,
        "points": routePoints
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch("https://gps.speedotrack.com/api/user/route", requestOptions)
      const result = await response.json()
      console.log(result)
    } catch (error) {
      console.log(error)
    }
  }
  //fetch all routes of a user
  const funcFetchUserRoutes = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem('token'));

      var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
      };
      const response = await fetch("https://gps.speedotrack.com/api/user/route", requestOptions)
      const result = await response.json()
      setRouteList(result.result)
      console.log(result)
    } catch (error) {
      console.log(error)
    }
  }
  //delete route
  const funcDeleteRoute = async (routeId) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem('token'));

      var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/user/route/${routeId}`, requestOptions)
      const result = await response.json();
      funcFetchUserRoutes()
      console.log(result)
    } catch (error) {
      console.log(error)
    }
  }


  //history
  const fetchHistory = async (deviceImei, dateFrom, endDate, stopDuration = '10') => {
    try {
      setIsLoadingHistory(true)
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem('token'));

      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "cmd": "load_route_data",
        "imei": deviceImei,
        "dtf": new Date("1-12-2023"), //dateFrom,
        "dtt": endDate,
        "min_stop_duration": stopDuration
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch("https://gps.speedotrack.com/api/device-history", requestOptions)
      const result = await response.json();
      console.log(result)
      setRoute(result?.route)
      const routeData = result?.route
      dispatch(setHistoryRoute(routeData))
      setIsHistory(true)
      setShowMonitorDataBar(false)
      setIsLoadingHistory(false)

    } catch (error) {
      console.log(error)
    }
  }






  const handleCancel = () => {
    setPlacesRoute(''); // Update the route state to hide the card
  };


  const handleMarkersClick = () => {
    setSelectedMenu('MARKER')
    setShowMarkers(!showMarkers);
    setShowRoutes(false); // Close other menu items when this is clicked
    setShowZones(false);
  };

  const handleRoutesClick = () => {
    setSelectedMenu('ROUTE')
    setShowRoutes(!showRoutes);
    setShowMarkers(false); // Close other menu items when this is clicked
    setShowZones(false);
  };

  const handleZonesClick = () => {
    setSelectedMenu('ZONE')
    setShowZones(true);
    setShowMarkers(false); // Close other menu items when this is clicked
    setShowRoutes(false);
  };




  //The code return statement, starts from here....
  return (
    <div>
      <div style={{ marginTop: '-17px', height: '90vh', width: '92vw', display: 'flex' }}>

        {isLoadingHistory ? (
          <FullScreenLoader />
        ) : null}

        {isCardVisible && (
          <Card className={`cards`} style={{ marginLeft: '-30px', marginRight: '5px', minHeight: '99.7vh', width: '300px', borderRadius: '0px', overflowX: 'hidden', zIndex: 7, marginTop: '-14px' }}>
            <div style={{ width: '20vw', padding: '6px', marginLeft: '9px', marginBottom: '-5px', borderRadius: '5px', marginTop: '5px' }}>

              <div className="bd-example">
                <Tab.Container defaultActiveKey="first">
                  <Nav>
                    <Nav variant="tabs" className="mb-3" id="nav-tab" role="tablist">
                      <Nav.Link eventKey="first" variant=" d-flex align-items-center" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true" style={{ borderRadius: '10px', minWidth: '50px', fontSize: '12px' }}>Object</Nav.Link>
                      <Nav.Link eventKey="second" variant="" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false"
                        style={{ borderRadius: '10px', minWidth: '55px', fontSize: '12px', }}
                        onClick={funcFetchEvents}
                      >Event</Nav.Link>
                      <Nav.Link eventKey="third" variant="" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false" style={{ borderRadius: '10px', minWidth: '55px', fontSize: '12px' }}>Places</Nav.Link>
                      <Nav.Link eventKey="fourth" variant="" id="nav-history-tab" data-bs-toggle="tab" data-bs-target="#nav-history" type="button" role="tab" aria-controls="nav-history" aria-selected="false" style={{ borderRadius: '10px', minWidth: '55px', fontSize: '12px', marginLeft: '-7px' }}>History</Nav.Link>
                    </Nav>
                  </Nav>


                  <Tab.Content>


                    {/* Object */}
                    <Tab.Pane className=" fade show" eventKey="first" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      <div className="tab-content" id="nav-tabContent" style={{ marginTop: '-15px', marginLeft: '-5px' }}>
                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                          <div className="card-body" style={{ marginLeft: '-10px' }}>
                            <div className="input-group rounded " >
                              <div class="form-group has-search d-flex" style={{ marginTop: '5px', minWidth: '190px', marginLeft: '-13px' }}>
                                <span class="fa fa-search form-control-feedback"></span>
                                <input type="text" class="form-control" placeholder="Search..." style={{ marginTop: '-15px' }} value={loadDeviceSearch} onChange={(e) => { setLoadDeviceSearch(e.target.value) }} />
                                <img src={require('./images/refresh-color.png')} style={{ width: '20px', marginLeft: '5px', marginTop: '-5px', cursor: 'pointer', height: '20px', border: '3 px solid #D5D5D5', borderRadius: '5px' }}
                                  onClick={fetchData}
                                />
                              </div>
                            </div>
                            <div className="icon-container pb-2 btn btn-soft-" style={{ marginTop: '-6px', border: '0px solid ', padding: '4px', backgroundColor: '', borderRadius: '4px', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', width: '272px', marginRight: '10px', marginLeft: '-14px' }}>
                              <div className="icon-text-pair" style={{}}>

                                <span style={{ padding: '5px', marginLeft: '2px', marginTop: '10px', fontSize: '11px', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', width: '42px' }}
                                  onClick={() => { setConnFilter('online'); setStatusFilter('') }}
                                >All({totalDevices})
                                </span>



                              </div>
                              <div className="icon-text-pair" style={{ marginLeft: '-7px' }}>
                                <span style={{ padding: '5px', marginLeft: '2px', marginTop: '10px', fontSize: '11px', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', width: '68px' }}
                                  onClick={() => { setConnFilter('online'); setStatusFilter('moving') }}
                                >Moving({movingDevices})</span>


                              </div>
                              <div className="icon-text-pair" style={{ marginLeft: '-7px' }}>
                                <span style={{ padding: '5px', marginLeft: '3px', marginTop: '10px', fontSize: '11px', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', width: '72px' }}
                                  onClick={() => { setConnFilter('online'); setStatusFilter('stopped'); console.log('clicked on moving.') }}
                                >Stopped({stoppedDevices})
                                </span>


                              </div>
                              <div className="icon-text-pair" style={{ marginLeft: '-7px' }}>
                                <span style={{ padding: '5px', marginLeft: '3px', marginTop: '10px', fontSize: '11px', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', width: '62px' }} >Idle(0)</span>


                              </div>


                            </div>

                            <div className="icon-container pb-2 btn btn-soft-" style={{ marginTop: '-6px', border: '0px solid ', padding: '4px', backgroundColor: '', borderRadius: '4px', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', width: '272px', marginRight: '10px', marginLeft: '-14px' }}>

                              <div className="icon-text-pair" style={{ marginLeft: '20px' }}>
                                <span style={{ padding: '5px', marginLeft: '2px', marginTop: '10px', fontSize: '11px', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', width: '62px' }}
                                  onClick={() => { setConnFilter('online'); setStatusFilter('moving') }}
                                >Inactive({inactiveDevices})</span>


                              </div>
                              <div className="icon-text-pair" style={{ marginLeft: '-7px' }}>
                                <span style={{ padding: '5px', marginLeft: '3px', marginTop: '10px', fontSize: '11px', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', width: '62px' }}
                                  onClick={() => { setConnFilter('online'); setStatusFilter('stopped'); console.log('clicked on moving.') }}
                                >Expired({expiredDevices})
                                </span>


                              </div>
                              <div className="icon-text-pair" style={{ marginLeft: '-7px' }}>
                                <span style={{ padding: '5px', marginLeft: '3px', marginTop: '10px', fontSize: '11px', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', width: '65px' }}
                                  onClick={() => { setConnFilter('online'); setStatusFilter('stopped'); console.log('clicked on moving.') }}
                                >Offline({offlineDevices})
                                </span>


                              </div>


                            </div>

                            <div class="row " style={{ marginTop: '15px', marginBottom: '10px', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', height: '40px', width: '274px', borderRadius: '5px' }}>
                              <div class="col-2">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ width: '13px', height: '13px', borderColor: 'black', marginTop: '12px' }} />
                              </div>
                              <div class="col-4" style={{ marginTop: '5px' }}>
                                <span style={{ fontSize: '12px', marginTop: '15px', marginLeft: '-20px' }}>Select All</span>
                              </div>
                              <div class="col-4" style={{ marginTop: '-2px', marginLeft: '8px' }}>
                                <div class="d-flex bd-highlight mb-3">
                                  <div class="p-2 bd-highlight">  <span style={{ fontSize: '12px', marginTop: '12px' }}>Ungrouped </span> </div>
                                  <div class="p-2 bd-highlight"><LuPlus style={{ cursor: 'pointer' }} /></div>
                                </div>
                              </div>
                            </div>






                            <div>
                              {vehicleData?.map((vehicle, index) => (
                                <>



                                  <Card style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', padding: '10px', width: '276px', marginLeft: '-15px', marginTop: '0px', marginBottom: '5px', cursor: 'pointer' }}
                                    bg={selectedVehicleImei === vehicle.imei ? 'light' : ''} key={index}
                                    onClick={() => { setSelectedVehicleImei(vehicle?.imei); fetchVehicleInfo(vehicle?.imei); setIsHistory(false) }}
                                  >
                                    <div class="row">

                                      <Col xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                                        {/* <CiDeliveryTruck 
                                        style={{
                                          fontSize: '30px',
                                        }} /> */}
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ padding: '7px', marginTop: '-6px', borderColor: '#D5D5D5' }} />

                                      </Col>


                                      <Col xs={6} style={{ fontSize: '11px', }}>
                                        {/* <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ fontSize: '10px', height: '10px', width: '10px', borderColor: 'gray' }} /> */}
                                        {vehicle.name}
                                        <br style={{ marginBottom: '-10px' }} />
                                        <span style={{ fontSize: '10px', }}> <FcClock />{" "}
                                          {vehicle?.status?.slice(0, 12)}</span>
                                      </Col>
                                      <Col xs={2} style={{ fontSize: '10px', marginLeft: '-10px', marginTop: '5px' }}>
                                        <div style={{ border: '1px solid orange', marginTop: '3px', borderRadius: '10px', width: '40px', padding: '2px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                          {vehicle?.location_data?.speed}
                                        </div>
                                      </Col>
                                      <Col xs={1} style={{ marginTop: '5px' }}>
                                        {vehicle?.location_data?.isMoving ? (
                                          <img src={require('./images/en.png')} style={{ textAlign: 'center', color: 'green', width: '18px' }} alt="Engine On" />
                                        ) : (
                                          <img src={require('./images/engine-off.png')} style={{ textAlign: 'center', color: 'red', width: '18px' }} alt="Engine Off" />
                                        )}
                                      </Col>


                                      <Col xs={1} style={{ marginTop: '5px' }}>
                                        <Popup trigger=
                                          {<a style={{
                                            // border: 'none', backgroundColor: 'transparent'
                                            height: '10px'
                                          }}> <BiDotsVertical style={{ marginLeft: '-8px' }} /></a>}
                                          position="right center">
                                          <div className="test" style={{ height: '220px', minWidth: '80px', fontSize: '13px' }}>
                                            <div className="d-flex flex-column">
                                              <div className="p-2 w-100">
                                                <Dropdown as={ButtonGroup} drop="end">
                                                  {/* <Button variant="link"><SlUserFollow />{" "} History</Button> */}

                                                  <Dropdown.Toggle variant="link" id="dropdown-basic" style={{ width: '120px' }}>
                                                    <SlUserFollow />{"        "}History
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => {
                                                      const now = new Date()
                                                      fetchHistory(
                                                        vehicle.imei,
                                                        new Date(now.getTime() - (1 * 60 * 60 * 1000)),
                                                        new Date()
                                                      );
                                                    }}>Last hour</Dropdown.Item>
                                                    <Dropdown.Item
                                                      onClick={() => {
                                                        const now = new Date()
                                                        fetchHistory(
                                                          vehicle.imei,
                                                          new Date(now.getTime() - (24 * 60 * 60 * 1000)),
                                                          new Date()
                                                        );
                                                      }}
                                                    >Today</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => {
                                                      const now = new Date()
                                                      fetchHistory(
                                                        vehicle.imei,
                                                        new Date(now.getTime() - (48 * 60 * 60 * 1000)),
                                                        new Date(now.getTime() - (24 * 60 * 60 * 1000))
                                                      );
                                                    }}>yesterday</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => {
                                                      const now = new Date()
                                                      fetchHistory(
                                                        vehicle.imei,
                                                        new Date(now.getTime() - (72 * 60 * 60 * 1000)),
                                                        new Date(now.getTime() - (48 * 60 * 60 * 1000))
                                                      );
                                                    }}>Before 2 days</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => {
                                                      const now = new Date()
                                                      fetchHistory(
                                                        vehicle.imei,
                                                        new Date(now.getTime() - (96 * 60 * 60 * 1000)),
                                                        new Date(now.getTime() - (72 * 60 * 60 * 1000))
                                                      );
                                                    }}>Before 3 days</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-3" onClick={() => {
                                                      const now = new Date()
                                                      fetchHistory(
                                                        vehicle.imei,
                                                        new Date(now.getTime() - (168 * 60 * 60 * 1000)),
                                                        new Date()
                                                      );
                                                    }}>This week</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => {
                                                      const now = new Date()
                                                      fetchHistory(
                                                        vehicle.imei,
                                                        new Date(now.getTime() - (336 * 60 * 60 * 1000)),
                                                        new Date(now.getTime() - (168 * 60 * 60 * 1000))
                                                      );
                                                    }}>Last week</Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                              <div className="p-2"><SlUserFollow />{" "}Follow</div>
                                              <div className="p-2"><SlUserFollow />{" "}Follow (new window)</div>
                                              <div className="p-2" style={{ cursor: 'pointer' }} ><BiStreetView style={{ fontSize: '16px', }} />{" "}
                                                <a href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${vehicle?.location_data?.lat},${vehicle?.location_data?.lng}`} target="_blank" className="text-muted">Street view </a>
                                              </div>
                                              <div className="p-2"><IoIosSend style={{ fontSize: '16px' }} />{" "}Send Command</div>
                                              <div className="p-2"><BiSolidEdit style={{ fontSize: '16px' }} />{" "}Edit</div>
                                            </div>
                                          </div>

                                        </Popup>

                                      </Col>

                                    </div>


                                  </Card>
                                </>
                              ))}





                              <Card style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', padding: '10px', minWidth: '120px', marginLeft: '-15px' }}>
                                <div class="row">
                                  <Col xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={require('./images/delivery-truck.png')} style={{
                                      width: '20px',
                                      height: '20px',
                                      flexShrink: '0'
                                    }} />
                                  </Col>
                                  <Col xs={5} style={{ fontSize: '10px', }}>
                                    Demo Card 1
                                    <br style={{ marginBottom: '-10px' }} />
                                    <span style={{ fontSize: '10px' }}> <FcClock />{" "}
                                      Moving 28 km</span>
                                  </Col>
                                  <Col xs={3} style={{ fontSize: '10px' }}>
                                    <div style={{ border: '1px solid orange', marginTop: '3px', borderRadius: '10px', width: '40px', padding: '2px' }}>
                                      12 kph
                                    </div>
                                  </Col>
                                  <Col xs={1} style={{}}>
                                    <PiEngine />

                                  </Col>
                                  <Col xs={1} style={{}}>
                                    <PiDotsThreeVerticalBold />

                                  </Col>

                                </div>


                              </Card>

                            </div>

                          </div>
                        </div>



                        <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                          <div className="input-group rounded" >
                            <input type="search" className="form-control rounded" placeholder="Search..." aria-label="Search" aria-describedby="search-addon" style={{ marginTop: '15px', width: '20%!important' }} />
                            <button type="button" className="input-group-text border-0" id="search-addon" title="Refresh" style={{ color: 'black', backgroundColor: '#F5F5F5', marginTop: '15px', width: '12%', marginLeft: '5px' }}>
                              <i className="fas fa-refresh" style={{ color: 'orange' }}></i>
                            </button>

                            <button type="button" className="input-group-text border-0" id="search-addon" title="Export" style={{ color: 'black', backgroundColor: '#F5F5F5', marginTop: '15px', width: '12%', marginLeft: '5px' }}>
                              <i className='fas fa-file-export' style={{ color: 'green' }}></i>
                            </button>

                            <button type="button" className="input-group-text border-0" id="search-addon" title="Delete" style={{ color: 'black', backgroundColor: '#F5F5F5', marginTop: '15px', width: '12%', marginLeft: '5px', }}>
                              <i className='fas fa-trash-alt' style={{ color: 'red' }}></i>
                            </button>
                          </div>

                          <ul className="inline-menu" style={{ marginBottom: '20px', color: 'black', fontWeight: 'bold', marginTop: '20px', borderRadius: '0px', }}>


                            <button type="button" className="btn btn-outline-primary" style={{ borderRadius: '10px', }}>Time</button>
                            <button type="button" className="btn btn-outline-primary" style={{ borderRadius: '10px', marginLeft: '5px' }}>Object</button>
                            <button type="button" className="btn btn-outline-primary" style={{ borderRadius: '10px', width: '60%', marginLeft: '5px' }}>Event</button>

                          </ul>

                        </div>
                        <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">


                          <div className="input-group rounded" >
                            <input type="search" className="form-control rounded" placeholder="Search..." aria-label="Search" aria-describedby="search-addon" style={{ marginTop: '15px', width: '100%' }} />
                            <button type="button" className="input-group-text border-0" id="search-addon" title="Refresh" style={{ color: 'black', backgroundColor: '#F5F5F5', marginTop: '10px', width: '15%', marginLeft: '5px', borderRadius: '8px' }}>
                              <i className="fas fa-refresh" style={{ color: 'orange' }}></i>
                            </button>

                            <button type="button" className="input-group-text border-0" id="search-addon" title="Export" style={{ color: 'black', backgroundColor: '#F5F5F5', marginTop: '10px', width: '15%', marginLeft: '5px', borderRadius: '8px' }}>
                              <i className='fas fa-file-export' style={{ color: 'green' }}></i>
                            </button>

                            <button type="button" title="Import" className="input-group-text border-0" id="search-addon" style={{ color: 'black', backgroundColor: '#F5F5F5', marginTop: '10px', width: '15%', marginLeft: '5px', borderRadius: '8px' }}>
                              <i className='fas fa-file-import' style={{ color: 'orange' }}></i>
                            </button>

                            <button type="button" title="Group" className="input-group-text border-0" id="search-addon" style={{ color: 'black', backgroundColor: '#F5F5F5', marginTop: '10px', width: '15%', marginLeft: '5px', borderRadius: '8px' }}
                              data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">
                              <i className='fas fa-user-check' style={{ color: 'green' }}></i>
                            </button>

                            <button type="button" title="Add Marker" className="input-group-text border-0" id="search-addon" style={{ color: 'black', backgroundColor: '#F5F5F5', marginTop: '10px', width: '15%', marginLeft: '5px', borderRadius: '8px' }}>
                              <i className='fas fa-clipboard-list' style={{ color: 'orange' }}></i>
                            </button>

                            <button type="button" title="Delete" className="input-group-text border-0" id="search-addon" style={{ color: 'black', backgroundColor: '#F5F5F5', marginTop: '10px', width: '15%', marginLeft: '5px', borderRadius: '8px' }}>
                              <i className='fas fa-trash-alt' style={{ color: 'red' }}></i>
                            </button>

                            <button type="button" title="Show/Hide All" className="input-group-text border-0" id="search-addon" style={{ color: 'black', backgroundColor: '#F5F5F5', marginTop: '10px', width: '15%', marginLeft: '5px', borderRadius: '8px' }}>
                              <i className='fas fa-eye'></i>
                            </button>
                            <button
                              type="button"
                              className='btn btn-outline-primary'
                              style={{ width: "82%", marginLeft: "", fontSize: "12px", borderRadius: "0px", height: '30px', marginTop: '10px', borderColor: '#F5F5F5', borderRadius: '8px' }}

                            >
                              Name
                            </button>


                          </div>


                        </div>
                        <div className="tab-pane fade" id="nav-history" role="tabpanel" aria-labelledby="nav-history-tab">
                          <div className="input-group rounded" >
                            <label style={{ marginTop: '25px' }}>Object</label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              style={{ marginTop: '20px', marginLeft: '43px', width: '70%', borderRadius: '10px' }}
                              value={selectedVehicle}
                              onChange={(e) => setSelectedVehicle(e.target.value)}
                            >                  <option selected >Select</option>
                              {vehicles.map((vehicle) => (
                                <option key={vehicle.id} value={vehicle.id}>
                                  {vehicle.name}
                                </option>
                              ))}
                            </select>
                            <label style={{ marginTop: '15px' }}>Filter</label>
                            <select className="form-select" aria-label="Default select example" style={{ marginTop: '10px', marginLeft: '55px', width: '65%', borderRadius: '10px' }}>
                              <option selected>Last Hour</option>
                              <option value="1">Today</option>
                              <option value="2">Yesterday</option>
                              <option value="3">Before 2 Days</option>
                              <option value="3">Before 3 Days</option>
                              <option value="3">This Week</option>
                              <option value="3">Last Week</option>
                              <option value="3">This Month</option>
                              <option value="3">Last Month</option>
                            </select>

                            <label style={{ marginTop: '15px' }}>Time From </label>
                            <input type="date" style={{ marginTop: '10px', marginLeft: '18px', width: '38%', padding: '5px', fontSize: '14px', borderColor: '#F5F5F5', borderRadius: '10px' }} />
                            <input type="time" id="appt" name="appt" style={{ height: '35px', marginLeft: '2px', marginTop: '10px', borderColor: '#F5F5F5', borderRadius: '10px' }} />

                            <label style={{ marginTop: '15px' }}>Time To..... </label>
                            <input type="date" style={{ marginTop: '10px', marginLeft: '18px', width: '38%', padding: '5px', fontSize: '14px', borderColor: '#F5F5F5', borderRadius: '10px' }} />
                            <input type="time" id="appt" name="appt" style={{ height: '35px', marginLeft: '2px', marginTop: '10px', borderColor: '#F5F5F5', borderRadius: '10px' }} />

                            <label style={{ marginTop: '15px' }}>Stops</label>
                            <select className="form-select" aria-label="Default select example" style={{ marginTop: '8px', marginLeft: '53px', width: '60%', borderRadius: '10px' }}>
                              <option selected>1 Min</option>
                              <option value="1">2 Min</option>
                              <option value="2">5 Min</option>
                              <option value="3">10 Min</option>
                              <option value="3">20 Min</option>
                              <option value="3">30 Min</option>
                              <option value="3">1 hour</option>
                              <option value="3">2 hour</option>
                              <option value="3">5 hour</option>
                            </select>


                            <ul className="inline-menu" style={{ marginBottom: '20px', color: 'black', fontWeight: 'bold', marginTop: '20px', borderRadius: '0px' }}>
                              <button type="button" className="btn btn-outline-primary" style={{ borderRadius: '10px', }}>Show</button>
                              <button type="button" className="btn btn-outline-primary" style={{ borderRadius: '10px', marginLeft: '5px' }}>Hide</button>
                              <button type="button" className="btn btn-outline-primary" style={{ borderRadius: '10px', width: '45%', marginLeft: '5px' }}>Im/Ex</button>
                              <button type="button" className="btn btn-outline-primary" style={{ borderRadius: '10px', marginLeft: '45px', marginTop: '5px' }}>Time</button>
                              <button type="button" className="btn btn-outline-primary" style={{ borderRadius: '10px', marginLeft: '5px', marginTop: '5px' }}>Information</button>
                            </ul>

                          </div>
                        </div>
                      </div>
                    </Tab.Pane>

                    {/* Events */}
                    <Tab.Pane className=" fade" id="nav-profile" eventKey="second" role="tabpanel" aria-labelledby="nav-profile-tab">
                      <div className="input-group rounded" >
                        <div className="form-group has-search d-flex" style={{ marginTop: '5px', minWidth: '220px', marginLeft: '' }}>
                          <span className="fa fa-search form-control-feedback" style={{ marginTop: '-8px', }}></span>
                          <input type="text" className="form-control" placeholder="Search" style={{ marginTop: '-8px', height: '32px', marginRight: '' }} />{" "}
                          <img src={require('./images/color.png')} style={{
                            width: '25px', height: '25px', marginTop: '-5px', marginLeft: '5px', border: '1px solid', borderRadius: '3px', height: '29px', padding: '3px',
                            margintop: '-5px'
                          }} />
                        </div>
                      </div>
                      <div className="bd-example table-responsive" style={{ marginTop: '-4px', marginRight: '' }}>
                        <Table className="table table-sm table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Type</th>
                              {/* <th scope="col">Event</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {
                              eventList.map((event, index) => (
                                <tr key={event._id}>
                                  <td>{event.name}</td>
                                  <td>{event.type}</td>
                                  {/* <td>{event.active}</td> */}
                                </tr>
                              ))
                            }

                          </tbody>
                        </Table>
                      </div>

                    </Tab.Pane>

                    {/* Places */}
                    <Tab.Pane className=" fade" id="nav-contact" eventKey="third" role="tabpanel" aria-labelledby="nav-contact-tab">
                      <hr style={{ marginTop: '-4px' }}></hr>

                      <div className="topnav d-flex" style={{ marginTop: '-15px' }}>
                        <a className={selectedMenu === 'MARKER' ? 'active' : ''} style={{ fontSize: '12px', cursor: 'pointer' }} onClick={handleMarkersClick}>Markers(0)</a>
                        <a className={selectedMenu === 'ROUTE' ? 'active' : ''} style={{ fontSize: '12px', cursor: 'pointer' }} onClick={() => { handleRoutesClick(); funcFetchUserRoutes(); }}>Routes(0)</a>
                        <a className={selectedMenu === 'ZONE' ? 'active' : ''} style={{ fontSize: '12px', cursor: 'pointer' }} onClick={() => { handleZonesClick(); funcFetchUserZones(); }}>Zones(0)</a>
                      </div>

                      <div className="input-group rounded" style={{ marginTop: '10px' }}>
                        <div className="form-group has-search d-flex" style={{ marginTop: '5px', minWidth: '250px', marginLeft: '' }}>
                          <span className="fa fa-search form-control-feedback" style={{ marginTop: '-8px' }}></span>
                          <input type="text" className="form-control" placeholder="Search" style={{ marginTop: '-8px', height: '32px', marginRight: '' }} />{" "}
                          <img title="Reload" src={require('./images/color.png')} style={{
                            width: '25px', height: '25px', marginTop: '-5px', cursor: 'pointer', marginLeft: '5px', border: '1px solid', borderRadius: '3px', padding: '3px',
                            margintop: '-7px'
                          }} />
                        </div>
                      </div>
                      <div className="d-flex flex-row" style={{ marginTop: '-12px' }} >
                        <div className="p-2" onClick={() => { setPlacesRoute('') }}><img title="Reload" src={require('./images/color.png')} style={{
                          width: '25px', height: '25px', marginTop: '-5px', cursor: 'pointer', marginLeft: '-5px', border: '1px solid', borderRadius: '3px', padding: '3px',
                          margintop: '-7px'
                        }} /></div>
                        <div className="p-2"><img title="Groups" src={require('./images/groups.png')} style={{
                          width: '25px', height: '25px', marginTop: '-5px', cursor: 'pointer', marginLeft: '-8px', border: '1px solid', borderRadius: '3px', padding: '3px',
                          margintop: '-7px'
                        }} /></div>
                        <div className="p-2" onClick={() => { setPlacesRoute('ADD-MARKER') }}><img title="Add Marker" src={require('./images/marker-add.png')} style={{
                          width: '25px', height: '25px', marginTop: '-5px', cursor: 'pointer', marginLeft: '-8px', border: '1px solid', borderRadius: '3px', padding: '3px',
                          margintop: '-7px'
                        }} /></div>

                        <div className="p-2" onClick={() => { setPlacesRoute('ADD-ROUTE'); setAddRouteCheck(true) }}><img title="Add Route" src={require('./images/route-add.png')} style={{
                          width: '25px', height: '25px', marginTop: '-5px', cursor: 'pointer', marginLeft: '-8px', border: '1px solid', borderRadius: '3px', padding: '3px',
                          margintop: '-7px'
                        }} /></div>

                        <div className="p-2" ><img title="Export" src={require('./images/export.png')} style={{
                          width: '25px', height: '25px', marginTop: '-5px', cursor: 'pointer', marginLeft: '-8px', border: '1px solid', borderRadius: '3px', padding: '3px',
                          margintop: '-7px'
                        }} /></div>
                        <div className="p-2"><img title="Import" src={require('./images/import.png')} style={{
                          width: '25px', height: '25px', marginTop: '-5px', cursor: 'pointer', marginLeft: '-8px', border: '1px solid', borderRadius: '3px', padding: '3px',
                          margintop: '-7px'
                        }} /></div>
                        <div className="p-2" onClick={() => { setPlacesRoute('ADD-ZONE'); setAddZoneCheck(true) }}>
                          <img title="Zone Add" src={require('./images/zones.png')} style={{
                            width: '25px', height: '25px', marginTop: '-5px', cursor: 'pointer', marginLeft: '-8px', border: '1px solid', borderRadius: '3px', padding: '3px',
                            margintop: '-7px'
                          }} /></div>
                        <div className="p-2"><img title="Remove" src={require('./images/remove2.png')} style={{
                          width: '25px', height: '25px', marginTop: '-5px', cursor: 'pointer', marginLeft: '-8px', border: '1px solid', borderRadius: '3px', padding: '3px',
                          margintop: '-7px'
                        }} /></div>
                      </div>


                      {showMarkers && (
                        <>
                          <div className=" d-flex" style={{ height: '30px', backgroundColor: '#F5F5F5', marginTop: '10px' }}>
                            <FaEye style={{ marginLeft: '7px', marginTop: '8px' }} />
                            <span style={{ marginLeft: '7px', borderLeft: '1px solid #D5D5D5', paddingLeft: '7px', fontSize: '14px', marginTop: '5px' }}>Name</span>
                          </div>

                          <div className="d-flex justify-content-between">
                            <div className=" p-2">
                              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            </div>
                            <div className="p-2" style={{ fontSize: '12px' }}>ungrouped(0) +</div>
                          </div><hr style={{ marginTop: '2px' }}></hr>


                          <div className="d-flex justify-content-between " style={{ marginTop: '-10px' }}>
                            <div className=" p-2">
                              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            </div>
                            <div className="p-2 " style={{ fontSize: '13px', marginLeft: '-10px' }}><GiPositionMarker style={{ fontSize: '13px', color: 'green' }} />
                              Marker new 1</div>
                            <div className="p-2" style={{ fontSize: '12px', marginLeft: '80px' }}><LiaPencilAltSolid onClick={() => { setPlacesRoute('ADD-MARKER') }} style={{ fontSize: '13px', cursor: 'pointer' }} />{" "}
                              <Popup trigger=
                                {<button style={{
                                  border: 'none', backgroundColor: 'transparent'
                                }}> <MdDelete style={{ fontSize: '13px', color: 'red' }} /></button>}
                                position="right center">
                                <div className="test" style={{ height: '', minWidth: '400px' }}><span style={{ fontSize: '12px', marginTop: '5px' }}>Are you sure you want to delete?</span></div>
                                <div className="d-flex" style={{ marginBottom: '10px' }}>
                                  <button type="button" className="btn btn-primary btn-sm" style={{ marginTop: '25px', marginLeft: '20px', width: '70px' }}>Yes</button>
                                  <button type="button" className="btn btn-light btn-sm" style={{ marginLeft: '5px', marginTop: '25px', width: '70px', }} onClick={handleCancel}>No</button>
                                </div>

                              </Popup></div>
                          </div><hr style={{ marginTop: '2px' }}></hr>

                          <div className="d-flex justify-content-between " style={{ marginTop: '-10px' }}>
                            <div className=" p-2">
                              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            </div>
                            <div className="p-2 " style={{ fontSize: '13px', marginLeft: '-10px' }}><GiPositionMarker style={{ fontSize: '13px', color: 'green', cursor: 'pointer' }} />
                              Marker new 2</div>
                            <div className="p-2" style={{ fontSize: '12px', marginLeft: '80px' }}><LiaPencilAltSolid style={{ fontSize: '13px', cursor: 'pointer' }} onClick={() => { setPlacesRoute('ADD-MARKER') }} />{" "} <Popup trigger=
                              {<button style={{
                                border: 'none', backgroundColor: 'transparent'
                              }}> <MdDelete style={{ fontSize: '13px', color: 'red' }} /></button>}
                              position="right center">
                              <div className="test" style={{ height: '', minWidth: '400px' }}><span style={{ fontSize: '12px', marginTop: '5px' }}>Are you sure you want to delete?</span></div>
                              <div className="d-flex" style={{ marginBottom: '10px' }}>
                                <button type="button" className="btn btn-primary btn-sm" style={{ marginTop: '25px', marginLeft: '20px', width: '70px' }}>Yes</button>
                                <button type="button" className="btn btn-light btn-sm" style={{ marginLeft: '5px', marginTop: '25px', width: '70px', }} onClick={handleCancel}>No</button>
                              </div>

                            </Popup></div>
                          </div><hr style={{ marginTop: '2px' }}></hr>

                          <div className="d-flex justify-content-between " style={{ marginTop: '-10px' }}>
                            <div className=" p-2">
                              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            </div>
                            <div className="p-2 " style={{ fontSize: '13px', marginLeft: '-10px' }}><GiPositionMarker style={{ fontSize: '13px', color: 'green' }} />
                              Marker new 3</div>
                            <div className="p-2" style={{ fontSize: '12px', marginLeft: '80px' }}><LiaPencilAltSolid style={{ fontSize: '13px', cursor: 'pointer' }} onClick={() => { setPlacesRoute('ADD-MARKER') }} />{" "} <Popup trigger=
                              {<button style={{
                                border: 'none', backgroundColor: 'transparent'
                              }}> <MdDelete style={{ fontSize: '13px', color: 'red' }} /></button>}
                              position="right center">
                              <div className="test" style={{ height: '', minWidth: '400px' }}><span style={{ fontSize: '12px', marginTop: '5px' }}>Are you sure you want to delete?</span></div>
                              <div className="d-flex" style={{ marginBottom: '10px' }}>
                                <button type="button" className="btn btn-primary btn-sm" style={{ marginTop: '25px', marginLeft: '20px', width: '70px' }}>Yes</button>
                                <button type="button" className="btn btn-light btn-sm" style={{ marginLeft: '5px', marginTop: '25px', width: '70px', }} onClick={handleCancel}>No</button>
                              </div>

                            </Popup></div>
                          </div><hr style={{ marginTop: '2px' }}></hr>
                        </>
                      )}


                      {/* zones */}

                      {showZones && (
                        <>
                          <div className=" d-flex" style={{ height: '30px', backgroundColor: '#F5F5F5', marginTop: '10px' }}>
                            <FaEye style={{ marginLeft: '7px', marginTop: '8px' }} />
                            <span style={{ marginLeft: '7px', borderLeft: '1px solid #D5D5D5', paddingLeft: '7px', fontSize: '14px', marginTop: '5px' }}>Name</span>
                          </div>

                          <div className="d-flex justify-content-between">
                            <div className=" p-2">
                              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            </div>
                            <div className="p-2" style={{ fontSize: '12px' }}>ungrouped(0) +</div>
                          </div><hr style={{ marginTop: '2px' }}></hr>


                          {zoneList?.map((zone, index) => (
                            <div key={index}>
                              <div className="d-flex justify-content-evenly " style={{ marginTop: '-10px', cursor: 'pointer', backgroundColor: zoneMap._id === zone._id ? '#f5f5f5' : 'white' }}
                                onDoubleClick={() => funcFetchZone(zone._id)}
                                onClick={() => setZoneMap({})}
                              >
                                <div className=" p-2">
                                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                </div>
                                <div className="p-2 " style={{ fontSize: '13px', marginLeft: '-12px', }}><IoStopSharp
                                  style={{ fontSize: '14px', color: zone.color, marginTop: '-4px' }} />
                                  {zone?.name}</div>
                                <div className="p-2" style={{ fontSize: '12px', marginLeft: '80px' }}><LiaPencilAltSolid onClick={() => { setPlacesRoute('ADD-ZONE') }} style={{ fontSize: '13px', cursor: 'pointer' }} />{" "} <Popup trigger=
                                  {<button style={{
                                    border: 'none', backgroundColor: 'transparent'
                                  }}> <MdDelete style={{ fontSize: '13px', color: 'red' }} /></button>}
                                  position="right center">
                                  <div className="test" style={{ height: '', minWidth: '400px' }}><span style={{ fontSize: '12px', marginTop: '5px' }}>Are you sure you want to delete?</span></div>
                                  <div className="d-flex" style={{ marginBottom: '10px' }}>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ marginTop: '25px', marginLeft: '20px', width: '70px' }} onClick={() => { funcDeleteZone(zone._id) }}>Yes</button>
                                    <button type="button" className="btn btn-light btn-sm" style={{ marginLeft: '5px', marginTop: '25px', width: '70px', }} onClick={handleCancel}>No</button>
                                  </div>

                                </Popup></div>
                              </div><hr style={{ marginTop: '2px' }}></hr>
                            </div>
                          ))}



                          <div className="d-flex justify-content-between " style={{ marginTop: '-10px' }}>
                            <div className=" p-2">
                              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            </div>
                            <div className="p-2 " style={{ fontSize: '13px', marginLeft: '-12px', }}><IoStopSharp
                              style={{ fontSize: '14px', color: 'red', marginTop: '-4px' }} />
                              Zones new 2</div>
                            <div className="p-2" style={{ fontSize: '12px', marginLeft: '80px' }}><LiaPencilAltSolid onClick={() => { setPlacesRoute('ADD-ZONE') }} style={{ fontSize: '13px', cursor: 'pointer' }} />{" "}

                              <Popup trigger=
                                {<button style={{
                                  border: 'none', backgroundColor: 'transparent'
                                }}> <MdDelete style={{ fontSize: '13px', color: 'red' }} /></button>}
                                position="right center">
                                <div className="test" style={{ height: '', minWidth: '400px' }}><span style={{ fontSize: '12px', marginTop: '5px' }}>Are you sure you want to delete?</span></div>
                                <div className="d-flex" style={{ marginBottom: '10px' }}>
                                  <button type="button" className="btn btn-primary btn-sm" style={{ marginTop: '25px', marginLeft: '20px', width: '70px' }}>Yes</button>
                                  <button type="button" className="btn btn-light btn-sm" style={{ marginLeft: '5px', marginTop: '25px', width: '70px', }} onClick={handleCancel}>No</button>
                                </div>

                              </Popup>
                            </div>
                          </div><hr style={{ marginTop: '2px' }}></hr>

                          <div className="d-flex justify-content-between " style={{ marginTop: '-10px' }}>
                            <div className=" p-2">
                              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            </div>
                            <div className="p-2 " style={{ fontSize: '13px', marginLeft: '-12px', }}><IoStopSharp
                              style={{ fontSize: '14px', color: 'red', marginTop: '-4px' }} />
                              Zones new 3</div>
                            <div className="p-2" style={{ fontSize: '12px', marginLeft: '80px' }}><LiaPencilAltSolid onClick={() => { setPlacesRoute('ADD-ZONE') }} style={{ fontSize: '13px', cursor: 'pointer' }} />{" "} <Popup trigger=
                              {<button style={{
                                border: 'none', backgroundColor: 'transparent'
                              }}> <MdDelete style={{ fontSize: '13px', color: 'red' }} /></button>}
                              position="right center">
                              <div className="test" style={{ height: '', minWidth: '400px' }}><span style={{ fontSize: '12px', marginTop: '5px' }}>Are you sure you want to delete?</span></div>
                              <div className="d-flex" style={{ marginBottom: '10px' }}>
                                <button type="button" className="btn btn-primary btn-sm" style={{ marginTop: '25px', marginLeft: '20px', width: '70px' }}>Yes</button>
                                <button type="button" className="btn btn-light btn-sm" style={{ marginLeft: '5px', marginTop: '25px', width: '70px', }} onClick={handleCancel}>No</button>
                              </div>

                            </Popup></div>
                          </div><hr style={{ marginTop: '2px' }}></hr>
                        </>
                      )}


                      {/* routes */}

                      {showRoutes && (
                        <>
                          <div className=" d-flex" style={{ height: '30px', backgroundColor: '#F5F5F5', marginTop: '10px' }}>
                            <FaEye style={{ marginLeft: '7px', marginTop: '8px' }} />
                            <span style={{ marginLeft: '7px', borderLeft: '1px solid #D5D5D5', paddingLeft: '7px', fontSize: '14px', marginTop: '5px' }}>Name</span>
                          </div>

                          <div className="d-flex justify-content-between">
                            <div className=" p-2">
                              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            </div>
                            <div className="p-2" style={{ fontSize: '12px' }}>ungrouped(0) +</div>
                          </div><hr style={{ marginTop: '2px' }}></hr>

                          {routeList?.map((route, index) => (
                            <div key={index}>
                              <div className="d-flex justify-content-between " style={{ marginTop: '-10px' }}>
                                <div className=" p-2">
                                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                </div>
                                <div className="p-2 " style={{ fontSize: '13px', marginLeft: '-12px', }}>
                                  <IoStopSharp
                                    style={{ fontSize: '14px', color: route.color, marginTop: '-4px' }} />
                                  {route?.name}
                                </div>
                                <div className="p-2" style={{ fontSize: '12px', marginLeft: '80px' }}><LiaPencilAltSolid onClick={() => { setPlacesRoute('ADD-ROUTE') }} style={{ fontSize: '13px', cursor: 'pointer' }} />{" "} <Popup trigger=
                                  {<button style={{
                                    border: 'none', backgroundColor: 'transparent'
                                  }}> <MdDelete style={{ fontSize: '13px', color: 'red' }} /></button>}
                                  position="right center">
                                  <div className="test" style={{ height: '', minWidth: '400px' }}><span style={{ fontSize: '12px', marginTop: '5px' }}>Are you sure you want to delete?</span></div>
                                  <div className="d-flex" style={{ marginBottom: '10px' }}>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ marginTop: '25px', marginLeft: '20px', width: '70px' }} onClick={() => { funcDeleteRoute(route._id) }}>Yes</button>
                                    <button type="button" className="btn btn-light btn-sm" style={{ marginLeft: '5px', marginTop: '25px', width: '70px', }} onClick={handleCancel}>No</button>
                                  </div>

                                </Popup></div>
                              </div><hr style={{ marginTop: '2px' }}></hr>
                            </div>
                          ))}

                          <div className="d-flex justify-content-between " style={{ marginTop: '-10px' }}>
                            <div className=" p-2">
                              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            </div>
                            <div className="p-2 " style={{ fontSize: '13px', marginLeft: '-12px', }}><IoStopSharp
                              style={{ fontSize: '14px', color: 'red', marginTop: '-4px' }} />
                              Route new 2</div>
                            <div className="p-2" style={{ fontSize: '12px', marginLeft: '80px' }} ><LiaPencilAltSolid onClick={() => { setPlacesRoute('ADD-ROUTE') }} style={{ fontSize: '13px', cursor: 'pointer' }} />{" "} <Popup trigger=
                              {<button style={{
                                border: 'none', backgroundColor: 'transparent'
                              }}> <MdDelete style={{ fontSize: '13px', color: 'red' }} /></button>}
                              position="right center">
                              <div className="test" style={{ height: '', minWidth: '400px' }}><span style={{ fontSize: '12px', marginTop: '5px' }}>Are you sure you want to delete?</span></div>
                              <div className="d-flex" style={{ marginBottom: '10px' }}>
                                <button type="button" className="btn btn-primary btn-sm" style={{ marginTop: '25px', marginLeft: '20px', width: '70px' }}>Yes</button>
                                <button type="button" className="btn btn-light btn-sm" style={{ marginLeft: '5px', marginTop: '25px', width: '70px', }} onClick={handleCancel}>No</button>
                              </div>

                            </Popup></div>
                          </div><hr style={{ marginTop: '2px' }}></hr>

                          <div className="d-flex justify-content-between " style={{ marginTop: '-10px' }}>
                            <div className=" p-2">
                              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            </div>
                            <div className="p-2 " style={{ fontSize: '13px', marginLeft: '-12px', }}><IoStopSharp
                              style={{ fontSize: '14px', color: 'red', marginTop: '-4px' }} />
                              Route new 3</div>
                            <div className="p-2" style={{ fontSize: '12px', marginLeft: '80px' }}><LiaPencilAltSolid onClick={() => { setPlacesRoute('ADD-ROUTE') }} style={{ fontSize: '13px', cursor: 'pointer' }} />{" "} <Popup trigger=
                              {<button style={{
                                border: 'none', backgroundColor: 'transparent'
                              }}> <MdDelete style={{ fontSize: '13px', color: 'red' }} /></button>}
                              position="right center">
                              <div className="test" style={{ height: '', minWidth: '400px' }}><span style={{ fontSize: '12px', marginTop: '5px' }}>Are you sure you want to delete?</span></div>
                              <div className="d-flex" style={{ marginBottom: '10px' }}>
                                <button type="button" className="btn btn-primary btn-sm" style={{ marginTop: '25px', marginLeft: '20px', width: '70px' }}>Yes</button>
                                <button type="button" className="btn btn-light btn-sm" style={{ marginLeft: '5px', marginTop: '25px', width: '70px', }} onClick={handleCancel}>No</button>
                              </div>

                            </Popup></div>
                          </div><hr style={{ marginTop: '2px' }}></hr>
                        </>
                      )}










                      {placesRoute === 'ADD-ZONE' ? (
                        <Card style={{ padding: '5px', marginTop: '-95%', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', minWidth: '280px', marginLeft: '-10px', height: '250px' }}>
                          <span className="bg-primary" style={{ padding: '5px', color: 'white', fontSize: '14px' }}>Zone properties</span>
                          <div className="p-2">
                            <Form>
                              <Form.Group className="mb-3 d-flex" controlId="exampleForm.ControlInput1" style={{ marginTop: '10px' }}>
                                <Form.Label style={{ fontSize: '13px' }}>Name</Form.Label>
                                <Form.Control type="text" placeholder="" value={zoneName} onChange={(e) => setZoneName(e.target.value)} style={{ width: '67%', marginLeft: '17%' }} />
                              </Form.Group>
                              {zoneNameMissing ? (
                                <p className="text-danger">enter zone name</p>
                              ) : null}

                              <Form.Group className="mb-3 d-flex" controlId="exampleForm.ControlTextarea1" style={{ marginTop: '-8px' }}>
                                <Form.Label style={{ fontSize: '13px' }}>Color</Form.Label>
                                <Form.Control type="color" placeholder="" className="w-50 mx-auto" value={zoneColor} onChange={(e) => setZoneColor(e.target.value)} style={{ width: '', marginLeft: '-6%' }} />
                              </Form.Group>

                              <div
                                className="p-2"
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '13px' }}>
                                <Form.Check
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="Zone Visible"
                                  checked={zoneVisible}
                                  onChange={() => setZoneVisible(!zoneVisible)}
                                />

                                <Form.Check
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="Name Visible"
                                  checked={zoneNameVisible}
                                  onChange={() => setZoneNameVisible(!zoneNameVisible)}
                                  style={{ marginLeft: '-10px' }}
                                />
                              </div>
                            </Form>

                            {zoneCoordsMissing ? (
                              <p className="text-danger">draw zone on map</p>
                            ) : null}

                            <Button className="btn-sm w-25" onClick={funcAddZone} style={{ marginTop: '20px' }}><GoPlus style={{ fontSize: '16px' }} />
                              {" "}Add</Button>
                            <Button className="btn-sm w-25" onClick={() => { handleCancel(); setAddZoneCheck(false) }} style={{ marginLeft: '5px', marginTop: '20px', minWidth: '80px' }}> <IoIosClose style={{ fontSize: '16px' }} />
                              {" "}Cancel</Button>

                          </div>
                        </Card>


                      ) : placesRoute === 'ADD-MARKER' ? (
                        <Card style={{ padding: '5px', marginTop: '-97%', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', minWidth: '280px', marginLeft: '-10px' }}>
                          <span className="bg-primary text-white" style={{ padding: '7px', fontSize: '13px' }}>Marker properties</span>
                          <div className="p-2">
                            <Form>
                              <Form.Group className="mb-3 d-flex" controlId="exampleForm.ControlInput1" style={{ marginTop: '10px' }}>
                                <Form.Label style={{ fontSize: '12px' }}>Group</Form.Label>
                                <Form.Control type="text" placeholder="" onChange={(e) => setMarkerGroup(e.target.value)} style={{ width: '73%', marginLeft: '20%' }} />
                              </Form.Group>
                              <Form.Group className="mb-3 d-flex" controlId="exampleForm.ControlInput1" style={{ marginTop: '-10px', fontSize: '12px' }}>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" value={markerName} onChange={(e) => setMarkerName(e.target.value)} style={{ width: '70%', marginLeft: '20%' }} />
                              </Form.Group>

                              <Form.Group className="mb-3 d-flex" controlId="exampleForm.ControlInput1" style={{ marginTop: '-10px', fontSize: '12px' }}>
                                <Form.Label>Icons.</Form.Label>
                                <Form.Control type="text" placeholder="" value={markerIcon} onChange={(e) => setMarkerIcon(e.target.value)} style={{ width: '70%', marginLeft: '20%' }} />
                              </Form.Group>

                              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" style={{ marginTop: '-7px', fontSize: '12px' }}>
                                <Form.Label>Default Marker</Form.Label>
                                {/* <Form.Control as="textarea" rows={3} value={markerDesc} onChange={(e) => setMarkerDesc(e.target.value)} /> */}
                                <div className="card">
                                  <div className="card-body" style={{ minHeight: '120px', overflow: 'scroll' }}>
                                    <div className="d-flex flex-row" style={{ marginLeft: '-30px', marginTop: '-15px' }}>
                                      <div className="p-2"></div>
                                      <div className="p-2"><img src={require('./images/pin-3.png')} style={{ width: '20px' }} /></div>
                                      <div className="p-2"><img src={require('./images/pin-8.png')} style={{ width: '20px' }} /></div>
                                      <div className="p-2"><img src={require('./images/pin-9.png')} style={{ width: '20px' }} /></div>
                                      <div className="p-2"><img src={require('./images/pin-23.png')} style={{ width: '20px' }} /></div>
                                      <div className="p-2"><img src={require('./images/pin-24.png')} style={{ width: '20px' }} /></div>
                                      <div className="p-2"><img src={require('./images/pin-26.png')} style={{ width: '20px' }} /></div>
                                      <div className="p-2"><img src={require('./images/pin-28.png')} style={{ width: '20px' }} /></div>

                                    </div>

                                    <div className="d-flex flex-row" style={{ marginLeft: '-30px', marginTop: '-5px' }}>
                                      <div className="p-2"></div>
                                      <div className="p-2"><img src={require('./images/service-24-hours-1.png')} style={{ width: '20px' }} /></div>
                                      <div className="p-2"><img src={require('./images/service-24-hours.png')} style={{ width: '20px' }} /></div>
                                      <div className="p-2"><img src={require('./images/service-aeroplane.png')} style={{ width: '20px' }} /></div>
                                      <div className="p-2"><img src={require('./images/service-airplane.png')} style={{ width: '20px' }} /></div>
                                      <div className="p-2"><img src={require('./images/pin-6.png')} style={{ width: '20px' }} /></div>
                                      <div className="p-2"><img src={require('./images/pin-12.png')} style={{ width: '20px' }} /></div>
                                      <div className="p-2"><img src={require('./images/service-wine-bottle.png')} style={{ width: '20px' }} /></div>

                                    </div>
                                    <div className="d-flex flex-row" style={{ marginLeft: '-30px', marginTop: '-5px' }}>
                                      <div className="p-2"></div>
                                      <div className="p-2"><img src={require('./images/service-bellboy.png')} style={{ width: '20px' }} /></div>
                                      <div className="p-2"><img src={require('./images/service-beer.png')} style={{ width: '20px' }} /></div>
                                      <div className="p-2"><img src={require('./images/service-bed.png')} style={{ width: '20px' }} /></div>
                                      <div className="p-2"><img src={require('./images/service-bed-1.png')} style={{ width: '20px' }} /></div>
                                      <div className="p-2"><img src={require('./images/service-bathtub.png')} style={{ width: '20px' }} /></div>

                                    </div>


                                  </div>
                                  <div className="btn-group" role="group" aria-label="Basic example">
                                    <button type="button" className="btn btn-light btn-sm">Save</button>
                                    <button type="button" className="btn btn-light btn-sm" style={{ marginLeft: '10px' }}>Cancel</button>
                                    <button type="button" className="btn btn-light btn-sm" style={{ marginLeft: '10px' }}>Upload</button>
                                  </div>
                                </div>
                              </Form.Group>



                              <div
                                className="p-2"
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '-15px' }}>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                  <Form.Label style={{ fontSize: '12px' }}>Radius</Form.Label>
                                  <Form.Control type="number" placeholder="radius" className="w-76" value={markerRadius} onChange={(e) => setMarkerRadius(e.target.value)} />
                                </Form.Group>

                                <Form.Check
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="Visible"
                                  checked={markerVisible}
                                  onChange={() => setMarkerVisible(!zoneNameVisible)} style={{ marginLeft: '5px' }}
                                />
                              </div>

                              <div
                                className="p-2"
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '-10px' }}>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                  <Form.Label style={{ fontSize: '12px' }}>Latitude</Form.Label>
                                  <Form.Control type="text" placeholder="radius" className="w-78" value={markerLat} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" style={{ marginLeft: '3px' }}>
                                  <Form.Label style={{ fontSize: '12px' }}>Longitude</Form.Label>
                                  <Form.Control type="text" placeholder="radius" className="w-78" value={markerLng} />
                                </Form.Group>

                              </div>
                            </Form>
                            <Button className="btn-sm w-25" onClick={funcAddMarker}><GoPlus style={{ fontSize: '16px' }} />{" "}Add</Button>
                            <Button className="btn-sm w-40" onClick={handleCancel} style={{ marginLeft: '4px' }}><IoIosClose style={{ fontSize: '16px' }} />
                              {" "}Cancel</Button>

                          </div>
                        </Card>
                      ) : placesRoute === 'ADD-ROUTE' ? (
                        <Card style={{ padding: '5px', marginTop: '-95%', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', minWidth: '280px', marginLeft: '-10px' }}>
                          <span className="bg-primary text-white" style={{ padding: '7px', fontSize: '13px' }}>Route properties</span>

                          <div className="p-2">
                            <Form>
                              <Form.Group className="mb-3 d-flex" controlId="exampleForm.ControlInput1" style={{ marginTop: '10px' }}>
                                <Form.Label style={{ fontSize: '13px' }}>Name</Form.Label>
                                <Form.Control type="text" placeholder="" value={routeName} onChange={(e) => setRouteName(e.target.value)} style={{ width: '73%', marginLeft: '17%' }} />
                              </Form.Group>

                              <Form.Group className="mb-3 d-flex" controlId="exampleForm.ControlInput1" style={{ marginTop: '-10px' }}>
                                <Form.Label style={{ fontSize: '13px' }}>Deviation</Form.Label>
                                <Form.Control type="number" placeholder="" value={routeDeviation} onChange={(e) => setRouteDeviation(e.target.value)} style={{ width: '80%', marginLeft: '9%' }} />
                              </Form.Group>

                              <Form.Group className="mb-3 d-flex" controlId="exampleForm.ControlTextarea1" style={{ marginTop: '-10px' }}>
                                <Form.Label style={{ fontSize: '13px' }}> Color</Form.Label>
                                <Form.Control type="color" placeholder="route color" className="w-50 mx-auto" value={routeColor} onChange={(e) => setRouteColor(e.target.value)} style={{ width: '', marginLeft: '1%' }} />
                              </Form.Group>



                              <div
                                className="p-2"
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '-10px', fontSize: '13px' }} >
                                <Form.Check
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="Route Visible"
                                  checked={routeVisible}
                                  onChange={() => setRouteVisible(!routeVisible)}
                                />

                                <Form.Check
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="Name Visible"
                                  checked={routeNameVisible}
                                  onChange={() => setRouteNameVisible(!routeNameVisible)}
                                />
                              </div>

                            </Form>
                            <Button className="btn-sm w-25" onClick={funcAddRoute} style={{ marginTop: '20px', }}><GoPlus style={{ fontSize: '16px' }} />{" "}Add</Button>
                            <Button className="btn-sm w-40" onClick={handleCancel} style={{ marginLeft: '4px', marginTop: '20px' }}><IoIosClose style={{ fontSize: '16px', }} />
                              {" "}Cancel</Button>
                          </div>
                        </Card>



                      ) : (
                        null
                      )}
                    </Tab.Pane>

                    {/* History */}
                    <Tab.Pane className=" fade" id="nav-history" eventKey="fourth" role="tabpanel" aria-labelledby="nav-history-tab"><hr style={{ marginTop: '-4px' }}></hr>
                      <div>
                        <label for="fname" style={{ fontSize: '11px' }}>Object</label>
                        <Multiselect
                          displayValue="name"
                          closeOnSelect={true}
                          showArrow={true}
                          onKeyPressFn={function noRefCheck() { }}
                          onRemove={function noRefCheck() { }}
                          onSearch={function noRefCheck() { }}
                          onSelect={function noRefCheck() { }}
                          selectionLimit={1}
                          options={historyDeviceList}
                          style={{
                            multiselectContainer: {
                              width: '95%'
                            }
                          }}
                        />
                      </div>
                      <div>
                        <label for="fname" style={{ fontSize: '11px', marginTop: '8px' }}>Filter</label>
                        <select className="form-select" style={{
                          marginLeft: '86px',
                          marginTop: '-23px',
                          width: '185px',
                          padding: '3px',
                          borderRadius: '3px',

                        }}>
                          <option value="volvo">Filter</option>
                          <option value="saab">Today</option>
                          <option value="opel">yesterday</option>
                          <option value="audi">Before 2 days</option>
                          <option value="audi">Before 3 days</option>
                          <option value="audi">This week</option>
                          <option value="audi">Last week</option>
                          <option value="audi">This month</option>
                          <option value="audi">Last month</option>
                        </select>
                      </div>
                      <div>
                        <label for="fname" style={{ fontSize: '11px', marginTop: '13px' }}>Timing from</label>
                        <input type="datetime-local" className="form-control" id="birthdaytime" name="birthdaytime" style={{
                          width: '185px', marginLeft: '86px', marginTop: '-27px', borderRadius: '2px',
                          borderColor: ''
                        }}></input>
                      </div>
                      <div>
                        <label for="fname" style={{ fontSize: '11px' }}>Timing To</label>
                        <input type="datetime-local" className="form-control" id="birthdaytime" name="birthdaytime" style={{
                          width: '185px', marginLeft: '86px', marginTop: '-18px', borderRadius: '2px',
                          borderColor: ''
                        }}></input>
                      </div>
                      <div>
                        <label for="fname" style={{ fontSize: '11px' }}>Stops</label>
                        <select className="form-select" style={{
                          marginLeft: '86px',
                          marginTop: '-18px',
                          width: '185px',
                          padding: '3px',
                          borderRadius: '3px',

                        }}>
                          <option value="volvo">1 min</option>
                          <option value="saab">2 min</option>
                          <option value="opel">5 min</option>
                          <option value="audi">20 min</option>
                          <option value="audi">30 min</option>
                          <option value="audi">1 h</option>
                          <option value="audi">2 h</option>
                          <option value="audi">5 h</option>
                        </select>
                      </div>
                      <div className="btn-group" role="group" aria-label="Basic example" style={{ marginTop: '15px', marginLeft: '-5px', borderRadius: '5px' }}>
                        <button type="button" className="btn btn-outline-primary btn-sm" style={{
                          minWidth: '38%',
                          height: '30px',
                          borderRadius: '3px'
                        }}>Show</button>
                        <button type="button" className="btn btn-outline-primary btn-sm" style={{
                          minWidth: '38%',
                          height: '30px',
                          borderRadius: '3px',
                          marginLeft: '3px'

                        }}>Hide</button>
                        <button type="button" className="btn btn-outline-primary btn-sm" style={{
                          minWidth: '70%',
                          height: '30px',
                          borderRadius: '3px',
                          marginLeft: '3px'

                        }}>Import/Export</button>
                      </div>
                      <div className="btn-group" role="group" aria-label="Basic example" style={{ marginTop: '6px', marginLeft: '-5px', borderRadius: '5px' }}>
                        <button type="button" className="btn btn-outline-primary btn-sm" style={{
                          minWidth: '125px',
                          height: '30px',
                          borderRadius: '3px'
                        }}>Time</button>
                        <button type="button" className="btn btn-outline-primary btn-sm" style={{
                          minWidth: '147px',
                          height: '30px',
                          borderRadius: '3px',
                          marginLeft: '3px'

                        }}>Information</button>


                      </div>
                    </Tab.Pane>


                  </Tab.Content>

                </Tab.Container>
              </div>

              {/* <div className="btn-group" role="group" aria-label="Basic example">
                <button type="button" className="btn btn-primary btn-sm" style={{ borderRadius: '10px', minWidth: '60px' }}>Object</button>

                <button type="button" className="btn btn- btn-sm" style={{ marginLeft: '5px', borderRadius: '10px', minWidth: '60px', backgroundColor: '#F5F5F5' }}>Events</button>

                <button type="button" className="btn btn- btn-sm"
                  style={{ marginLeft: '5px', borderRadius: '10px', minWidth: '60px', backgroundColor: '#F5F5F5' }}>Places</button>

                <button type="button" className="btn btn- btn-sm"
                  style={{ marginLeft: '5px', borderRadius: '10px', minWidth: '60px', backgroundColor: '#F5F5F5' }}>History</button>
              </div> */}


            </div>


          </Card>
        )
        }
        <div style={{ height: '90vh', width: '90%', marginTop: '-7px', marginLeft: isCardVisible ? '275px' : '-15px', zIndex: 5 }}>

          <div className=" w-10  d-flex flex-column m-2 " style={{ position: 'absolute', marginTop: '30px' }}>


            <ButtonGroup vertical className="btn-group bg- card" style={{ width: '35px', marginTop: '123px', zIndex: 9, marginLeft: '-10px' }}>
              <Button variant="" className={`  ${showMarker ? ' ' : ''}`} data-bs-toggle="tooltip" title="Enable and Disable Objects" onClick={() => { setShowMarker(!showMarker) }} style={{}}><BsSendFill style={{ marginLeft: '-14px', fontSize: '14px', color: '' }} /></Button>

              <Button variant="" className={`  ${showToolTip ? ' ' : ''}`} data-bs-toggle="tooltip" title="Enable and Disable Object label." onClick={() => { setShowToolTip(!showToolTip) }}><BsFillChatLeftTextFill style={{ marginLeft: '-12px', fontSize: '14px', color: '' }} /></Button>

              <Button variant="" className={` ${showCluster ? ' ' : ''}`} data-bs-toggle="tooltip" title="Enable and Disable Markers" ><BsGeoAltFill style={{ marginLeft: '-12px', color: '' }} /></Button>

              <Button variant="" className="  " data-bs-toggle="tooltip" title="Enable and Disable Routes" onClick={() => { funcFetchUserRoutes(); setShowRoute(!showRoute) }}><BsFillPinMapFill style={{ marginLeft: '-10px', fontSize: '14px', color: '' }} /></Button>


              <Button variant="" className=" " data-bs-toggle="tooltip" title="Enable and Disable Zones" onClick={() => { funcFetchUserZones(); setShowZone(!showZone) }}><GiBrokenHeartZone style={{ marginLeft: '-12px', fontSize: '14px', color: '' }} /></Button>

              <Button variant="" className={`  ${showCluster ? ' ' : ''}`} data-bs-toggle="tooltip" title="Enable and Disable Cluster" onClick={() => { setShowCluster(!showCluster) }}><AiOutlineCluster style={{ marginLeft: '-12px', fontSize: '14px', color: '' }} /></Button>

              <Button variant="" className={`  ${showCluster ? ' ' : ''}`} data-bs-toggle="tooltip" title="Enable and Disable Tail" onClick={() => { setShowTail(!showTail) }}><PiPathBold style={{ marginLeft: '-12px', fontSize: '14px', color: '' }} /></Button>


              <Button variant="" className={`  ${showCluster ? ' ' : ''}`} data-bs-toggle="tooltip" title="Enable and Disable Cluster" onClick={setFullScreen}><IoExpandOutline style={{ marginLeft: '-12px', fontSize: '14px', color: '' }} /></Button>


              <Button variant="" className={`  ${showDraw ? ' ' : ''}`} data-bs-toggle="tooltip" title="Enable and Disable Live Traffic" ><FaTrafficLight style={{ marginLeft: '-12px', fontSize: '14px', color: '' }} /></Button>

              {/* <Button variant="" className={`  ${showDraw ? ' ' : ''}`} data-bs-toggle="tooltip" title="Enable and Disable Draw Options." onClick={() => { setShowDraw(!showDraw) }}><i className='fas fa-draw-polygon' style={{ marginLeft: '-12px', fontSize: '14px', color: 'white' }}></i>
              </Button>
              <Button variant="" className=" " data-bs-toggle="tooltip" title="Measure Area"><i className="fas fa-chart-area" style={{ marginLeft: '-12px', fontSize: '14px', color: 'white' }}></i></Button> */}
            </ButtonGroup>


          </div>




          {/* currently working main monitor map */}
          <MonitorMap
            showMarker={showMarker}
            showToolTip={showToolTip}
            showTail={showTail}
            showCluster={showCluster}
            setZoneVertices={setZoneVertices}
            zoneMap={zoneMap}
            setMarkerLat={setMarkerLat}
            setMarkerLng={setMarkerLng}
            setRoutePoints={setRoutePoints}
            zoneList={zoneList}
            showZone={showZone}
            routeList={routeList}
            showRoute={showRoute}
            addZoneCheck={addZoneCheck}
            addRouteCheck={addRouteCheck}
          />
        </div>




        <div style={{ margin: "0" }}>

        </div>


        {/* monitor screen bottom menu bar */}
        {/* <MonitorBottom />  */}
        {showBottomCard ? (
          // <div style={{ marginLeft: isCardVisible ? '271px' : '-31px', bottom: '0', maxWidth: '200px', position: 'fixed', zIndex: '7', }}>

          <div style={{ marginLeft: isCardVisible ? '271px' : '-31px', bottom: '0', maxWidth: '700px', position: 'fixed', zIndex: '7', }}>

            {isHistory ? (
              <></>
              // <MonitorGraph />
            ) : (
              <TestCard
                selectedVehicleImei={selectedVehicleImei}
              />
            )}

            {/* <MonitorBottom />  */}
            {/* <DemoCard
                setFullScreen={setFullScreen}
                selectedVehicleImei={selectedVehicleImei}
              /> */}
            {/* <MonitorMassage /> */}

            {/* <DemoCard
              setFullScreen={setFullScreen}
              selectedVehicleImei={selectedVehicleImei}
            /> */}
            {/* </div> */}
          </div>
        ) : null}

        {showMonitorDataBar ? (
          <div style={{ position: 'fixed', bottom: '180px', right: '8%', zIndex: 9, width: '70vw', maxHeight: '280px' }}>
            <MonitorDataBar className=""
              setShowMonitorDataBar={setShowMonitorDataBar}
              showMonitorDataBar={showMonitorDataBar}
              style={{
                display: "flex",
                flexFlow: 'column wrap',
                alignContent: 'flex-end',
              }}
            />
          </div>
        ) : null}

      </div>





    </div >



  )
}








