import React, { memo, Fragment, useState, useEffect } from "react";
import { Form, Pagination, Row, Button, Alert, Dropdown, Table, Modal, } from "react-bootstrap";
import "./Style.css"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MyVerticallyCenteredModal from "./ModalComponents/DeleteModal";
import AddModal from "./ModalComponents/AddModal";
import EditTable from "./ModalComponents/EditTable";
import DeactivateModal from "./ModalComponents/DeactivateModal";
import ExpModal from "./ModalComponents/ExpModal";


//importing of the icons
import { TfiPencil } from "react-icons/tfi";
import { TfiEye } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import { TfiBookmarkAlt } from "react-icons/tfi";
import { TbSettingsCog } from "react-icons/tb";
import { TbFilter } from "react-icons/tb";
import { IoMdAdd } from "react-icons/io";
import { FcCheckmark } from "react-icons/fc";
import { IoMdRefresh } from "react-icons/io";
import { FaSortAmountDown } from "react-icons/fa";
import { FaSortAmountUp } from "react-icons/fa";

import EventsModal from '../Speedo/ModalComponents/EventsModal';
import CustomerViewModal from './CustomerModalView/CustomerViewModal';
import CustomerEvents from './ObjectEvents/CustomerEvents';

import NewLoader from "./speedo-asset/Loader";
import { BsSendFill } from "react-icons/bs";
import SendNotificationModal from "./SendNotificationModal";








//components
import Card from "../../../components/bootstrap/card";
// import Datatable from "../../../components/DataTable";


// import DeviceDetailModal from "../../../our-customised-components/other-components/assets/DeviceDetailModal";
// import DeviceEditModal from "../../../our-customised-components/other-components/assets/DeviceEditModal";
// import DeviceCreateModal from "../../../our-customised-components/other-components/assets/DeviceCreateModal";
// import DeviceSensorModal from "../../../our-customised-components/other-components/assets/device-sensor/DeviceSensorModal";
// import UserEventModal from "../../../our-customised-components/other-components/assets/UserEditModal";



const TableData = memo(() => {
  // const navigate = useNavigate()
  // const loginCheck = sessionStorage.getItem('loginCheck')
  // useEffect(() => {
  //   if (loginCheck === 'LOGOUT') {
  //     navigate('/sign-in')
  //   }
  // })

  const userPrivileges = JSON.parse(localStorage.getItem("loginInfo"))?.privileges?.cpanel?.user;
  const userTypePrivileges = JSON.parse(localStorage.getItem("loginInfo"))?.privileges?.user_type;

  //states for handling modals
  const [viewModal, setViewModal] = useState(false);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [addModalShow, setAddModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [deactivateModalShow, setDeactivateModalShow] = useState(false);
  const [expModal, setExpModal] = useState(false);
  const [editTable, setEditTable] = useState(false);
  const [eventsModal, setEventsModal] = useState(false);
  const [customerEvent, setCustomerEvent] = useState(false);
  const [errorFetchingTable, setErrorFetchingTable] = useState(false)


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //declaration of the state variables for API data.
  const [toggleChange, setToggleChange] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [tableStatus, setTableStatus] = useState('')
  const [userNameForEdit, setUserNameForEdit] = useState('')
  //delete user
  const [deleted, setDeleted] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('')
  const [userNameForDelete, setUserNameForDelete] = useState('')
  //parameters
  const [pageNumber, setPageNumber] = useState('1')
  const [rows, setRows] = useState('20')
  const [field, setField] = useState('createdAt')
  const [totalPages, setTotalPages] = useState('1')
  //response data
  const [userList, setUserList] = useState([])
  //events
  const [userNameForEvent, setUserNameForEvent] = useState('')
  //search
  const [searchField, setSearchField] = useState('all')
  const [searchValue, setSearchValue] = useState('')
  //bulk action
  const [selectedUser, setSelectedUser] = useState([])



  const fetchUsers = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem('token'));


      setIsLoading(true)
      var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
      };
      const response = await fetch(`https://gps.speedotrack.com/api/cpanel/user?page=${pageNumber}&rows=${rows}&sort=${field}&search-field=${searchField}&search-value=${searchValue}`, requestOptions)
      const result = await response.json();
      setUserList(result?.result)
      setTotalPages(result?.pages)
      setTableStatus(`(${result?.total} / ${result?.result?.length})`)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      setErrorFetchingTable(true);
    }
  }

  useEffect(() => {
    fetchUsers();
  }, [pageNumber, rows, field, toggleChange, searchField, searchValue])


  const deleteUser = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem('token'));

      var requestOptions = {
        method: 'DELETE',
        redirect: 'follow',
        headers: myHeaders
      };

      const response = await fetch(`https://gps.speedotrack.com/api/cpanel/user/${userNameForDelete}`, requestOptions)
      const result = await response.json()
      if (response.ok) {
        setDeleted(true)
        setDeleteMessage(result.message)
        setTimeout(() => {
          handleClose();
          setDeleted(false)
        }, 3000)
        setToggleChange(!toggleChange)
      }
    } catch (error) {
      console.log(error)
    }
  }


  const userSelectToggle = (username) => {
    if (selectedUser.includes(username)) {
      let newSelection = selectedUser.filter((user) => user !== username)
      setSelectedUser(newSelection)
    } else {
      setSelectedUser([...selectedUser, username])
    }
  }

  const selectAllUser = () => {
    const usernameList = userList.map(user => user.username);
    if (userList.length === selectedUser.length) {
      // If all users are already selected, deselect all
      setSelectedUser([]);
    } else {
      // Select all users by setting the usernameList
      setSelectedUser(usernameList);
    }
  };

  const [notificationShow, setNotificationShow] = React.useState(false);




  return (
    <>
      {userTypePrivileges === 'user' ? (
        <h1>Users are not allowed to see other user</h1>
      ) : (
        <>
          <Fragment>
            <Row>
              <Fragment>
                <Row >
                  <Alert variant="danger" show={errorFetchingTable} onClose={() => setErrorFetchingTable(false)} dismissible className="my-2">
                    <p>Failed to fetch user list. Please try again later.</p>
                  </Alert>
                  <Card>
                    <div class="col-12">

                      <Card.Header className="d-flex justify-content-between" class="col-12">
                        <div className="header-title">
                          <div class="btn-group flex-wrap" role="group" aria-label="Basic example" style={{ marginBottom: '20px' }}><h5 style={{ color: '', marginLeft: '10px', marginRight: '10px', marginTop: '8px', marginBottom: 'px' }}><img src={require('./images/user.png')} style={{ width: '30px' }} /> <b>All</b> <b>Users</b>
                          </h5>
                            <span>Show
                              <select style={{ width: '30%', marginTop: '8px', marginLeft: '' }}
                                value={rows}
                                onChange={(e) => {
                                  setRows(e.target.value)
                                }}
                              >
                                <option value={'1'}>1</option>
                                <option value={'5'}>05</option>
                                <option value={'10'}>10</option>
                                <option value={'20'}>20</option>
                                <option value={'50'}>50</option>
                              </select><span style={{}}> entries</span></span>

                            <button type="button" class="btn btn-outline-primary" style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', borderRadius: '5px', height: '38px', fontSize: '14px', marginLeft: "250px" }}>{" "}
                              <i class="fa fa-map-marker" style={{ marginRight: '3px' }}></i>

                              <span style={{ fontSize: '14px' }}>{tableStatus}</span>
                            </button>

                            <Dropdown>
                              <Dropdown.Toggle as={Button} variant="outline-primary" type="button" id="dropdownMenuButton " style={{ marginLeft: '5px', border: '1px solid', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', }}>
                                Action
                              </Dropdown.Toggle>
                              <Dropdown.Menu aria-labelledby="dropdownMenuButton">
                                <Dropdown.Item href="#">Activate</Dropdown.Item>
                                <Dropdown.Item href="#"  >Deactivate</Dropdown.Item>
                                <Dropdown.Item href="#" >Delete</Dropdown.Item>
                                <Dropdown.Item href="#">Set Expiry</Dropdown.Item>
                                <Dropdown.Item href="#" >Import</Dropdown.Item>
                                <Dropdown.Item href="#" >Export</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>



                            <Dropdown>
                              <Dropdown.Toggle as={Button} variant="outline-primary" type="button" id="dropdownMenuButton " style={{ marginLeft: '5px', border: '1px solid ', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', height: '37px' }}>
                                <TbFilter />
                              </Dropdown.Toggle>
                              <Dropdown.Menu aria-labelledby="dropdownMenuButton">
                                <Dropdown.Item onClick={() => setSearchField('all')}>All</Dropdown.Item>
                                <Dropdown.Item onClick={() => setSearchField('username')}>Username</Dropdown.Item>
                                <Dropdown.Item onClick={() => setSearchField('email')}>Email</Dropdown.Item>
                                <Dropdown.Item onClick={() => setSearchField('phone.number')}>Phone</Dropdown.Item>
                                <Dropdown.Item href="#">Email</Dropdown.Item>
                                <Dropdown.Item href="#">Mobile No.</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>

                            <div class=" " style={{ marginTop: '', marginLeft: '5px', marginRight: '' }}>
                              <input type="text" id="inputField" class="form-control " placeholder={`${searchField}...`} style={{ minWidth: '200px', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }}
                                onChange={(e) => setSearchValue(e.target.value)}
                              />
                            </div>
                            <BsSendFill title="Send Notification" style={{ marginTop: '10px', marginLeft: '10px', fontSize: '20px', cursor: 'pointer', }} onClick={() => setNotificationShow(true)} />

                            {userPrivileges?.create ? (
                              <button type="button" class="btn btn-primary" onClick={() => setAddModalShow(true)} style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', borderRadius: '5px', marginLeft: '5px', fontSize: '12px' }}>
                                <IoMdAdd /> Add User
                              </button>
                            ) : null}

                          </div>

                        </div>

                      </Card.Header>


                    </div>
                  </Card>

                  <Card style={{ marginTop: '-18px' }}>
                    <Card.Body >
                      <div className="table-responsive" style={{ marginTop: '10px' }}>
                        {isLoading ? (
                          <NewLoader />
                        ) : (
                          <Table striped hover size="sm">
                            <thead>
                              <tr>
                                <th style={{ paddingRight: '0px', paddingLeft: '10px' }}>S.No</th>
                                <th style={{ paddingRight: '0px', }} >
                                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                    checked={userList.length === selectedUser.length ? true : false}
                                    onChange={selectAllUser}
                                  />
                                </th>
                                <th style={{ paddingLeft: '0px', cursor: 'pointer' }}
                                  onClick={() => setField("active")} onDoubleClick={() => setField("-active")}
                                >Active
                                  {" "}<span className='text-primary'>{field === 'active' ? (<FaSortAmountDown />) : field === '-active' ? (<FaSortAmountUp />) : null}</span></th>

                                <th style={{ cursor: 'pointer' }} onClick={() => setField("account_expire_dt")} onDoubleClick={() => setField("-account_expire_dt")}
                                >Expiry
                                  {" "}<span className='text-primary'>{field === 'account_expire_dt' ? (<FaSortAmountDown />) : field === '-account_expire_dt' ? (<FaSortAmountUp />) : null}</span></th>

                                <th style={{ cursor: 'pointer' }} onClick={() => setField("username")} onDoubleClick={() => setField("-username")}
                                >Username
                                  {" "}<span className='text-primary'>{field === 'username' ? (<FaSortAmountDown />) : field === '-username' ? (<FaSortAmountUp />) : null}</span></th>

                                <th style={{ cursor: 'pointer' }} onClick={() => setField("email")} onDoubleClick={() => setField("-email")}
                                >Email
                                  {" "}<span className='text-primary'>{field === 'email' ? (<FaSortAmountDown />) : field === '-email' ? (<FaSortAmountUp />) : null}</span></th>

                                <th style={{ cursor: 'pointer' }} onClick={() => setField("user_type")} onDoubleClick={() => setField("-user_type")}
                                >Privillege
                                  {" "}<span className='text-primary'>{field === 'user_type' ? (<FaSortAmountDown />) : field === '-user_type' ? (<FaSortAmountUp />) : null}</span></th>

                                <th style={{ cursor: 'pointer' }} onClick={() => setField("login_date")} onDoubleClick={() => setField("-login_date")}
                                >Last Login
                                  {" "}<span className='text-primary'>{field === 'login_date' ? (<FaSortAmountDown />) : field === '-login_date' ? (<FaSortAmountUp />) : null}</span></th>

                                <th style={{ cursor: 'pointer' }} onClick={() => setField("createdAt")} onDoubleClick={() => setField("-createdAt")}
                                >Date
                                  {" "}<span className='text-primary'>{field === 'createdAt' ? (<FaSortAmountDown />) : field === '-createdAt' ? (<FaSortAmountUp />) : null}</span></th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {userList.map((user, index) => (
                                <tr key={index}>
                                  <td style={{ marginLeft: '50px' }}>{index + 1}</td>
                                  <td >
                                    <input class="form-check-input" type="checkbox" id="flexCheckDefault"
                                      checked={selectedUser.includes(user.username) ? true : false}
                                      onChange={() => userSelectToggle(user.username)} />
                                  </td>
                                  <td><FcCheckmark style={{ marginLeft: '-10px' }} /></td>
                                  <td>{user.account_expire_dt}</td>
                                  <td>{user.username}</td>
                                  <td>{user.email}</td>
                                  <td>{user.user_type}</td>
                                  <td>{user.login_date}</td>
                                  <td>{user.createdAt}</td>

                                  <td>
                                    {userPrivileges?.read || userPrivileges?.update ? (
                                      <a href="#" class="edit" data-toggle="modal" title="view" onClick={() => { setUserNameForEdit(user.username); setEditTable(true) }}>
                                        <TfiEye style={{ width: '23px' }} />
                                      </a>
                                    ) : null}

                                    {/* <a href="#" class="delete" data-toggle="modal" title="Edit" onClick={() => setViewModal(true)}><TfiPencil style={{ width: '23px' }} /></a> */}

                                    {userPrivileges?.delete ? (
                                      <a href="#" class="delete" data-toggle="modal" title="Delete" onClick={() => { setUserNameForDelete(user.username); handleShow() }}><TfiTrash style={{ width: '23px' }} /></a>
                                    ) : null}

                                    <a href="#" class="delete" data-toggle="modal" title="Event" onClick={() => { setCustomerEvent(true); setUserNameForEvent(user.username) }} ><TfiBookmarkAlt style={{ width: '23px' }} /></a>

                                  </td>
                                </tr>
                              ))
                              }
                            </tbody>
                          </Table>
                        )}
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                          <Button variant="link" onClick={fetchUsers}><IoMdRefresh /></Button>
                          <Pagination size="sm">
                            <Pagination.Prev
                              disabled={pageNumber === '1' ? true : false}
                              onClick={() => setPageNumber((parseInt(pageNumber) - 1).toString())}
                            />
                            {
                              (
                                <>
                                  {Array.from({ length: totalPages }).map((_, i) => (
                                    <Pagination.Item
                                      key={i}
                                      onClick={() => setPageNumber((i + 1).toString())}
                                      active={pageNumber === (i + 1).toString() ? true : false}
                                    >{i + 1}
                                    </Pagination.Item>
                                  ))}
                                </>
                              )
                            }
                            <Pagination.Next
                              disabled={pageNumber === totalPages.toString() ? true : false}
                              onClick={() => setPageNumber((parseInt(pageNumber) + 1).toString())}
                            />
                          </Pagination>
                        </div>
                      </div>


                    </Card.Body>
                  </Card>
                </Row>


              </Fragment>








            </Row >



            {/* //Edit User Modal */}
            <div id="exampleModalss" class="modal fade">
              <div class="modal-dialog">
                <div class="modal-content" style={{ minWidth: '700px', borderRadius: '5px', paddingBottom: '30px' }}>
                  <div class="modal-header">
                    <h4 class="modal-title text-xs-center ">Edit User </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ border: 'none' }}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <form lg="12">
                      <div class="form-row d-flex">
                        <div class="form-group col-md-6">
                          <label for="inputEmail4">Name</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                        </div>
                        <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                          <label for="inputPassword4">Email</label>
                          <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                        </div>
                      </div>
                      <div class="form-row d-flex">
                        <div class="form-group col-md-6">
                          <label for="inputEmail4">Phone No.</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                        </div>
                        <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                          <label for="inputPassword4">Address (Optional)</label>
                          <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                        </div>
                      </div>
                      <div class="form-row d-flex">
                        <div class="form-group col-md-12">
                          <label for="inputPassword4">User Type</label>
                          <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}>
                            <option selected>Manager</option>
                            <option value="1">Super Admin</option>
                            <option value="2">User</option>
                            <option value="3">Sub Admin</option>
                          </select>
                        </div>
                      </div>
                      <button type="button" class="btn btn-primary" style={{ width: '98%', borderRadius: '5px' }}>Edit User »</button>
                    </form>
                  </div>

                </div>
              </div>
            </div>




            {/* delete user */}
            {show ? (
              <Modal show={show} onHide={handleClose} animation={false} >
                <Modal.Header closeButton>
                  <Modal.Title style={{ fontSize: '14px' }}>Delete User</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ color: 'red' }}>
                  {deleted ? (
                    <p className="text-success">User deleted sucessfully !!</p>
                  ) : <p>Are you sure you want to delete this user?</p>}

                </Modal.Body>
                <Modal.Footer>
                  <Button variant="danger" onClick={deleteUser}>
                    Yes
                  </Button>
                  <Button variant="secondary" onClick={handleClose}>
                    No
                  </Button>
                </Modal.Footer>
              </Modal>
            ) : null}


            {/* as usual  */}
            <MyVerticallyCenteredModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />

            {/* add user modal */}
            {addModalShow ? (
              <AddModal
                show={addModalShow}
                onHide={() => setAddModalShow(false)}
                toggleChange={toggleChange}
                setToggleChange={setToggleChange}
              />
            ) : null}


            {/* user edit modal */}
            {editTable ? (
              <EditTable
                show={editTable}
                onHide={() => setEditTable(false)}
                userNameForEdit={userNameForEdit}
                toggleChange={toggleChange}
                setToggleChange={setToggleChange}
              />
            ) : null}


            {/* {editModalShow ? (
        <EditTable
          show={editModalShow}
          onHide={() => setEditModalShow(false)}
          userNameForEdit={userNameForEdit}
          toggleChange={toggleChange}
          setToggleChange={setToggleChange}
        />
      ) : null} */}


            <DeactivateModal
              show={deactivateModalShow}
              onHide={() => setDeactivateModalShow(false)}
            />

            <ExpModal
              show={expModal}
              onHide={() => setExpModal(false)}
            />


            {/* <EventsModal
        show={eventsModal}
        onHide={() => setEventsModal(false)}
      /> */}


            <SendNotificationModal
              show={notificationShow}
              onHide={() => setNotificationShow(false)}
            />

            <CustomerViewModal
              show={viewModal}
              onHide={() => setViewModal(false)}
            />

            {customerEvent ? (
              <CustomerEvents
                show={customerEvent}
                onHide={() => setCustomerEvent(false)}
                toggleChange={toggleChange}
                setToggleChange={setToggleChange}
                userNameForEvent={userNameForEvent}
              />
            ) : null}

          </Fragment >
        </>
      )}
    </>

  );
});

TableData.displayName = "TableData";
export default TableData;