import React, { useState } from "react"
import { Modal, Card, Button } from 'react-bootstrap';
import './Coins.css';

function PaymentModal(props) {
    const [showSuccess, setShowSuccess] = useState(false);

    const handlePay = () => {
        // Logic for payment success can go here
        // For demonstration, I'll just set showSuccess to true
        setShowSuccess(true);
    };

    const handleClose = () => {
        setShowSuccess(false);
        // Additional logic if needed when closing the success popup
    };
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                style={{ marginLeft: '7%' }}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '15px' }}>
                        Payment Method
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div class="row">
                        <div class="col-3">Currency</div>
                        <div class="col-6"><select class="form-select" aria-label="Default select example" style={{ minWidth: '100px' }}>
                            <option selected>INR</option>
                            <option value="1">USD</option>
                        </select></div>
                    </div>
                    <div class="row" style={{ marginTop: "5px" }}>
                        <div class="col-3">Number of coins</div>
                        <div class="col-6"><select class="form-select" aria-label="Default select example" style={{ minWidth: '100px' }}>
                            <option selected>1 coins</option>
                            <option value="1">2 coins</option>
                        </select></div>
                    </div>
                    <div class="row" style={{ marginTop: "5px" }}>
                        <div class="col-3">Amount</div>
                        <div class="col-6">    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                    </div>
                    <div class="row" style={{ marginTop: "5px" }}>
                        <div class="col-3">Payment method</div>
                        <div class="col-6">
                            <div class="" style={{ width: '40%' }}>
                                <div class="d-flex" style={{ minHeight: '20px', width: '30px' }}>
                                    <img src={require('./images/download.png')} style={{ width: '100px', border: '1px solid #F5F5F5', padding: '5px', cursor: 'pointer' }} />
                                    <img src={require('./images/rf.png')} style={{ width: '100px', border: '1px solid #F5F5F5', padding: '6px', cursor: 'pointer', marginLeft: '7px' }} />
                                </div>
                            </div>
                        </div>
                        <span style={{ marginLeft: "25%", marginTop: '7px' }}>(1 coins = 1 INR/1 USD)</span>
                    </div>
                    <div class="row" style={{ marginTop: "25px" }}>
                        <button type="button" class=" btn btn-primary" onClick={handlePay}>Pay</button>

                    </div>

                </Modal.Body>


            </Modal>

            <Modal show={showSuccess} onHide={handleClose} style={{ marginLeft: '-6%', minWidth:'200px' }} >
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: 'green' }}>Payment Successful!!!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="carding">
                        <div style={{borderRadius:'200px', height:'200px', width:'200px', background: '#F8FAF5',margin:'0 auto'}}>
                            <i class="checkmark">✓</i>
                        </div>
                        <h1>Success</h1>
                        <p>"Payment Successful! Your transaction has been processed successfully."<br />  Thank you for your purchase.</p>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    );
}

export default PaymentModal;