import React, { useState } from 'react'
import { CiSearch } from "react-icons/ci";
import { Table, } from "react-bootstrap";
import { CiImport } from "react-icons/ci";
import { MdAutorenew } from "react-icons/md";
import CoinsModal from './CoinsModal';
import { LiaBookOpenSolid } from "react-icons/lia";

const Statement = () => {
  const coinBalance = JSON.parse(localStorage.getItem('loginInfo'))?.coin;
  console.log(coinBalance)

  return (
    <div>
      <div class="card" style={{ marginTop: '-15px' }}>
        <div class="card-body">
          <h6><img src={require('./images/coins.png')} style={{ width: '5%' }} />{" "}{" "}Mi Coins Balance : {coinBalance}</h6>
          <Table striped hover size="sm" style={{ marginTop: '30px' }}>
            <thead>
              <tr>
                <th style={{ paddingRight: '0px', paddingLeft: '10px' }}>S.No</th>
                <th>Date</th>
                <th>Type</th>
                <th>IMEI</th>
                <th>Narration</th>
                <th>Debit</th>
                <th>Credit</th>
                <th> Balance</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ marginLeft: '50px' }}>1</td>
                <td>10-12-2023</td>
                <td>Pytm</td>
                <td>6754565434231</td>
                <td>Provident fund </td>
                <td>500.00</td>
                <td>500.00</td>
                <td>1000.</td>


              </tr>



            </tbody>
          </Table>

        </div>
      


      </div>

    </div>
  )
}

export default Statement;
