import React, { useEffect, useState } from 'react'
import { CiSearch } from "react-icons/ci";
import { Table, } from "react-bootstrap";
import { CiImport } from "react-icons/ci";
import { MdAutorenew } from "react-icons/md";
import CoinsModal from './CoinsModal';
import { LiaBookOpenSolid } from "react-icons/lia";
import axios from 'axios';
import NewLoader from '../speedo-asset/Loader';



const Coins = () => {
    const [modalShow, setModalShow] = useState(false);
    const [coin, setCoin] = useState(1);
    const [currency, setCurrency] = useState("Select One");

    const coinBalance = JSON.parse(localStorage.getItem('loginInfo'))?.coin;


    //data
    const [passBookList, setPassBookList] = useState([]);

    //pagination
    const [isLoading, setIsLoading] = useState(false)
    const oneMonthAgo = new Date()
    const [startDate, setStartDate] = useState(oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1))
    const [endDate, setEndDate] = useState(new Date())




    //function for fetching the statement list
    const fetchStatementList = async () => {
        try {
            setIsLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", localStorage.getItem('token'));

            var raw = JSON.stringify({
                "from": startDate,
                "to": endDate
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch("https://gps.speedotrack.com/api/coin/transaction", requestOptions)
            const result = await response.json()
            console.log(result?.passbook)
            setPassBookList(result?.passbook)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchStatementList()
    }, [startDate, endDate])

    const formatDate = (date) => {
        const formattedDate = date.toISOString().slice(0, 16);
        return formattedDate;
    };







    return (
        <div>
            <div class="card" style={{ marginTop: '-15px' }}>
                <div class="card-body">
                    <h6><img src={require('./images/coins.png')} style={{ width: '5%' }} />{" "}{" "} Coin Balances : {coinBalance}</h6>
                    <div class="form-check d-flex" style={{ marginTop: '20px' }}>
                        <span>Search by:</span>
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '13px' }} />{" "}
                        <label class="form-check-label" for="flexCheckDefault" style={{ marginLeft: "5px" }}>
                            Order
                        </label>
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '13px' }} />{" "}
                        <label class="form-check-label" for="flexCheckDefault" style={{ marginLeft: "5px" }}>
                            Details
                        </label>
                    </div>
                    <div class="d-flex" style={{ marginTop: '15px' }}>
                        <span style={{ marginLeft: '20px' }}>Operation time:</span>

                        <input
                            class="form-control"
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            style={{ marginLeft: '20px', width: '20%' }}
                            value={startDate ? new Date(startDate).toISOString().slice(0, 16) : ''}
                            onChange={(e) => { setStartDate(e.target.value) }}
                        />

                        <input
                            class="form-control"
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            style={{ marginLeft: '10px', width: '20%' }}
                            value={endDate ? new Date(endDate).toISOString().slice(0, 16) : ''}
                            onChange={(e) => { setEndDate(e.target.value) }}
                        />


                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Search..." style={{ marginLeft: '8px', width: '20%' }} />
                        <button type="button" class="btn btn-primary btn-sm" style={{ borderRadius: '5px', marginLeft: '5px', width: '30px' }}><CiSearch style={{ fontSize: '15px' }} /></button>
                        <button type="button" class="btn btn-primary btn-sm" style={{ marginLeft: "5px", borderRadius: '5px', width: '10%' }} onClick={() => setModalShow(true)}><MdAutorenew /> Renew Device </button>
                        <div className="table-responsive" style={{ marginTop: '10px' }}>
                        </div>
                    </div>
                    {isLoading ? (
                        <div className='w-100 my-5' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <NewLoader />
                        </div>
                    ) : (
                        <Table striped hover size="sm" style={{ marginTop: '30px' }}>
                            <thead>
                                <tr>
                                    <th style={{ paddingRight: '0px', paddingLeft: '10px' }}>S.No</th>
                                    <th style={{ paddingRight: '0px', }} ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></th>
                                    <th>Date</th>
                                    {/* <th>Username</th>
                                <th>Type	</th>
                                <th>IMEI</th> */}
                                    <th>Narration</th>
                                    <th>Debit</th>
                                    <th>Credit</th>
                                    {/* <th>Amount</th> */}
                                    <th>Balance</th>
                                </tr>
                            </thead>

                            <tbody>

                                {passBookList?.map((statement, index) => (
                                    <tr>
                                        <td style={{ marginLeft: '50px' }}>{index + 1}</td>
                                        <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                                        <td>{statement?.date}</td>
                                        <td>{statement?.message}</td>
                                        <td>{statement?.debit ? statement?.debit : ''}</td>
                                        <td>{statement?.credit ? statement?.credit : ''}</td>
                                        <td>{statement?.balance}</td>
                                        {/* <td>20 Coins</td> */}
                                    </tr>
                                ))}



                            </tbody>

                        </Table>
                    )}

                </div>

                {modalShow ? (
                    <CoinsModal
                        coinBalance={coinBalance}
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                ) : null}


            </div>

        </div>
    )
}

export default Coins;
