import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CiSearch } from "react-icons/ci";
import { Table, } from "react-bootstrap";
import { useEffect, useState } from 'react';
import { IoAdd } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import Multiselect from 'multiselect-react-dropdown';


function DeviceRenew(props) {
  const coinBalance = JSON.parse(localStorage.getItem('loginInfo')).coin;
  const [selectedPlan, setSelectedPlan] = useState('0')
  const [planTime, setPlanTime] = useState('')
  const [noOfCoins, setNoOfCoins] = useState(0)
  const [renewImei, setRenewImei] = useState([]);



  const funcFetchTranslation = async () => {
    try {
      var myHeaders = new Headers();

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      const response = await fetch("https://gps.speedotrack.com/api/coin/translation", requestOptions)
      const result = await response.json()
      props.setTranslationList(result?.translations)
      console.log(result)

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (props.translationList?.length <= 0) {
      funcFetchTranslation()
    }
  }, [])


  useEffect(() => {
    const imeiList = props.renewDeviceList.map(device => device.imei);
    setRenewImei(imeiList);
  }, [props.renewDeviceList])

  useEffect(() => {
    const totalCoins = renewImei.length * parseInt(selectedPlan)
    setNoOfCoins(totalCoins)

  }, [selectedPlan])


  const funcRenewDevices = async () => {
    try {
      var myHeaders = new Headers();

      var formdata = new FormData();
      formdata.append("cmd", "renew");
      formdata.append("devices", renewImei);
      formdata.append("total_coins", noOfCoins);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      const response = await fetch("https://gps.speedotrack.com/api/cpanel/device/bulk", requestOptions)
      const result = await response.json()
      console.log(result)
      if (response.ok) {
        props.onHide()
      }
    } catch (error) {
      console.log(error)
    }
  }


  const funcRemoveVehicle = async (imei) => {
    const newArray = props.renewDeviceList.filter(vehicle => vehicle.imei !== imei);
    props.setRenewDeviceList(newArray)
  }


  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ marginLeft: "10%" }}
      backdrop="static"
    >

      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '14px' }}>
          Device Renew - {coinBalance} coins
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="d-flex">
          <span style={{ marginTop: '7px' }}>Plans :</span>
          <select class="form-select" aria-label="Default select example"
            style={{ width: '15%', marginLeft: '8px' }}
            value={selectedPlan}
            onChange={(e) => { setSelectedPlan(e.target.value) }}
          >
            <option value={'0'}>select plan</option>

            {props.translationList?.map((data, index) => (
              <option key={index} value={data.coins}>{data?.for}-{data?.coins}/{data?.time}</option>
            ))}

          </select>
          <span style={{ marginLeft: '10px', marginTop: '7px' }}>Account:</span>

          {/* <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Search..." style={{ marginLeft: '8px', width: '20%' }} /> */}

          <Multiselect
            displayValue="key"
            closeOnSelect={true}
            onKeyPressFn={function noRefCheck() { }}
            onRemove={function noRefCheck() { }}
            onSearch={function noRefCheck() { }}
            onSelect={function noRefCheck() { }}
            selectionLimit={1}
            options={[
              {
                cat: 'Group 1',
                key: 'User 1'
              },
              {
                cat: 'Group 1',
                key: 'User 2'
              },
            ]}

            style={{
              searchBox: {
                marginTop: '0px',
                width: '100px'
              },
              chips: {
                background: 'none',
                border: 'none',
                color: '#3a4659',
              },
            }}
          />



          <button type="button" class="btn btn-primary btn-sm" style={{ width: '40px' }}><CiSearch style={{ fontSize: '15px' }} /></button>

          {/* <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="IMEI" style={{ marginLeft: '8px', width: '20%' }} /> */}
          <Multiselect
            displayValue="key"
            closeOnSelect={true}
            onKeyPressFn={function noRefCheck() { }}
            onRemove={function noRefCheck() { }}
            onSearch={function noRefCheck() { }}
            onSelect={function noRefCheck() { }}
            selectionLimit={1}
            options={[
              {
                cat: 'Group 1',
                key: 'User 1'
              },
              {
                cat: 'Group 1',
                key: 'User 2'
              },
            ]}

            style={{
              searchBox: {
                marginTop: '0px',
                width: '100px'
              },
              chips: {
                background: 'none',
                border: 'none',
                color: '#3a4659',
              },
            }}
          />


          <button type="button" class="btn btn-primary btn-sm" style={{ width: '40px' }}><IoAdd style={{ fontSize: '15px' }} /></button>
          <button type="button" class="btn btn-primary btn-sm" style={{ marginLeft: "5px", borderRadius: '5px', width: '8%' }}> Search </button>
          <button type="button" class="btn btn-primary btn-sm" style={{ marginLeft: "5px", borderRadius: '5px', width: '8%' }} > Reset </button>
        </div>
        <div className="table-responsive" style={{ marginTop: '20px' }}>
          <Table striped hover size="sm">
            <thead>
              <tr>
                <th style={{ paddingRight: '0px', paddingLeft: '10px' }}>S.No</th>
                <th style={{ paddingRight: '0px', }} ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></th>
                <th>Device</th>
                <th>IMEI</th>
                <th>Subscription Expire</th>
                {/* <th>Service Type</th> */}
                {/* <th>Unit Price</th> */}
                <th>Action</th>

              </tr>
            </thead>
            <tbody>

              {props?.renewDeviceList?.map((device, index) => (
                <tr>
                  <td style={{ marginLeft: '50px' }}>{index + 1}</td>
                  <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                  <td>{device?.name}</td>
                  <td>{device?.imei}</td>
                  <td>{device?.expire_dt}</td>
                  {/* <td>Expired</td> */}
                  {/* <td>Renew(10 Year)</td> */}
                  {/* <td>300</td> */}
                  <td><RiDeleteBin6Line
                    style={{ cursor: 'pointer', color: 'red' }}
                    onClick={() => { funcRemoveVehicle(device?.imei) }}
                  /></td>
                </tr>
              ))}

            </tbody>
          </Table>
          <div class="d-flex">
            <span>Remarks:</span>
            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Remark" style={{ marginLeft: '40px' }} />
          </div>

        </div>
      </Modal.Body>
      <div>
        <Modal.Footer>
          <h6>Total: {noOfCoins} coins</h6>
          <Button onClick={props.onHide} class="btn btn-secondary">Cancel</Button>
          <Button onClick={funcRenewDevices}
            disabled={parseInt(noOfCoins) === 0 ? true : false}
          >Confirm</Button>

        </Modal.Footer>
      </div>

    </Modal>
  );
}



export default DeviceRenew;