import React, { useEffect, useState } from 'react';
import { Button, Tab, Nav, ButtonGroup, Card } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';


import { Routes, Route, Link, NavLink } from 'react-router-dom';
// import Card from '../../../components/bootstrap/card';



//importing of the componenets
import ObjectSetting from './ObjectSetting';
import EventSetting from './EventSetting';
import TemplateSetting from './TemplateSetting';
import SmsSetting from './SmsSetting';
import Loader from '../speedo-asset/Loader';

import UserInterfaceSetting from '../ModalComponents/UserInterfaceSetting';
// import MyAccountSetting from './MyAccountSetting';
// import SubAccountSetting from './SubAccountSetting';

function EditTable(props) {
  const [viewDeviceRoute, setViewDeviceRoute] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [deviceData, setDeviceData] = useState({})
  const [patchData, setPatchData] = useState({});



  useEffect(() => {
    //fetching a specific device
    const getDevice = async (imei) => {
      try {
        setIsLoading(true)

        var myHeaders = new Headers();
        myHeaders.append("Authorization", localStorage.getItem('token'));
        var requestOptions = {
          method: 'GET',
          redirect: 'follow',
          headers: myHeaders
        };
        const response = await fetch(`https://gps.speedotrack.com/api/cpanel/device/${imei}`, requestOptions)
        const result = await response.json();
        setDeviceData(result);
        console.log(result)
        setIsLoading(false)
      } catch (error) {
        console.log(error)
        setIsLoading(false)
      }
    }

    getDevice(props.selectedDeviceImei)
  }, [props.toggleChange])


  //function of editing the device
  const editDevice = async () => {
    // setModalShow(true)
    try {
      console.log("this is patch data", patchData)
      setIsLoading(true)

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", localStorage.getItem('token'));


      var raw = JSON.stringify(patchData);

      var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/cpanel/device/${deviceData.device.imei}`, requestOptions)
      const result = await response.json()
      console.log(result)
      setIsLoading(false)
      props.setToggleChange(!props.toggleChange)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      top
      style={{ marginLeft: '10%' }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '14px' }}>
          View Device
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div>
          {/* <Card style={{ borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }}>  */}
          <div className=" topnav mx-auto w-100 z-index-5 d-flex align-items-center justify-content-center">

            <ButtonGroup className="btn-group w-100" >
              <NavLink>
                <Button variant="link" className={`btn btn-link btn-lg ${viewDeviceRoute === '' ? ' active' : ''}`} data-bs-toggle="tooltip" title="Objects setting."
                  style={{ fontSize: '14px' }}
                  onClick={() => setViewDeviceRoute('')}
                >General</Button>

              </NavLink>
              <NavLink>
                <Button variant="link" className={`btn btn-link btn-lg ${viewDeviceRoute === 'DETAILED' ? ' active' : ''}`} data-bs-toggle="tooltip" title="Events setting."
                  style={{ fontSize: '14px' }}
                  onClick={() => setViewDeviceRoute('DETAILED')}
                >Detailed</Button>
              </NavLink>
              <Link>
                <Button variant="link" className={`btn btn-link btn-lg ${viewDeviceRoute === 'USERS' ? ' active' : ''}`} data-bs-toggle="tooltip" title="Templates setting"
                  style={{ fontSize: '14px' }}
                  onClick={() => setViewDeviceRoute('USERS')}
                >Users </Button>
              </Link>
              <Link>
                <Button variant="link" className={`btn btn-link btn-lg ${viewDeviceRoute === 'INFO' ? ' active' : ''}`} data-bs-toggle="tooltip" title="Templates setting"
                  style={{ fontSize: '14px' }}
                  onClick={() => setViewDeviceRoute('INFO')}
                >Info </Button>
              </Link>
              <Link>
                <Button variant="link" className={`btn btn-link btn-lg ${viewDeviceRoute === 'ICON' ? ' active' : ''}`} data-bs-toggle="tooltip" title="Templates setting"
                  style={{ fontSize: '14px' }}
                  onClick={() => setViewDeviceRoute('ICON')}
                >Icon </Button>
              </Link>


            </ButtonGroup>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {
                viewDeviceRoute === "DETAILED" ? (
                  <EventSetting
                    deviceData={deviceData}
                    editDevice={editDevice}
                    patchData={patchData}
                    setPatchData={setPatchData}
                  />
                ) : viewDeviceRoute === "USERS" ? (
                  <TemplateSetting
                    deviceData={deviceData}
                    editDevice={editDevice}
                    patchData={patchData}
                    setPatchData={setPatchData}
                  />
                ) : viewDeviceRoute === "INFO" ? (
                  <SmsSetting
                    deviceData={deviceData}
                    editDevice={editDevice}
                    patchData={patchData}
                    setPatchData={setPatchData}
                  />
                ) : viewDeviceRoute === "ICON" ? (
                  <UserInterfaceSetting
                    deviceData={deviceData}
                    editDevice={editDevice}
                    patchData={patchData}
                    setPatchData={setPatchData}
                  />
                ) : (
                  <ObjectSetting
                    deviceData={deviceData}
                    editDevice={editDevice}
                    patchData={patchData}
                    setPatchData={setPatchData}
                  />
                )
              }



              {deviceData && deviceData.device && deviceData.device.icon && (
                <img src={deviceData.device.icon} alt="Device Icon" />
              )}

            </>
          )}
          {/* </Card> */}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ paddingBottom: '20px' }}>

      </Modal.Footer>
    </Modal >
  );
}
export default EditTable;

