import { useEffect, useState } from 'react';
import { Button, Offcanvas, Card, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
// import Button from 'react-bootstrap/Button';
// import Offcanvas from 'react-bootstrap/Offcanvas';
import Chart from "react-apexcharts";
// import { Button, Col } from 'react-bootstrap'
import "./Monitor.css";
import { RiArrowUpSFill } from "react-icons/ri";
import { CiCreditCard2 } from "react-icons/ci";
import { CiHeadphones } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { IoIosClose } from "react-icons/io";
import { CiDeliveryTruck } from "react-icons/ci";
// import ReactSpeedometer from "react-d3-speedometer";
import { LuFuel } from "react-icons/lu";
import { TbSettingsStar } from "react-icons/tb";
import { RiLoader2Line } from "react-icons/ri";
import { TbRouteAltRight } from "react-icons/tb";
import { SiGoogleearthengine } from "react-icons/si";
import { SlSpeedometer } from "react-icons/sl";
import { GoStop } from "react-icons/go";
import { PiEngineLight } from "react-icons/pi";
import { LiaTruckMovingSolid } from "react-icons/lia";
import { SiBookmeter } from "react-icons/si";
import { TbClockHour3 } from "react-icons/tb";
import { HiOutlineWrenchScrewdriver } from "react-icons/hi2";
import { FaTrailer } from "react-icons/fa6";
const MonitorDataBar = ({ name, ...props }) => {

  const vehicleData = useSelector(state => state.generalTracking.generalData)




  const closeCard = () => {
    props.setShowMonitorDataBar(false)
  };

  const [isCardOpen, setIsCardOpen] = useState(false);

  const openCard = () => {
    setIsCardOpen(true); // Set the state to open the card
  };



  return (

    <div>
      {props.showMonitorDataBar && (
        <div>
          <div style={{
            position: "fixed", top: '-58px', right: '-5px', marginBottom: '5px', 
          }}>
            <div className="container " style={{ height: '10px' }}>
              <section className="mx-auto my-5 " style={{ width: '20rem', height: '10px', overflowX: '' }}>
                <div className="card" style={{ overflowX: 'scroll', height: '99.5vh', }}>
                  <div class="row">
                    <div class="col-6" style={{ marginTop: '20px', marginLeft: '10px' }}> <h6 className="card-title font-weight-bold mb-2" style={{}}><CiDeliveryTruck style={{ fontSize: '30px' }} /> <b>{vehicleData?.object}</b></h6></div>
                    <div class="col-4"><button type="button" className="btn-close" aria-label="Close" style={{ marginTop: '20px', marginLeft: '70px' }} onClick={closeCard}></button></div>
                  </div>
                  <div className="card-body d-flex flex-row">

                    {/* <div>
                      <p className="card-text" style={{ fontSize: '13px', color: 'green', marginTop: '-30px', marginLeft:'16px',  }}><img src={require('./images/clock.png')} style={{ width: '13px' }} /> {" "}{vehicleData?.route_length}</p>
                    </div> */}


                  </div>
                  <div className="bg-image hover-overlay ripple rounded-0" data-mdb-ripple-color="light" style={{ marginTop: '-85%', width: '80%', marginLeft: '20px' }} >
                    <img className="img-fluid" src={require('./images/car.png')}
                      alt="Card image cap" style={{ marginTop: '-26%' }} />  <hr style={{ marginTop: '-18%' }}></hr>
                  </div>

                  <div style={{ marginTop: '-5px', marginLeft: '15px' }}>
                    <div style={{
                      color: '#2E384D',
                      fontSize: '11px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      lineHeight: '22px',
                      color: 'green'

                    }}>
                      Location
                    </div>
                    <span style={{
                      color: '#8798AD',
                      fontSize: '11px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '22px',
                      marginTop: '-5px',
                      padding: '5px',

                    }}>Ranchi Jharkhand sector || market H.E.C colony old  <br></br>
                      <span style={{ marginLeft: '6px' }}>  vidhan sabha dhuruwa..</span></span>
                    <span><div className="d-flex" style={{ marginTop: '6px' }}>
                      <div className="p-2 flex-fill" style={{
                        color: 'green',
                        fontSize: '11px',
                        fontWeight: '700',

                        fontStyle: 'normal', marginLeft: '-2px'
                      }}>Time (Position)</div>
                      <div className="p-2 flex-fill" style={{
                        color: 'green',
                        fontSize: '11px',
                        fontStyle: 'normal',
                        fontWeight: '700',

                      }}>648.01</div>

                    </div></span>

                    <span><div className="d-flex" style={{ marginTop: '-8px', }}>
                      <div className="p-2 flex-fill" style={{
                        color: 'green',
                        fontSize: '11px',
                        fontWeight: '700',

                        fontStyle: 'normal', marginLeft: ''
                      }}>Time (Server)</div>
                      <div className="p-2 flex-fill" style={{
                        color: 'green',
                        fontSize: '11px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        marginLeft: '25px'

                      }}>{vehicleData?.driver}</div>

                    </div></span>

                    <span><div className="d-flex" style={{ marginTop: '-8px' }}>
                      <div className="p-2 flex-fill" style={{
                        color: 'green',
                        fontSize: '11px',
                        fontWeight: '700',

                        fontStyle: 'normal', marginLeft: '1px'
                      }}>Driver</div>
                      <div className="p-2 flex-fill" style={{
                        color: 'green',
                        fontSize: '11px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        marginLeft: '35px'

                      }}><a href="#" onClick={openCard}>SpeedoTrack</a></div>

                    </div></span>




                  </div>



                  <div className="d-flex" style={{ marginTop: '6px', }}>
                    <div className="p-2 flex-fill" style={{
                      color: 'green',
                      fontSize: '11px',
                      fontWeight: '700',

                      fontStyle: 'normal', marginLeft: '10px'
                    }}>{vehicleData?.route_length}</div>
                    <div className="p-2 flex-fill" style={{
                      color: 'green',
                      fontSize: '11px',
                      fontStyle: 'normal',
                      fontWeight: '700',

                    }}>{vehicleData?.fuel_consumption}</div>
                    <div className="p-2 flex-fill" style={{
                      color: 'green',
                      fontSize: '11px',
                      fontStyle: 'normal',
                      fontWeight: '700',

                    }}>{vehicleData?.move_duration?.slice(0, 12)}</div>
                  </div>

                  <div className="d-flex">
                    <div className="p-2 flex-fill" style={{
                      fontSize: '11px',
                      marginTop: '-12px',
                      fontStyle: 'normal', marginLeft: '10px'
                    }}>Distance</div>
                    <div className="p-2 flex-fill" style={{
                      fontSize: '11px',
                      marginTop: '-12px',
                      fontStyle: 'normal', marginLeft: '-10px'
                    }}>fuel consumed</div>
                    <div className="p-2 flex-fill" style={{
                      fontSize: '11px',
                      marginTop: '-12px',
                      fontStyle: 'normal', marginLeft: ''
                    }}>Time</div>
                  </div>






                  <hr style={{ marginTop: "4px", width: '290px' }}></hr>



                  <div style={{ display: 'flex', marginTop: '20px' }}>
                    <Card style={{ border: 'none', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '150px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '10px',
                      }}>
                       <LuFuel  style={{
                          width: '20px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '20px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '8px'
                        }}>Avg. Fuel
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.avg_fuel_consumption}</span></span>
                      </div>
                    </Card>{" "}
                    <Card style={{ border: 'none', marginLeft: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '110px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '15px', marginRight: '15px',
                      }}>
                       <TbSettingsStar  style={{
                          width: '18px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '18px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '10px'
                        }}>Fuel Cons...
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.fuel_consumption}</span></span>
                      </div>
                    </Card>
                  </div>



                  <div style={{ display: 'flex', marginTop: '-18px' }}>
                    <Card style={{ border: 'none', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '150px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '10px',
                      }}>
                       <RiLoader2Line  style={{
                          width: '20px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '20px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '8px'
                        }}>Stop Duration
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.avg_fuel_consumption}</span></span>
                      </div>
                    </Card>{" "}
                    <Card style={{ border: 'none', marginLeft: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '110px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '15px', marginRight: '15px',
                      }}>
                       <TbRouteAltRight  style={{
                          width: '18px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '18px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '10px'
                        }}>Route Length
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.fuel_consumption}</span></span>
                      </div>
                    </Card>
                  </div>



                  <div style={{ display: 'flex', marginTop: '-18px' }}>
                    <Card style={{ border: 'none', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '150px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '10px',
                      }}>
                       <SiGoogleearthengine  style={{
                          width: '20px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '20px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '8px'
                        }}>Engine Work
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.avg_fuel_consumption}</span></span>
                      </div>
                    </Card>{" "}


                    <Card style={{ border: 'none', marginLeft: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '110px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '15px', marginRight: '15px',
                      }}>
                       <SlSpeedometer  style={{
                          width: '18px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '18px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '10px'
                        }}>Top Speed
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.top_speed}</span></span>
                      </div>
                    </Card>



                  </div>

                  <div style={{ display: 'flex', marginTop: '-18px' }}>
                    <Card style={{ border: 'none', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '150px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '10px',
                      }}>
                       <GoStop  style={{
                          width: '20px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '20px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '8px'
                        }}>Stop Count
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.stop_count}</span></span>
                      </div>
                    </Card>{" "}
                    <Card style={{ border: 'none', marginLeft: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '110px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '15px', marginRight: '15px',
                      }}>
                       <PiEngineLight  style={{
                          width: '18px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '18px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '10px'
                        }}>Engine Idle
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.engine_idle}</span></span>
                      </div>
                    </Card>



                  </div>

                  {/* move duration fuel cost  */}

                  <div style={{ display: 'flex', marginTop: '-18px' }}>
                    <Card style={{ border: 'none', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '150px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '10px',
                      }}>
                       <LiaTruckMovingSolid  style={{
                          width: '20px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '20px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '8px'
                        }}>Move Duration
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.move_duration}</span></span>
                      </div>
                    </Card>{" "}
                    <Card style={{ border: 'none', marginLeft: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '110px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '15px', marginRight: '15px',
                      }}>
                          <LuFuel  style={{
                          width: '18px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '18px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '10px'
                        }}>Fuel Cost
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.fuel_cost}</span></span>
                      </div>
                    </Card>



                  </div>


                  {/* ododmeter and engine hour  */}

                  <div style={{ display: 'flex', marginTop: '-18px' }}>
                    <Card style={{ border: 'none', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '150px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '10px',
                      }}>
                       <SiBookmeter  style={{
                          width: '20px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '20px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '8px'
                        }}>Odometer
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.odometer}</span></span>
                      </div>
                    </Card>{" "}
                    <Card style={{ border: 'none', marginLeft: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '110px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '15px', marginRight: '15px',
                      }}>
                        <TbClockHour3  style={{
                          width: '18px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '18px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '10px'
                        }}>Engine Hours
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.engine_hours}</span></span>
                      </div>
                    </Card>



                  </div>



                  {/*driver and trailer  */}

                  <div style={{ display: 'flex', marginTop: '-18px' }}>
                    <Card style={{ border: 'none', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '150px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '10px',
                      }}>
                       <HiOutlineWrenchScrewdriver  style={{
                          width: '20px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '20px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '8px'
                        }}>Driver
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.driver}</span></span>
                      </div>
                    </Card>{" "}
                    <Card style={{ border: 'none', marginLeft: '4px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '110px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '15px', marginRight: '15px',
                      }}>
                      <FaTrailer  style={{
                          width: '18px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '18px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '10px'
                        }}>Trailer
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.trailer}</span></span>
                      </div>
                    </Card>



                  </div>


                  {/*driver and trailer  */}

                  {/* <div style={{ display: 'flex', marginTop: '-18px' }}>
                    <Card style={{ border: 'none' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '150px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '10px',
                      }}>
                        <img src={require('./images/truck.png')} style={{
                          width: '20px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '20px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '8px'
                        }}>Avg. Speed
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData.avg_speed}</span></span>
                      </div>
                    </Card>{" "}
                    <Card style={{ border: 'none', marginLeft: '4px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '110px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '15px', marginRight: '15px',
                      }}>
                        <img src={require('./images/uptime.png')} style={{
                          width: '18px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '18px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '10px'
                        }}>Overspeed Count
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData.overspeed_count}</span></span>
                      </div>
                    </Card>



                  </div> */}


                  {/*averege  and trailer  */}

                 
                </div>
              </section>
            </div>
          </div>
        </div>
      )}

      {isCardOpen && (
        <div
          className="card"
          style={{
            position: 'fixed',
            top: '10px',
            right: '5px',
            width: '20rem',
            borderRadius: '10px',
          }}
        >
          <div className="card-body" style={{ borderRadius: '10px' }}>
            <div class=" row bg-primary" style={{ marginTop: '-20px', marginLeft: '-20px', marginRight: '-10px' }}>
              <div class="col-8">  <h5 className="card-title " style={{ padding: '10px', color: 'white', marginTop: '5px', marginLeft: '-21px', marginRight: '-21px' }}>Drive information</h5></div>
              <div class="col-4 "><div class=" bg-primary"><IoIosClose style={{ marginLeft: '30%', fontSize: '20px', cursor: 'pointer', marginTop: '15px', color: 'white' }} onClick={closeCard} /></div>
              </div>
            </div>


            <div class="row">
              <div class="col-6"><img src={require('./images/user-blank.png')} style={{ width: '80%' }} /></div>
              <div class="col-6" style={{ marginTop: '40px' }}>SpeedoTrack</div>
            </div>
            <div class="row">
              <div class="col-6"><CiCreditCard2 style={{ fontSize: '18px' }} />{" "}ID number:</div>
              <div class="col-6" style={{ marginTop: '' }}>123456789</div>
            </div><hr></hr>
            <div class="row">
              <div class="col-6"><CiHeadphones style={{ fontSize: '18px' }} /> Phone:</div>
              <div class="col-6" style={{ marginTop: '' }}>+123456789</div>
            </div><hr></hr>
            <div class="row">
              <div class="col-6"><CiMail style={{ fontSize: '18px' }} /> E-mail:</div>
              <div class="col-6" style={{ marginTop: '' }}>email@email.com</div>
            </div><hr></hr>
            <div class="row">
              <div class="col-6"><CiDeliveryTruck style={{ fontSize: 'px' }} /> Truck driver:</div>
              <div class="col-6" style={{ marginTop: '' }}>Truck driver</div>
            </div>
          </div>
        </div>
      )}
    </div>





  )
}

export default MonitorDataBar;