import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { MdOutlineSensors } from "react-icons/md";
import CallibrationDeviceModal from './CallibrationDeviceModal';

import NewLoader from '../speedo-asset/Loader';




function EditDeviceModal(props) {

  const [isLoading, setIsLoading] = useState(false)
  const [callibrationModal, setCallibrationModal] = useState(false);
  const [sensorTemplate, setSensorTemplate] = useState([]);
  const [sensorParamList, setSensorParamList] = useState([])

  //states for holding the input sensor values.
  const [sensorName, setSensorName] = useState('')
  const [sensorType, setSensorType] = useState({})
  const [sensorOneText, setSensorOneText] = useState('')
  const [sensorZeroText, setSensorZeroText] = useState('')
  const [sensorParam, setSensorParam] = useState('')
  const [sensorResultType, setSensorResultType] = useState('')
  const [sensorDataList, setSensorDataList] = useState(true)
  const [sensorPopup, setSensorPopup] = useState(true)
  const [sensorAccIgnore, setSensorAccIgnore] = useState(true)
  const [sensorUnit, setSensorUnit] = useState('')
  const [sensorLowestValue, setSensorLowestValue] = useState('')
  const [sensorHighestValue, setSensorHighestValue] = useState('')
  const [sensorFormula, setSensorFormula] = useState('')
  const [sensorCalibration, setSensorCalibration] = useState([]);
  const [sensorTypesList, setSensorTypesList] = useState([]);
  const [resultTypeList, setResultTypeList] = useState([]);
  const [resultConfigList, setResultConfigList] = useState([])

  //enable disable according to configuration
  const [unitsDisable, setUnitDisable] = useState(true);
  const [formulaDisable, setFormulaDisable] = useState(false);
  const [lowestValueDisable, setLowestValueDisable] = useState(false);
  const [highestValueDisable, setHighestValueDisable] = useState(false);


  // const sensorTypesList = ['battery', 'digital_input', 'digital_output', 'driver_assign', 'engine_hour', 'fuel_level', 'fuel_level_sum_up', 'fuel_consumption', 'GSM_level', 'GPS_level', 'ignition', 'odometer'];
  const parameterList = ['acc', 'alarm', 'batl', 'bats', 'cellid', 'defence', 'defense', 'gpslev', 'gsmlev', 'lac', 'mcc', 'mnc', 'pump', 'track']
  // const resultTypeList = ['logic', 'value', 'percentage', 'absolute']


  const funcFetchAddReqData = async () => {
    try {
      setIsLoading(true)

      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem('token'));

      var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders
      };

      const response = await fetch(`https://gps.speedotrack.com/api/device/${props.imeiForSensor}/sensor/required-data`, requestOptions)
      const result = await response.json();
      setSensorParamList(result?.params);
      setSensorTypesList(result?.sensorFields);
      setResultConfigList(result?.sensorResults);
      console.log(result)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    funcFetchAddReqData()
  }, [])


  useEffect(() => {
    const foundSensor = sensorTypesList.find(type => type.val === sensorType)
    if (foundSensor?.resultTypes?.length > 0) {
      setResultTypeList(foundSensor?.resultTypes)
    }
  }, [sensorType])



  useEffect(() => {
    const foundResultType = resultConfigList.find(type => type.val === sensorResultType)
    console.log('result type configuration is ', foundResultType)

    if (foundResultType?.units) {
      setUnitDisable(true)
      if (typeof foundResultType.units !== "boolean") {
        setSensorUnit(foundResultType?.units);
      } else {
        setSensorUnit('')
      }
    } else {
      setUnitDisable(false);
      setSensorUnit('')
    }
    if (foundResultType?.formula) {
      setFormulaDisable(foundResultType?.formula);
    } else {
      setFormulaDisable(foundResultType?.formula);
      setSensorFormula('')
    }


    setLowestValueDisable(foundResultType?.lVal)
    setHighestValueDisable(foundResultType?.hVal)

  }, [sensorResultType])


  const createSensor = async () => {
    try {
      setIsLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", localStorage.getItem('token'));

      var raw = JSON.stringify({
        "name": sensorName,
        "type": sensorType,
        "param": sensorParam,
        "dataList": sensorDataList,
        "popup": sensorPopup,
        "resultType": sensorResultType,
        "text1": sensorOneText,
        "text0": sensorZeroText,
        "units": sensorUnit,
        "lv": sensorLowestValue,
        "hv": sensorHighestValue,
        "accIgnore": sensorAccIgnore,
        "formula": sensorFormula,
        "calibration": sensorCalibration,
        "dictionary": []
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/device/${props.imeiForSensor}/sensor`, requestOptions)
      const result = await response.json()
      console.log(result)
      props.setToggleSensorChange(!props.toggleSensorChange);
      setIsLoading(false)
      props.onHide()
    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }
  }

  // const sensorTypeSetting = (typeSelected) => {
  //   setSensorType(typeSelected);
  //   const selectedType = sensorTemplate?.find((type) => type.type === typeSelected)
  //   console.log("selected type", selectedType)

  //   setSensorParam(selectedType.param)
  //   setSensorResultType(selectedType.resultType)
  //   setSensorUnit(selectedType.units)
  //   setSensorLowestValue(selectedType.lv)
  //   setSensorHighestValue(selectedType.hv)
  //   setSensorFormula(selectedType.formula)
  // }



  // useEffect(() => {
  //     const foundSensor = sensorTypesList?.find(type => type?.value === sensorType);
  //     setResultTypeList(foundSensor?.resultTypes);
  // },[sensorType])


  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      top
      style={{ marginLeft: '-7.5%', paddingBottom: '20px' }}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '14px' }}>
          <MdOutlineSensors /> {" "}Add Sensor
        </Modal.Title>
      </Modal.Header>
      {isLoading ? (
        <NewLoader />
      ) : (
        <Modal.Body>

          <form lg="12">
            <div class="form-row d-flex">
              <div class="form-group col-md-6">
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Sensor properties</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="enter sensor name"
                  style={{ borderRadius: '5px' }}
                  value={sensorName}
                  onChange={(e) => setSensorName(e.target.value)}
                />
              </div>
              <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px' }}> Sensor Type</label>
                <select class="form-select" aria-label="Default select example"
                  value={sensorType}
                  onChange={(e) => {
                    setSensorType(e.target.value)
                    if (e.target.value === 'fuel_level') {
                      setCallibrationModal(true)
                    }
                  }}
                >
                  {sensorTypesList?.map((type, index) => (
                    <option key={index} value={type?.val}>{type?.txt}</option>
                  ))}
                </select>
              </div>
            </div>
            <div class="form-row d-flex" >
              <div class="form-group col-md-6" >
                <label for="inputEmail4" style={{ fontSize: '13px' }}>If sensor "0" (text)</label>
                <input type="text" class="form-control" id="inputEmail4" placeholder="text when sensor value is 0"
                  style={{ borderRadius: '5px' }}
                  value={sensorZeroText}
                  onChange={(e) => { setSensorZeroText(e.target.value) }}

                />
              </div>
              <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px' }}>If sensor "1" (text)</label>
                <input type="text" class="form-control" id="inputPassword4" placeholder="text when sensor value is 1"
                  style={{ borderRadius: '5px' }}
                  value={sensorOneText}
                  onChange={(e) => { setSensorOneText(e.target.value) }}

                />
              </div>
            </div>
            <div class="form-row d-flex" >
              <div class="form-group col-md-6" >
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Parameter Type</label>
                <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}
                  value={sensorParam}
                  onChange={(e) => { setSensorParam(e.target.value) }}>
                  {/* <option value="2">{sensorType.param}</option> */}

                  {sensorParamList?.map((param, index) => (
                    <option key={index} value={param.name}>{param.name} ({param?.val})</option>
                  ))}

                </select>
                {/* <input type="email" class="form-control" id="inputEmail4" placeholder="sensor param"
                  style={{ borderRadius: '5px' }}
                  value={sensorParam}
                  disabled
                /> */}
              </div>
              <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px' }}>Result Type</label>
                <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}
                  value={sensorResultType}
                  onChange={(e) => setSensorResultType(e.target.value)}
                >
                  {resultTypeList?.map((result) => (
                    <option value={result}>{result}</option>
                  ))}
                </select>
                {/* <input type="email" class="form-control" id="inputEmail4" placeholder="sensor param"
                  style={{ borderRadius: '5px' }}
                  value={sensorResultType}
                  disabled
                /> */}
              </div>
            </div>

            <div class="form-row d-flex" >
              <div class="form-group col-md-6" >
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Units of measurement</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="enter unit"
                  style={{ borderRadius: '5px' }}
                  value={sensorUnit}
                  onChange={(e) => setSensorUnit(e.target.value)}
                  disabled={!unitsDisable}
                />
              </div>

              <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px' }}>Formula</label>
                <input type="text" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }}
                  value={sensorFormula}
                  onChange={(e) => setSensorFormula(e.target.value)}
                  disabled={!formulaDisable}
                />
              </div>
            </div>

            <div class="form-row d-flex" >
              <div class="form-group col-md-6" >
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Lowest Value</label>
                <input type="number" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }}
                  value={sensorLowestValue}
                  onChange={(e) => setSensorLowestValue(e.target.value)}
                  disabled={!lowestValueDisable}
                />
              </div>
              <div class="form-group col-md-6" style={{ marginLeft: '8px', }}>
                <label for="inputPassword4" style={{ fontSize: '13px' }}>Highest Value</label>
                <input type="number" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }}
                  value={sensorHighestValue}
                  onChange={(e) => setSensorHighestValue(e.target.value)}
                  disabled={!highestValueDisable}
                />
              </div>
            </div>
            <div class="form-row d-flex" >
              <div class="form-group col-md-4" >
                <Form.Check
                  type="checkbox"
                  id={`default-checkbox`}
                  label="Data List"
                  checked={sensorDataList}
                  onChange={() => setSensorDataList(!sensorDataList)}
                />
              </div>
              <div class="form-group col-md-4" >
                <Form.Check
                  type="checkbox"
                  id={`default-checkbox`}
                  label="Pop up"
                  checked={sensorPopup}
                  onChange={() => setSensorPopup(!sensorPopup)}
                />
              </div>

              {/* <div class="form-group col-md-4" >
                <Form.Check
                  type="checkbox"
                  id={`default-checkbox`}
                  label="Acc Ignore"
                  checked={sensorAccIgnore}
                  onChange={() => setSensorAccIgnore(!sensorAccIgnore)}
                />
              </div> */}

            </div>
            {/* <div class="form-row d-flex">
              <div class="form-group col-md-12">
                <label for="inputPassword4" style={{fontSize:'13px'}}>User Type</label>
                <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}>
                  <option selected>Manager</option>
                  <option value="1">Super Admin</option>
                  <option value="2">User</option>
                  <option value="3">Sub Admin</option>
                </select>
              </div>

            </div> */}


          </form>
        </Modal.Body>
      )}
      <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
        <Button variant="primary" className='w-25' onClick={createSensor}>Create</Button>
      </div>

      {callibrationModal ? (
        <CallibrationDeviceModal
          show={callibrationModal}
          onHide={() => setCallibrationModal(false)}
          sensorName={sensorName}
          setSensorName={setSensorName}
          sensorType={sensorType}
          sensorOneText={sensorOneText}
          setSensorOneText={setSensorOneText}
          sensorZeroText={sensorZeroText}
          setSensorZeroText={setSensorZeroText}
          sensorParam={sensorParam}
          setSensorParam={setSensorParam}
          sensorResultType={sensorResultType}
          setSensorResultType={setSensorResultType}
          sensorUnit={sensorUnit}
          setSensorUnit={setSensorUnit}
          sensorFormula={sensorFormula}
          setSensorFormula={setSensorFormula}
          sensorLowestValue={sensorLowestValue}
          setSensorLowestValue={setSensorLowestValue}
          sensorHighestValue={sensorHighestValue}
          setSensorHighestValue={setSensorHighestValue}
          sensorAccIgnore={sensorAccIgnore}
          setSensorAccIgnore={setSensorAccIgnore}
          sensorCalibration={sensorCalibration}
          setSensorCalibration={setSensorCalibration}

          createSensor={createSensor}
        />
      ) : null}

    </Modal>
  );
}
export default EditDeviceModal;

