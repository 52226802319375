import React, { useState } from 'react';
import Renew from './Renew';
import Statement from './Statement';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import "./Coins.css";
import { FiPlus } from "react-icons/fi";
import PaymentModal from './PaymentModal';



const Coins = () => {
    const [importRoute, setImportRoute] = useState('');
    const [modalShow, setModalShow] = React.useState(false);



    return (
        <div>
            <div class="card">
                <div class="card-body" style={{ minHeight: '10px' }}>
                    {/* <h6><img src={require('./images/coins.png')} style={{ width: '5%' }} />{" "}{" "}Mi Coins Balance : 0</h6> */}
                    <div className="topnav">
                        <Button variant="link" onClick={() => setImportRoute('RENEW')} class="active" style={{ fontSize: '14px' }}>Statement</Button>
                        {/* <Button variant="link" onClick={() => setImportRoute('STATEMENT')} style={{ fontSize: '14px' }}>Statement</Button> */}
                    </div>
                    <Button type="button" class="" style={{ float: 'right', marginTop: '-50px', width: '12%' }} onClick={() => setModalShow(true)}><FiPlus style={{ fontSize: '15px' }} /> {" "}Add Coins</Button>


                </div>
            </div>

            {
                importRoute === 'RENEW' ? (
                    <Renew />
                ) : importRoute === 'STATEMENT' ? (
                    <Statement />
                ) : (
                    <Renew />
                )
            }



            <PaymentModal
            
                show={modalShow}
                onHide={() => setModalShow(false)}
            />

        </div>
    )
}

export default Coins
